export function getLogoUrl(homeInfo, footerImages, defaultLogo) {
  // find default image
  let footerImage;

  if (homeInfo && footerImages) {
    const { CommunityRID: communityRID } = homeInfo;

    footerImage = footerImages.find(
      (file) => file.communityRID === communityRID,
    ) ? footerImages.find(
        (file) => file.communityRID === communityRID,
      ) : footerImages.find(
        (file) => file.communityRID === 0,
      );
  }

  if (!footerImage && defaultLogo) {
    return defaultLogo;
  }
  let url = '';
  if (footerImage) {
    url = footerImage.s3Path;
  }
  return url;
}
