const customPreConSteps = {
  arbor: [
    {
      ID: 'Text_SlsOrdMortStatus',
      Description: 'Loan Pre-Approval',
      isTracker: true,
      textValueTarget: 'Approved',
    },
    {
      ID: 'Bool_ColorsAreDone',
      Description: 'Design Center Appointment Completed',
      isTracker: true,
    },
  ],
  goodall: [],
  mungo: [],
};

export default customPreConSteps;
