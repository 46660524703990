import React, { useEffect } from 'react';
import { Link, withRouter, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import styles from './SideNavItems.module.css';
import { ThemeConsumer } from '../../../context/ThemeContext';
import {updateHubSpotAnalytics} from "../../../utils/HubSpotAnalytics";

function SideNavItems({
  menuType,
  location,
  handleLogout,
  hideMenu,
  enabledRoutes,
  slsOrdRID,
  windowWidth
}) {
  const onSideMenuClick = () => {
    if(windowWidth < 1024) {
      hideMenu();
    }
  }

  let routes = enabledRoutes.filter((route) => route.routeType === menuType);
  if (routes.length !== 0) {
    if (slsOrdRID === 0) {
      routes = routes.filter((route) => !['design center', 'documents'].includes(route.linkText));
    }
    return routes.reduce((accum, currVal) => {
      accum.push(
        <React.Fragment key={currVal.linkText}>
          <ThemeConsumer>
            {(theme) => (
              <div
                className={`
                  ${styles.sideNavItems}
                  ${styles[menuType]}
                `}
                style={(currVal.path === location.pathname)
                  ? {
                    backgroundColor: theme[`${menuType}HighlightBg`],
                  }
                  : null}
                onClick={onSideMenuClick}
              >
                {currVal.linkText === 'log out' ? (
                  <Link to={currVal.path} onClick={() => handleLogout(false)}>
                    {`${currVal.linkText}`}
                  </Link>
                ) : currVal.linkText === 'reset password' ? (
                  <Link to={currVal.path} onClick={onSideMenuClick}>
                    {`${currVal.linkText}`}
                  </Link>
                ) : (
                  <Link to={currVal.path} onClick={() => updateHubSpotAnalytics(currVal.path)}
                    style={(currVal.path === location.pathname)
                      ? {
                        color: theme[`${menuType}FontColor`],
                        backgroundColor: theme[`${menuType}HighlightBg`],
                      }
                      : null}>
                    {`${currVal.linkText}`}
                  </Link>
                )}
              </div>
            )}
          </ThemeConsumer>
        </React.Fragment>,
      );
      if (currVal.subRoutes && (location.pathname.includes(currVal.path))) {
        currVal.subRoutes.forEach((subRoute) => (
          accum.push(
            <React.Fragment key={subRoute.linkText}>
              <ThemeConsumer>
                {(theme) => (
                  <div
                    className={`
                      ${styles.subItem}
                      ${styles[menuType]}
                    `}
                    style={(currVal.path === location.pathname)
                      ? {
                        color: theme.primaryMenuFontColor,
                        backgroundColor: theme[`${menuType}HighlightBg`],
                      }
                      : null}
                  >
                    <Link to={subRoute.path} onClick={() => updateHubSpotAnalytics(subRoute.path)}>
                      {`${subRoute.linkText}`}
                    </Link>
                  </div>
                )}
              </ThemeConsumer>
            </React.Fragment>,
          )
        ));
      }
      return accum;
    }, []);
  }

  return (
    <div className={`${styles.defaultMessage}`}>Log In, Required</div>
  );
}

SideNavItems.propTypes = {
  location: PropTypes.shape({
    hash: PropTypes.string,
    key: PropTypes.string,
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string,
  }).isRequired,
  menuType: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
  ]).isRequired,
  handleLogout: PropTypes.func.isRequired,
  enabledRoutes: PropTypes.arrayOf(PropTypes.object).isRequired,
  slsOrdRID: PropTypes.number.isRequired,
  windowWidth: PropTypes.number

};

export default withRouter(SideNavItems);
