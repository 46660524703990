import React from 'react';
import ServiceRequestItem from '../../ServiceRequestItem';
import {IOptLocation} from "../../../../api/Api";

interface SvcReqItmListProps {
  optLocations: IOptLocation[];
  removeExistingItmImg: (itmId: string, imgId: string) => void;
  removeFileUpload: (itmId: string, fileName: string) => void;
  removeSvcReqItm: (key: string) => void;
  svcReqItms: { key: string }[];
  updateSvcReqItm: (key: string, event: React.ChangeEvent<HTMLInputElement>) => void;
  svcReqDisabled:boolean;
}

function SvcReqItmList({
  optLocations,
  removeExistingItmImg,
  removeFileUpload,
  removeSvcReqItm,
  svcReqItms,
  updateSvcReqItm,
  svcReqDisabled,
}: SvcReqItmListProps) {
  return (
    <>
    {
      svcReqItms.map((svcReqItm, i) => (
        <ServiceRequestItem
          key={svcReqItm.key}
          handleChange={(e) => updateSvcReqItm(svcReqItm.key, e)}
          handleClose={() => removeSvcReqItm(svcReqItm.key)}
          handleRemoveExistingImg={(imgId: string) => removeExistingItmImg(svcReqItm.key, imgId)}
          handleRemoveFileUpload={(fileName: string) => removeFileUpload(svcReqItm.key, fileName)}
          index={i}
          optLocations={optLocations}
          svcReqItem={svcReqItm}
          svcReqDisabled={svcReqDisabled}
        />
      ))
    }
    </>
  )
}

export default SvcReqItmList;
