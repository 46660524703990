export function getLogoUrl(homeInfo, defaultLogo, communityLogos, themeLogo) {
  let logo = defaultLogo;
  if (!logo) {
    return themeLogo;
  }

  if (homeInfo && communityLogos) {
    const { CommunityRID } = homeInfo;
    if (CommunityRID) {
      const communityLogo = communityLogos.find(
        (file) => file.communityRID === CommunityRID,
      );
      if (communityLogo) {
        logo = communityLogo;
      }
    }
  }

  let url = '';
  if (logo) {
    url = logo.s3Path;
  }

  return url;
}
