import Api from '../../api/Api';
import alertConfig from '../../config/alert';

// async function checkLogin(email, passwordOld) {
//   try {
//     await Api.login(email, passwordOld);
//     return true;
//   } catch (error) {
//     return false;
//   }
// }

function checkPasswordMatch(passwordNew, passwordConfirm) {
  return passwordNew === passwordConfirm;
}

export async function handleSubmit(
  e,
  formValues,
  handleLogout,
  kovaToken,
  openAlert,
  setValidationErrors,
  setSubmitDisabled,
) {
  e.preventDefault();
  setSubmitDisabled(true);
  const { passwordNew, passwordConfirm } = formValues;
  const validationErrors = {};
  const passwordsMatch = checkPasswordMatch(passwordNew, passwordConfirm);

  if (!passwordsMatch) {
    validationErrors.PASSWORDMISMATCH = 'Passwords do not match';
  } else {
    try {
      await Api.changePassword(formValues, kovaToken);
      openAlert(alertConfig.changePassword);
      handleLogout();
    } catch (error) {
      console.dir(error);
      const { Message } = error.response.data;
      if (Message === 'Could not change password! The old password is incorrect!') {
        validationErrors.LOGINFAILED = 'Email and/or Password is not recognized';
      } else {
        openAlert(alertConfig.error.default);
      }
    }
  }
  setValidationErrors(validationErrors);
  setSubmitDisabled(false);
}
