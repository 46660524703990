import axios from 'axios';
import moment from 'moment';
import ParseData from '../utils/ParseData';
import GlobalConfig from '../utils/GlobalConfig';

export interface IKovaApiToken {
  csrfToken: string;
}

export interface ISvcReq {
  SvcReqItm?: ISvcReqItm; //Only in use with fieldpoint
  HomeRID: number;
  SvcReqCategRID: number;
  CommunityRID: number;
  LotRID: number;
  ContPhone1: string;
  ContName: string;
  ContEmail: string;
  CustomerRID: number;
  Name: string;
  SvcReqRID: number;
  SvcReqID: string;
  ReceiverUserRID: number;
  SvcReqCategSvcReqCategID: string;
  Status: string;
  AssignedUserName: string;
  DateClosed: string;
  DateReceived: string;
  PrefServiceDate: string;
  ReceiverName: string;
}

export interface ISvcReqItm {
  SvcReqRID:number;
  SvcReqItmRID: number;
  Name:string;
  Description:string;
  Location:string;
  SeqNumber:number;
  Status?: string;
}

export interface ISvcReqType {
  SvcReqCategRID:number;
  SvcReqCategID:string;
  Name:string;
}

export interface IOptLocation {

  Name:string;
}

export interface IObjAttImg {
  MiddlewareURL: string;
  ObjAttImgRID:number;
  RefObjRID:number;
}

export interface ISvcReqUpdateData {
  itemAdditions:any[];
  itemDeletions:unknown[];
  itemUpdates:any[];
  imgDeletions:unknown[];
  imgUploadMap:any[];
  svcReqFields: {
    key:string;
    value:any;
  }[];
}

export interface ISvcReqAttachment {
  id?:string;
  fileURL:string;
  objAttImgRID:number;
  svcReqItmRID:number;
}

export interface IPortalUser {
  firstName: string,
  lastName: string,
  email: string,
  phone: string,
}

export interface IFieldPointUser {
  PhoneHome: string;
  EmailHome: string;
  Name: string;
}

export default class Api {
  static changePassword = async (dataValues: { passwordOld:string, passwordNew:string, passwordConfirm:string }, kovaToken:string) => {
    const { passwordOld, passwordNew, passwordConfirm } = dataValues;
    const kovaUrl = GlobalConfig.get(GlobalConfig.Key.KOVA_URL_PROD);
    const url = `${kovaUrl}api/v3/ChangePassword?token=${kovaToken}`;
    const data = JSON.stringify({
      OldPassword: passwordOld,
      NewPassword: passwordNew,
      NewPasswordConfirm: passwordConfirm,
    });
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const response = await axios.post(url, data, config);

    return response;
  };

  static changeForgotPassword = async (dataValues: { passwordNew:string, passwordConfirm:string }, urlParams:string) => {
    const { passwordNew, passwordConfirm } = dataValues;
    const kovaUrl = GlobalConfig.get(GlobalConfig.Key.KOVA_URL_PROD);
    const url = `${kovaUrl}api/v3/Customer/ResetPassword?${urlParams}`;
    const data = JSON.stringify({
      NewPassword: passwordNew,
      NewPasswordConfirm: passwordConfirm,
    });
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const response = await axios.post(url, data, config);

    return response;
  };

  static createDirectoriesForJobSchActs = async (kovaApiToken:IKovaApiToken, homeRID:number, jobRID:number) => {
    const apiUrl = GlobalConfig.get(GlobalConfig.Key.KOVA_API_URL);
    const config =
        {
          withCredentials: true,
          headers: {
            'Content-Type': 'application/json',
            'X-XSRF': kovaApiToken.csrfToken
          }
        };

    const url = `${apiUrl}customerportal/CreateDirectoriesForJobSchActs`;

    const data = JSON.stringify({
      JobRID: jobRID,
      HomeRID: homeRID,
    });

    const response = await axios.post(url, data, config);
    return response;

  };

  static deleteImage = async (kovaApiToken:IKovaApiToken, homeRID:number, repDocDirName:string, repDocName:string) => {
    const apiUrl = GlobalConfig.get(GlobalConfig.Key.KOVA_API_URL);
    const config = {
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json',
        'X-XSRF': kovaApiToken.csrfToken
      },
    };
    const deleteHomeAttacmhentsUrl = `${apiUrl}customerportal/DeleteHomeAttachments?HomeRID=${homeRID}&RepDocDirName=${repDocDirName}&RepDocName=${repDocName}`;
    const response = await axios.delete(deleteHomeAttacmhentsUrl, config);

    return response;
  };

  static emailPasswordResetLink = async (email:string) => {
    const kovaUrl = GlobalConfig.get(GlobalConfig.Key.KOVA_URL_PROD);
    const url = `${kovaUrl}api/v3/Customer/EmailPasswordResetLink`;
    const data = JSON.stringify({
      Email: email,
    });
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const response = await axios.post(url, data, config);

    return response;
  };

  static fetchActivities = async (jobRID:number) => {
    const apiUrl = GlobalConfig.get(GlobalConfig.Key.KOVA_API_URL);
    const config = { withCredentials: true };
    const url = `${apiUrl}customerportalstandard/Activities?JobRID=${jobRID}`;
    const response = await axios.get(url, config);


    return response.data;
  };

  static fetchCommunityBUnit = async (communityRID:number) => {
    const apiUrl = GlobalConfig.get(GlobalConfig.Key.KOVA_API_URL);
    const config = { withCredentials: true };
    const url = `${apiUrl}customerportalstandard/CommunityBUnit?CommunityRID=${communityRID}`;
    const response = await axios.get(url, config);

    return response.data;
  };

  static fetchCommunityVideoLink = async (BUnitRID:number) => {
    const apiUrl = GlobalConfig.get(GlobalConfig.Key.KOVA_API_URL);
    const config = { withCredentials: true };
    const url = `${apiUrl}customerportalstandard/CommunityVideoLink?BUnitRID=${BUnitRID}`;
    const response = await axios.get(url, config);

    return response.data;
  };

  static fetchConstructionStartSheet = async (slsOrdRID:number) => {

    const apiUrl = GlobalConfig.get(GlobalConfig.Key.KOVA_API_URL);
    const config = { withCredentials: true };
    const url = `${apiUrl}customerportal/ConstructionStartSheet?SlsOrdRID=${slsOrdRID}`;
    try {
      const response = await axios.get(url, config);

      if (response.status > 200) {
        return {};
      }
      return response.data;

    } catch (e) {
      if (e.response?.data === "Request failed with status code 404" || e.response?.data === "Start Sheet Download failed")
      return {};
    }
  };

  static fetchCustomers = async (customerRIDs:number[]) => {
    const apiUrl = GlobalConfig.get(GlobalConfig.Key.KOVA_API_URL);
    const config = { withCredentials: true };

    let customers = [];
    for(let customerRID of customerRIDs){
      const url = `${apiUrl}customerportal/Customer?CustomerRID=${customerRID}`;
      const response = await axios.get(url, config);
      customers.push(response.data)
    }

    return customers;
  };

  static fetchCustomFieldData = async () => {
    const apiUrl = GlobalConfig.get(GlobalConfig.Key.KOVA_API_URL);
    const config = { withCredentials: true };
    const url = `${apiUrl}customerportalstandard/CustomFields`;
    const response = await axios.get(url, config);

    return response.data;
  };

  static fetchCustomPreConSteps = async (slsOrdRID:number) => {
    const apiUrl = GlobalConfig.get(GlobalConfig.Key.KOVA_API_URL);
    const config = { withCredentials: true };
    const url = `${apiUrl}customerportalstandard/CustomPreConSteps?SlsOrdRID=${slsOrdRID}`;
    const response = await axios.get(url, config);

    return response
      ? ParseData.formatCustomPreConSteps(response.data)
      : response;
  };

  static fetchCustPortalGeneralDocuments = async (dirTypeName:string) => {

    const apiUrl = GlobalConfig.get(GlobalConfig.Key.KOVA_API_URL);
    const config = { withCredentials: true };
    const url = `${apiUrl}customerportal/CustPortalGeneralDocs?RepDocDirTypeName=${dirTypeName}`;
    const response = await axios.get(url, config);

    for (let i = 0; i < response.data.length; i += 1) {
      const repDoc = response.data[i];
      if (repDoc.MiddlewareURL) {
        repDoc.MiddlewareDownloadURL = `${apiUrl}customerportal/${repDoc.MiddlewareURL}`;
      }
    }
    return response.data;
  };

  static fetchCustPortalCommunityDocuments = async (repDocDirType:string, communityRID:number = 0) => {
    const apiUrl = GlobalConfig.get(GlobalConfig.Key.KOVA_API_URL);
    const config = { withCredentials: true };
    const url = `${apiUrl}customerportal/CustPortalCommunityDocs?RepDocDirTypeName=${repDocDirType}&CommunityRID=${communityRID}`;
    const response = await axios.get(url, config);

    for (let i = 0; i < response.data.length; i += 1) {
      const repDoc = response.data[i];
      if (repDoc.MiddlewareURL) {
        repDoc.MiddlewareDownloadURL = `${apiUrl}customerportal/${repDoc.MiddlewareURL}`;
      }
    }
    return response.data;
  };

  static fetchHomeDocuments = async (homeRID:number) => {

    const apiUrl = GlobalConfig.get(GlobalConfig.Key.KOVA_API_URL);
    const config = { withCredentials: true };
    const url = `${apiUrl}customerportal/HomeDocuments?HomeRID=${homeRID}`;
    const response = await axios.get(url, config);


    for (let i = 0; i < response.data.length; i += 1) {
      const repDoc = response.data[i];
      if (repDoc.MiddlewareURL) {
        repDoc.MiddlewareDownloadURL = `${apiUrl}customerportal/${repDoc.MiddlewareURL}`;
      }
    }
    let homeDocs = response.data;
    return homeDocs;
  };

  static fetchHomeInfo = async (customerRID:number) => {
    const apiUrl = GlobalConfig.get(GlobalConfig.Key.KOVA_API_URL);
    const config = { withCredentials: true };
    const url = `${apiUrl}customerportalstandard/HomeInfo?CustomerRID=${customerRID}`;

    const response = await axios.get(url, config);
    return response ? response.data : response;
  };

  static fetchJobSchedule = async (jobRID:number) => {
    const apiUrl = GlobalConfig.get(GlobalConfig.Key.KOVA_API_URL);
    const config = { withCredentials: true };
    const url = `${apiUrl}customerportalstandard/JobSchedule?JobRID=${jobRID}`;
    const response = await axios.get(url, config);

    return response ? response.data : response;
  };
  
  static fetchSelectionImages = async (slsOrdRID:number) => {
    const apiUrl = GlobalConfig.get(GlobalConfig.Key.KOVA_API_URL);
    const config = { withCredentials: true };

    const url = `${apiUrl}customerportal/SelectionImages?SlsOrdRID=${slsOrdRID}`;
    const response = await axios.get(url, config);

    for(let selectionImage of response.data){
      selectionImage.img = `${apiUrl}customerportal/${selectionImage.img}`;
    }

    return response.data;    
  };

  static fetchSlsOrdDocs = async (slsOrdRID:number, customerRID: number) => {
    const apiUrl = GlobalConfig.get(GlobalConfig.Key.KOVA_API_URL);
    const config = { withCredentials: true };
    const url = `${apiUrl}customerportal/SlsOrdDocs?SlsOrdRID=${slsOrdRID}&CustomerRID=${customerRID}`;

    const response = await axios.get(url, config);

    for(let slsOrdDoc of response.data){
      slsOrdDoc.MiddlewareDownloadURL = `${apiUrl}customerportal/${slsOrdDoc.MiddlewareURL}`;
    }

    return response.data;
  };

  static fetchSlsOrdOpts = async (slsOrdRID:number, homeRID:number) => {
    const apiUrl = GlobalConfig.get(GlobalConfig.Key.KOVA_API_URL);
    const config = { withCredentials: true };
    const url = `${apiUrl}customerportal/SlsOrdOpts?SlsOrdRID=${slsOrdRID}&HomeRID=${homeRID}`;
    const response = await axios.get(url, config);

    return response ? response.data : response;
  };

  static fetchStagePhotos = async (homeRID:number, stageKey:string[]) => {
    const apiUrl = GlobalConfig.get(GlobalConfig.Key.KOVA_API_URL);
    const config = { withCredentials: true };

    if(stageKey.length == 0){
      return [[]];
    }

    let stageKeyString = stageKey.join("|");
    stageKeyString = encodeURIComponent(stageKeyString);

    const url = `${apiUrl}customerportal/StagePhotos?HomeRID=${homeRID}&StageKey=${stageKeyString}`;
    const response = await axios.get(url, config);
    const flattenedResponse = response.data.reduce((a:any, b:any) => a.concat(b), []);
    for (let i = 0; i < flattenedResponse.length; i += 1) {
      const repDoc = flattenedResponse[i];
      if (repDoc.MiddlewareURL) {
        repDoc.MiddlewareDownloadURL = `${apiUrl}customerportal/${repDoc.MiddlewareURL}`;
      }
    }

    return response ? response.data : response;
  };


  static fetchSvcReqs = async (homeRID:number, customerRID:number) => {

    if(GlobalConfig.get(GlobalConfig.Key.WARRANTY_PROVIDER) === "Fieldpoint"){
      const apiUrl = GlobalConfig.get(GlobalConfig.Key.KOVA_API_URL);
      const config = { withCredentials: true };
      const url = `${apiUrl}fieldpoint/GetWorkOrders?CustomerRID=${customerRID}`;
      const response = await axios.get(url, config);;
      return response ? response.data : response;
    }
    else {

      const apiUrl = GlobalConfig.get(GlobalConfig.Key.KOVA_API_URL);
      const config = { withCredentials: true };
      const url = `${apiUrl}customerportalstandard/SvcReqs?HomeRID=${homeRID}&CustomerRID=${customerRID}`;
      const response = await axios.get(url, config);
      return response ? response.data : response;
    }
  };

  static fetchFieldpointCompany = async (customerRID:number):Promise<IFieldPointUser> => {
    const apiUrl = GlobalConfig.get(GlobalConfig.Key.KOVA_API_URL);
    const config = { withCredentials: true };
    const url = `${apiUrl}fieldpoint/GetFieldpointCompany?CustomerRID=${customerRID}`;
    const response = await axios.get(url, config);

    return response.data;
  };

  static fetchSvcReqItms = async (homeRID:number, svcReqRID:number) => {
    const apiUrl = GlobalConfig.get(GlobalConfig.Key.KOVA_API_URL);
    const config = { withCredentials: true };
    const url = `${apiUrl}customerportalstandard/SvcReqItms?HomeRID=${homeRID}&SvcReqRID=${svcReqRID}`;
    const response = await axios.get(url, config);

    return response ? response.data : response;
  };

  static fetchOptLocations = async () => {
    const apiUrl = GlobalConfig.get(GlobalConfig.Key.KOVA_API_URL);
    const config = { withCredentials: true };

    const url = `${apiUrl}customerportalstandard/OptLocations`;
    const response = await axios.get(url, config);

    return response.data;
  };

  static fetchSvcReqItmImgs = async (svcReqRID:number):Promise<ISvcReqAttachment[]> => {
    const apiUrl = GlobalConfig.get(GlobalConfig.Key.KOVA_API_URL);
    const config = { withCredentials: true };

    const url = `${apiUrl}customerportal/SvcReqItmImages?SvcReqRID=${svcReqRID}`;

    const response = await axios.get(url, config);

    const processedData = response.data.map((oai:IObjAttImg, i:number) => ({
      fileURL: `${apiUrl}customerportal/${oai.MiddlewareURL}`,
      objAttImgRID: response.data[i].ObjAttImgRID,
      svcReqItmRID: response.data[i].RefObjRID,
    } as ISvcReqAttachment));

    return processedData;
  };

  static fetchWorkOrderImgs = async (incidentNumber:number) => {
    const apiUrl = GlobalConfig.get(GlobalConfig.Key.KOVA_API_URL);
    const config = { withCredentials: true };

    const url = `${apiUrl}fieldpoint/GetWorkOrderImgs?IncidentNumber=${incidentNumber}`;

    const response = await axios.get(url, config);

    let imageBlobs = [];
    for(let img of response.data){
      const base64 = await fetch(`data:image/png;base64,${img.Document_Base64}`);
      const blob = await base64.blob();
      imageBlobs.push(blob);
    }

    return imageBlobs;
  };

  static fetchSvcReqTypes = async () => {
    const apiUrl = GlobalConfig.get(GlobalConfig.Key.KOVA_API_URL);
    const config = { withCredentials: true };
    const url = `${apiUrl}customerportalstandard/SvcReqCategs`;
    const response = await axios.get(url, config);

    return response.data;
  };

  static login = async (email:string, password:string) => {
    const apiUrl = GlobalConfig.get(GlobalConfig.Key.KOVA_API_URL);
    const postBody = { User: email, Pass: password };
    const config = { withCredentials: true };
    /*const postHeader = {
      Accept: 'application/json',
      'Content-Length': '59',
      'Content-Type': 'application/json',
    };*/

    const response = await axios.post(`${apiUrl}authenticate`, postBody, config);
    return response ? response.data : response;
  };

  static logout = async () =>{
    const apiUrl = GlobalConfig.get(GlobalConfig.Key.KOVA_API_URL);
    const config = { withCredentials: true };

    const response = await axios.post(`${apiUrl}authenticate/logout`, {}, config);
    return response;
  }


  static saveWorkorder = async (svcReq:ISvcReq, svcReqItm: any, kovaApiToken:IKovaApiToken) => {
    const apiUrl = GlobalConfig.get(GlobalConfig.Key.KOVA_API_URL);
    const config = {
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json',
        'X-XSRF': kovaApiToken.csrfToken
      },
    };
    const url = `${apiUrl}fieldpoint/SubmitWorkorder`;
    const data = JSON.stringify({
      SvcReqRID: svcReq.SvcReqRID,
      Name: svcReq.Name,
      CustomerRID: svcReq.CustomerRID,
      ContEmail: svcReq.ContEmail,
      ContName: svcReq.ContName,
      ContPhone1: svcReq.ContPhone1,
      DateReceived: moment().format(),
      SvcReqItm: svcReqItm
    });
    const response = await axios.post(url, data, config);

    return response;
  };

  static saveSvcReq = async (svcReq:ISvcReq, kovaApiToken:IKovaApiToken) => {
    const apiUrl = GlobalConfig.get(GlobalConfig.Key.KOVA_API_URL);
    const config = {
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json',
        'X-XSRF': kovaApiToken.csrfToken
      },
    };
    const url = `${apiUrl}customerportal/SubmitSvcReq`;
    const data = JSON.stringify({
      HomeRID: svcReq.HomeRID,
      Name: svcReq.Name,
      SvcReqCategRID: svcReq.SvcReqCategRID,
      CustomerRID: svcReq.CustomerRID,
      ReceiverUserRID: GlobalConfig.get(GlobalConfig.Key.KOVA_USER_RID),
      CreatorUserRID: GlobalConfig.get(GlobalConfig.Key.KOVA_USER_RID),
      CommunityRID: svcReq.CommunityRID,
      ContEmail: svcReq.ContEmail,
      ContName: svcReq.ContName,
      ContPhone1: svcReq.ContPhone1,
      LotRID: svcReq.LotRID,
      DateReceived: moment().format(),
    });
    const response = await axios.post(url, data, config);

    return response;
  };

  static saveSvcReqItem = async (homeRID:number, svcReqItem:ISvcReqItm, kovaApiToken:IKovaApiToken) => {
    const apiUrl = GlobalConfig.get(GlobalConfig.Key.KOVA_API_URL);
    const config = {
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json',
        'X-XSRF': kovaApiToken.csrfToken
      },
    };
    const url = `${apiUrl}customerportal/SvcReqItms`;
    const data = JSON.stringify({
      Description: svcReqItem.Description,
      HomeRID: homeRID,
      Location: svcReqItem.Location,
      Name: svcReqItem.Description.substring(0, 40),
      SeqNumber: svcReqItem.SeqNumber,
      SvcReqRID: svcReqItem.SvcReqRID,
    });
    const response = await axios.post(url, data, config);

    return response;
  };

  static uploadRepDocImgs = async (dirName:string, files:File[], homeRID:number, kovaApiToken:IKovaApiToken) => {
    const apiUrl = GlobalConfig.get(GlobalConfig.Key.KOVA_API_URL);
    const config = {
      withCredentials: true,
      headers: {
        'Content-Type': 'multipart/form-data',
        'X-XSRF': kovaApiToken.csrfToken
      },
    };

    const url = `${apiUrl}customerportal/UploadHomeAttachments`;
    const formData = new FormData();
    formData.append('DirName', dirName);
    formData.append('HomeRID', homeRID.toString());

    for (let i = 0; i < files.length; i += 1) {
      formData.append('file', files[i]);
    }

    const response = await axios.post(url, formData, config);
    return response;
  };

  static updateSvcReq = async (requestData:ISvcReqUpdateData, files:File[], homeRID:number, svcReqRID:number, kovaApiToken:IKovaApiToken) => {
    const apiUrl = GlobalConfig.get(GlobalConfig.Key.KOVA_API_URL);
    const config = {
      withCredentials: true,
      headers: {
        'Content-Type': 'multipart/form-data',
        'X-XSRF': kovaApiToken.csrfToken
      },
    };
    const url = `${apiUrl}customerportal/SvcReq/update`;
    const formData = new FormData();

    formData.append('HomeRID', homeRID.toString());
    formData.append('KovaUserRID', GlobalConfig.get(GlobalConfig.Key.KOVA_USER_RID));
    formData.append('SvcReqRID', svcReqRID.toString());
    formData.append('requestData', JSON.stringify(requestData));

    for (let i = 0; i < files.length; i += 1) {
      formData.append('file', files[i]);
    }

    const response = await axios.post(url, formData, config);
    return response;
  };

  static uploadSvcReqImgs = async (files:File[], svcReqItmRID:number, kovaApiToken:IKovaApiToken) => {
    const apiUrl = GlobalConfig.get(GlobalConfig.Key.KOVA_API_URL);
    const config = {
      withCredentials: true,
      headers: {
        'Content-Type': 'multipart/form-data',
        'X-XSRF': kovaApiToken.csrfToken
      },
    };
    const url = `${apiUrl}customerportal/SvcReqItm/upload`;
    const formData = new FormData();
    formData.append('SvcReqItmRID', svcReqItmRID.toString());

    for (let i = 0; i < files.length; i += 1) {
      formData.append('file', files[i]);
    }

    const response = await axios.post(url, formData, config);
    return response;
  };

  static uploadWorkorderAttachments = async (customerRID:number, files:File[], incidentNumber:number, kovaApiToken:IKovaApiToken) => {
    const apiUrl = GlobalConfig.get(GlobalConfig.Key.KOVA_API_URL);
    const config = {
      withCredentials: true,
      headers: {
        'Content-Type': 'multipart/form-data',
        'X-XSRF': kovaApiToken.csrfToken
      },
    };

    const url = `${apiUrl}fieldpoint/UploadWorkOrderAttachments`;
    const formData = new FormData();
    formData.append('CustomerRID', customerRID.toString());
    formData.append('IncidentNumber', incidentNumber.toString());

    for (let i = 0; i < files.length; i += 1) {
      formData.append('file', files[i]);
    }

    const response = await axios.post(url, formData, config);
    return response;
  };
}
