import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './MyProfile.module.css';
import Dictionary from "../../utils/Dictionary";

class MyProfile extends Component {

  render() {
    const { user } = this.props;

    return (
      <div>
        <div className={styles.registeredUser}>
          <p className={styles.userText}>
            Registered User:
          </p>
        </div>
        <div className={styles.formContainer}>
          <form
            className={styles.formDiv}
          >
            <div className={styles.formElementOne}>
              <div className={styles.formLabelOneDiv}>
                <label className={styles.formLabelOne}>
                  First Name:
                </label>
                
              </div>
              <input
                className={styles.formInputOne}
                type="text"
                name="firstName"
                value={user.firstName}
                readOnly="readonly"
              />
            </div>
            <div className={styles.formElementTwo}>
              <div className={styles.formLabelTwoDiv}>
                <label className={styles.formLabelTwo}>
                  Last Name:
                </label>
              </div>
              <input
                className={styles.formInputTwo}
                type="text"
                name="lastName"
                value={user.lastName}
                readOnly="readonly"
              />
            </div>
            <div className={styles.formElementThree}>
              <div className={styles.formLabelThreeDiv}>
                <label className={styles.formLabelThree}>Phone:</label>
              </div>
              <input
                className={styles.formInputThree}
                type="tel"
                pattern="[(][0-9]{3}[)] [0-9]{3}-[0-9]{4}"
                name="phone"
                value={user.phone}
                readOnly="readonly"
              />
            </div>
            <div className={styles.infoTextContainer}>
              <label className={styles.infoText}>{Dictionary.getText(Dictionary.MyProfile_InfoText)}</label>
              
            </div>
          </form>
        </div>
      </div>
    );
  }
}

MyProfile.propTypes = {
  openAlert: PropTypes.func.isRequired,
  setUserAfterUpDate: PropTypes.func.isRequired,
  customerRID: PropTypes.number.isRequired,
  handleLogout: PropTypes.func.isRequired,
};

export default MyProfile;
