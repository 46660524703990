import React from 'react';
import PropTypes from 'prop-types';
import styles from './Faqs.module.css';
import { LinkObj } from '../../types';

interface FaqsProps {
  links: LinkObj;
}

function Faqs({ links }: FaqsProps) {
  return (
    <div className={styles.faqs}>
      {links.faq.url && links.faq.url ? (
        <iframe src={links.faq.url} title="faq" />
      ) : (
        <div>This page is currently unavailable. Please check back later.</div>
      )}
    </div>
  );
}

Faqs.propTypes = {
  links: PropTypes.object.isRequired,
};

export default Faqs;
