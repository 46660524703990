import * as React from 'react';
import { Link, withRouter, RouteComponentProps } from 'react-router-dom';
import { ThemeConsumer } from '../../../context/ThemeContext';
import LoadingIndicator from '../../../shared/LoadingIndicator';
import styles from './LoginForm.module.css';

interface LoginFormProps {
  handleUserLogin: (email:string, password:string) => void;
  loadingStatus: string;
}

interface LoginFormState {
  emailInput: string;
  passwordInput: string;
}

export class LoginPortal
  extends React.Component<LoginFormProps & RouteComponentProps, LoginFormState> {
  constructor(props: LoginFormProps & RouteComponentProps) {
    super(props);

    this.state = {
      emailInput: '',
      passwordInput: '',
    };
  }

  updateEmailInput = (e: React.FormEvent<HTMLInputElement>) => {
    this.setState({ emailInput: (e.target as HTMLInputElement).value });
  };

  updatePasswordInput = (e: React.FormEvent<HTMLInputElement>) => {
    this.setState({ passwordInput: (e.target as HTMLInputElement).value });
  };

  handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const { handleUserLogin } = this.props;
    const { emailInput, passwordInput } = this.state;
    handleUserLogin(emailInput, passwordInput);
  };

  handleRememberMe = () => {
    // console.log('Remember me toggled');
  };

  render() {
    const { loadingStatus } = this.props;

    return (
      <ThemeConsumer>
        {(theme) => (
          <div className={styles.loginForm}>
            <form onSubmit={(e) => this.handleSubmit(e)}>
              <div className={styles.textWrapper}>
                <div className={styles.header}>Sign In</div>
                <div className={styles.note}>Enter your information below to get started</div>
              </div>
              <div className={styles.inputWrapper}>
                <label htmlFor="email">
                  Email
                  <br />
                  <input
                    id="email"
                    type="email"
                    onChange={(e) => this.updateEmailInput(e as React.FormEvent<HTMLInputElement>)}
                    required
                  />
                </label>
              </div>
              <div className={styles.inputWrapper}>
                <label htmlFor="password">
                  Password
                  <br />
                  <input
                    id="password"
                    type="password"
                    onChange={(e) => this.updatePasswordInput(e)}
                    required
                  />
                </label>
                <div className={styles.loginOptions}>
                  <Link to="/forgot-password">Forgot Password?</Link>
                </div>
              </div>
              <div className={styles.buttonWrapper}>
              {loadingStatus === 'Authenticating User' ? (
                <span className={styles.loadingWrapper}>
                  <LoadingIndicator color={theme.accentColorA} />
                </span>
              ) : (
                <button
                  type="submit"
                  className={styles.submitButton}
                  style={{ backgroundColor: theme.accentColorA }}
                >
                  Submit
                </button>
              )}
              </div>
            </form>
          </div>
        )}
      </ThemeConsumer>
    );
  }
}

export default withRouter(LoginPortal);
