import React from 'react';
import PropTypes from 'prop-types';
import styles from './UsefulLinks.module.css';
import { LinkObj } from '../../types';

interface UsefulLinksProps {
  links: LinkObj;
}

function UsefulLinks({ links }: UsefulLinksProps) {
  return (
    <div className={styles.usefulLinks}>
      {links['useful links'].url ? (
        <iframe src={links['useful links'].url} title="useful-links" />
      ) : (
        <div>This page is currently unavailable. Please check back later.</div>
      )}
    </div>
  );
}

UsefulLinks.propTypes = {
  links: PropTypes.object.isRequired,
};

export default UsefulLinks;
