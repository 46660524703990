import {Dictionary} from "../../utils/Dictionary";
import React from "react";

interface IDictionaryText {
  dictionary: Dictionary;
  dictionaryKey:string;

}
export function DictionaryText(props: IDictionaryText) {
  const { dictionary, dictionaryKey } = props;

  const text = dictionary.getText(dictionaryKey)

  return (<span x-dict={dictionaryKey} className={'configurableText'}>{text}</span>);
}