import React, { Component } from 'react';
import { Router, Switch } from 'react-router-dom';
import queryString from 'query-string';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faFileImage } from '@fortawesome/free-regular-svg-icons';
import { faEdit, faTimes, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { Routes } from '../../routes';
import Alert from '../Alert/Alert';
import ModalVideo from '../ModalVideo/ModalVideo';
import Header from '../Header/Header';
import SideNav from '../SideNav/SideNav';
import styles from './App.module.css';
import SideNavItems from '../SideNav/SideNavItems/SideNavItems';
import { ThemeContext } from '../../context/ThemeContext';
import { WindowProvider } from '../../context/WindowContext';
import Api from '../../api/Api';
import BrowserUtils from '../../utils/Browser';
import ParseData from '../../utils/ParseData';
import alertConfig from '../../config/alert';
import LoadingOverlay from '../../shared/LoadingOverlay/LoadingOverlay';
import SessionUtils from '../../utils/Session';
import AdminApi from '../../api/AdminApi';
import ColorSwatches from '../../shared/ColorSwatches/ColorSwatches';
import {CustomerPortalAuthenticator, IToken} from "@bimaire/optionslib/build/api/customerportal-client";
import {
  generateEnabledRoutes, setPageTitle,
} from './utils';
import GlobalConfig from '../../utils/GlobalConfig';
import LoadingIndicator from '../../shared/LoadingIndicator';
import Dictionary from "../../utils/Dictionary";
import {TokenLoader} from "../SkuSelections/SkuSelections";
import {
  identifyHubSpotAnalytics,
  setHubSpotAnalytics,
  updateHubSpotAnalytics
} from "../../utils/HubSpotAnalytics";
import {setGoogleAnalytics} from "../../utils/GoogleAnalytics";
import {useAuth0, withAuth0} from "@auth0/auth0-react";

library.add(
  faEdit,
  faFileImage,
  faTimes,
  faTrashAlt,
);


class App extends Component {
  static contextType = ThemeContext;

  state = {
    activities: null,
    adminApiToken: null,
    alert: null,
    colorSchemes: null,
    communityBUnitRID: null,
    communityBUnitVideo: null,
    communityLogos: null,
    communityName: null,
    communityRID: null,
    customerRID: null,
    defaultLogo: null,
    enabledRoutes: null,
    footerImages: null,
    hideFutureActivities: false,
    numberOfFutureActivities: 99,
    homeInfo: null,
    homeProgressVideoURL: '',
    homeRID: null,
    initializingProfile: false,
    initializingAppProgress: 0,
    isAuthenticated: false,
    isCompatibleBrowser: true,
    isMultipleHomeGate: false,
    isProxyUser: false,
    jobRID: null,
    kovaToken: null,
    links: null,
    loadingStatus: '',
    newSession: true,
    originalAdminToken: null,
    prelimCustomers: null,
    pushOut: null,
    showCalendar: true,
    showPrimaryMenu: false,
    showSettingsMenu: false,
    slsOrdRID: null,
    themeContextSet: false,
    user: {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
    },
    welcomeVideoActive: true,
    windowWidth: null,
  };

  async componentDidMount() {
    this.checkBrowserCompatibility();
    setGoogleAnalytics();
    setHubSpotAnalytics();
    setPageTitle();
    this.setEnabledRoutes();
    await this.checkSession();
    await this.setColorSchemes();
    await this.setSettings();
    await this.handleLogos();
    await this.handleLinks();
    this.handleWindowBasedState();
    window.addEventListener('resize', this.handleWindowBasedState);
    this.getFavicon();
  }

  async componentDidUpdate(prevProps, prevState) {
    const {
      adminApiToken,
      colorSchemes,
      customerRID,
      enabledRoutes,
      homeInfo,
      homeRID,
      isAuthenticated,
      isMultipleHomeGate,
      isProxyUser,
      jobRID,
      links,
      newSession,
      activities,
      kovaApiToken
    } = this.state;
    const { history } = this.props;

    if (adminApiToken && !prevState.adminApiToken) {
      this.setEnabledRoutes();
    }

    if (isMultipleHomeGate && isAuthenticated && homeRID && !prevState.homeRID) {
      this.setEnabledRoutes();
    }

    if (enabledRoutes.length > 3 && isAuthenticated && isMultipleHomeGate && newSession) {
      if (enabledRoutes.find((route) => route.isDefaultRoute)) {
        history.push(enabledRoutes.find((route) => route.isDefaultRoute).path);
      }
      this.setState({ newSession: false });
      if (GlobalConfig.get(GlobalConfig.Key.CLIENT_NAME) !== 'mungo') {
        this.openAlert(alertConfig.disclaimer);
      }
    }

    if (
      (!prevState.colorSchemes && colorSchemes)
      || (prevState.colorSchemes !== colorSchemes)
      || (!prevState.homeInfo && homeInfo)
    ) {
      this.setThemeContext();
    }

    if (jobRID > 0 && (prevState.jobRID !== jobRID)) {
      this.getJobData();
    }

    if (!prevState.links && links || prevState.links !== links ||
        !prevState.homeInfo && homeInfo || prevState.homeInfo !== homeInfo) {
      this.setEnabledRoutes();
    }

    if (!prevState.activities && activities && !adminApiToken && kovaApiToken) {
      if (GlobalConfig.get(GlobalConfig.Key.CLIENT_NAME) !== 'mungo') {
        this.checkDirectories();
      }
    }

    if (isAuthenticated || adminApiToken) {
      SessionUtils.setSessionState(this.state);
    }

    if (isProxyUser && !adminApiToken && prevState.adminApiToken) {
      this.getHomeData(null);
    }

    if (customerRID && !prevState.customerRID
      && isAuthenticated && !prevState.isAuthenticated) {
      this.getHomeData(null);
    }

    if (enabledRoutes && !prevState.enabledRoutes) {
      this.checkProxyParams();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowBasedState);
  }

  checkProxyParams = () => {
    if(location.hash.length > 0) {
      const {
        customerRID,
        csrf
      } = queryString.parse(location.hash.slice(1)); //remove first char (#)
      const token = "ThisIsToLetTheAppKnowAdminHasArrived";
      if (customerRID && csrf) {
        let kovaApiToken = {csrfToken: csrf}; //create dummy IToken with just csrf.
        this.setState({adminApiToken: token, isProxyUser: true, kovaApiToken: kovaApiToken}, () => {
          this.setUserProxy(customerRID);
        });
      }
    }
  };

  selectFromMultipleHome = (home) => {
    const user = {};
    user.firstName = home.customer.NameFirst;
    user.lastName = home.customer.NameLast;
    user.email = home.customer.EmailHome;
    user.phone = home.customer.PhoneHome;
    this.getHomeData(home.home);
    this.setState({ newSession: false }, () => {
      this.setState({
        isMultipleHomeGate: true, selectedHome: home, user, newSession: true,
      });
    });
  };

  /**
  * changing favicon dynamically
  * how to use:
  * add REACT_APP_BUILDER=ARBOR in .env file
  *
  * NOTE: WILL ONLY WORK WITH ARBOR. if we add more we need to
  * add a builder client name into 'builder' array variable below
  *
  * add new favicon images to the public folder titled:${BUILDER}-<name of file>
  * i.e. ARBOR-android-icon-36x36.png
  */
  getFavicon = () => {
    const homeBuildersWithFavicons = ['ARBOR'];
    const builder = GlobalConfig.get(GlobalConfig.Key.CLIENT_NAME).toUpperCase();
    if (homeBuildersWithFavicons.includes(builder)) {
      const elements = document.getElementsByTagName('head')[0].children;
      const elementArray = [].slice.call(elements);
      elementArray.forEach((element) => {
        if (element.id && element.id.includes('favicon')) {
          let currentId = element.id;
          currentId = currentId.split('favicon/');
          currentId = `${builder}-${currentId[1]}`;
          element.href = currentId;
        }
      });
    }
  };

  setColorSchemes = async () => {
    if (!GlobalConfig.get(GlobalConfig.Key.KOVA_API_URL)) {
      this.setState({ initializingAppProgress: 33, themeContextSet: true });
      return;
    }

    try {
      const colorSchemes = await AdminApi.fetchColorSchemes();
      this.setState({ colorSchemes, initializingAppProgress: 33 });
    } catch (err) {
      this.setState({ initializingAppProgress: 33, themeContextSet: true });
      await this.openAlert(alertConfig.error.colorSchemes, true);
    }
  };

  /**
  * returns enabled routes,
  *
  * has different cases for if preliminary customers has already been set,
  * if multiple homes have already been selected or
  * if the user is an admin
  */
  setEnabledRoutes = () => {
    const {
      links,
      isMultipleHomeGate,
      adminApiToken,
      prelimCustomers,
      showCalendar,
      homeInfo
    } = this.state;
    const { history } = this.props;
    let enabledRoutes;
    if (!isMultipleHomeGate && history.location.pathname !== '/admin') {
      enabledRoutes = generateEnabledRoutes({}, 'selectOnly', prelimCustomers, null, homeInfo);
    } else {
      enabledRoutes = generateEnabledRoutes(links, null, prelimCustomers, null, homeInfo);
    }

    if (adminApiToken) {
      enabledRoutes = generateEnabledRoutes(links, null, null, null, homeInfo);
    }

    if (!showCalendar) {
      enabledRoutes = enabledRoutes.filter(((route) => route.linkText.toUpperCase() !== 'CALENDAR'));
    }
    this.setState({ enabledRoutes });
  };

  setLoadingStatus = (loadingStatus) => {
    this.setState({ loadingStatus });
  };

  setSettings = async () => {
    if (!GlobalConfig.get(GlobalConfig.Key.KOVA_API_URL)) {
      this.setState({ initializingAppProgress: 66, themeContextSet: true });
      return;
    }

    try {
      const settings = await AdminApi.fetchSettings();
      let showCalendar = true;
      let hideFutureActivities = true;
      let numberOfFutureActivities = 99;
      let showMyImages = true;
      let allowCreatingSvcReqsWhenNewAssignedExists = true;
      const calendar = settings.find((setting) => (setting.settingName === 'Calendar'));
      if (calendar) {
        showCalendar = calendar.value === "true";
      }
      const futureActivities = settings.find((setting) => (setting.settingName === 'HideFutureActivities'));
      if (futureActivities) {
        hideFutureActivities = futureActivities.value === "true";
      }

      const numOfFutureActvities = settings.find((setting) => (setting.settingName === 'NumberOfFutureActivites'));
      if (numOfFutureActvities) {
        numberOfFutureActivities = parseInt(numOfFutureActvities.value);
      }

      const allowCreatingSvcReqsWhenNewAssignedExistsSetting = settings.find((setting) => (setting.settingName === 'AllowCreatingSvcReqsWhenNewAssignedExists'));
      if (allowCreatingSvcReqsWhenNewAssignedExistsSetting) {
        allowCreatingSvcReqsWhenNewAssignedExists = allowCreatingSvcReqsWhenNewAssignedExistsSetting.value === "true";
      }

      const myImages = settings.find((setting) => (setting.settingName === 'MyImages'));
      if (myImages) {
        showMyImages = myImages.value === "true";
      }

      this.setState({
        initializingAppProgress: 66,
        showCalendar,
        hideFutureActivities,
        numberOfFutureActivities,
        showMyImages,
        allowCreatingSvcReqsWhenNewAssignedExists
      });
    } catch (err) {
      this.setState({ initializingAppProgress: 66, showCalendar: true });
      await this.openAlert(alertConfig.error.settings, true);
    }
  };

  setThemeContext = () => {
    const { updateTheme, logo } = this.context;
    const { colorSchemes, homeInfo } = this.state;

    let communityRID = 0;

    if (!colorSchemes) {
      this.setState({ themeContextSet: true });
      return;
    }

    if (homeInfo) {
      ({ CommunityRID: communityRID } = homeInfo);
    }

    let colorScheme = colorSchemes.find(
      (scheme) => scheme.communityRID === communityRID,
    );

    if (!colorScheme) {
      communityRID = 0;

      colorScheme = colorSchemes.find(
        (scheme) => scheme.communityRID === communityRID,
      );
    }

    if (colorScheme) {
      updateTheme({
        logo,
        primaryMenuBg: colorScheme.primaryMenuBg,
        primaryMenuHighlightBg: colorScheme.primaryMenuHighLightBg,
        primaryMenuFontColor: colorScheme.primaryMenuFontColor,
        settingsMenuBg: colorScheme.settingsMenuBg,
        settingsMenuHighlightBg: colorScheme.settingsMenuHighlightBg,
        titleBarBgColor: colorScheme.titleBarBgColor,
        titleBarFontColor: colorScheme.titleBarFontColor,
        titleBarFontSize: colorScheme.titleBarFontSize,
        accentColor: colorScheme.accentColor,
        node: {
          inProgress: colorScheme.nodeInProgress,
          completed: colorScheme.nodeCompleted,
          notCompleted: colorScheme.nodeNotCompleted,
          selected: colorScheme.nodeSelected,
        },
        accentColorA: colorScheme.accentColorA,
        accentColorB: colorScheme.accentColorB,
        accentColorC: colorScheme.accentColorC,
        calendarLegendText: colorScheme.calendarLegendText,
        calendarDayText: colorScheme.calendarDayText,
        calendarWeekendText: colorScheme.calendarWeekendText,
      //   calendarCurrentWeek: colorScheme.calendarCurrentWeek,
      //   calendarBeforeCurrentWeek: colorScheme.calendarBeforeCurrentWeek,
      //   calendarNowDate: colorScheme.calendarNowDate,
      //   calendarWeekDay: colorScheme.calendarWeekDay,
      //   calendarSelectedDay: colorScheme.calendarSelectedDay,
      });
    }
    this.setState({ themeContextSet: true, activeTheme: colorScheme });
  };

  checkSession = async () => {
    const session = SessionUtils.getSessionState();
    if (session) {
      const sessionState = JSON.parse(session);
      if (sessionState) {
        sessionState.newSession = false;
        const isTokenValid = await this.checkToken(sessionState);
        if (!isTokenValid) {
          this.handleLogout(true);
        } else {
          this.setState(sessionState);
        }
      }
      if (sessionState.adminApiToken) {
        this.setState(sessionState);
      }
    }
  };

  checkToken = async (sessionState) => {
    let isTokenValid = true;
    try {
      await Api.fetchHomeInfo(sessionState.customerRID);
    } catch (err) {
      const tokenExpired = err.response?.data === 'jwt expired';
      if (tokenExpired) {
        isTokenValid = false;
      }
    }
    return isTokenValid;
  };

  checkBrowserCompatibility = () => {
    const isIE = BrowserUtils.detectIE(['ie10', 'ie11']);

    if (isIE) {
      const alert = alertConfig.browser;
      this.setState({
        alert,
        isCompatibleBrowser: false,
      });
    }
  };

  handleLinks = async () => {
    const nextState = {};

    if (!GlobalConfig.get(GlobalConfig.Key.KOVA_API_URL)) {
      nextState.contact = {
        enabled: !!GlobalConfig.get(GlobalConfig.Key.CONTACT_LINK),
        text: 'contact',
        url: GlobalConfig.get(GlobalConfig.Key.CONTACT_LINK) || '',
      };
      nextState.faq = {
        enabled: !!GlobalConfig.get(GlobalConfig.Key.FAQ_LINK),
        text: 'faq',
        url: GlobalConfig.get(GlobalConfig.Key.FAQ_LINK) || '',
      };
      nextState['useful links'] = {
        enabled: !!GlobalConfig.get(GlobalConfig.Key.USEFUL_LINK),
        text: 'useful links',
        url: GlobalConfig.get(GlobalConfig.Key.USEFUL_LINK) || '',
      };

      this.setState({ links: nextState });
      return;
    }

    try {
      const fetchedLinks = await AdminApi.fetchLinks();
      fetchedLinks.forEach((link) => {
        const linkClone = { ...link };
        // Convert int to bool
        linkClone.enabled = !!linkClone.enabled;
        nextState[link.text] = linkClone;
      });

      this.setState({ links: nextState });
      return;
    } catch (err) {
      console.error('Error - failed to fetch links', err);
      await this.openAlert(alertConfig.error.links, true);
    }
  };

  /**
   * handle Logos and Footer Images
   *
   * @returns
   */
  handleLogos = async () => {
    let files;

    if (!GlobalConfig.get(GlobalConfig.Key.KOVA_API_URL)) {
      return;
    }

    try {
      files = await AdminApi.fetchFiles();
    } catch (err) {
      await this.openAlert(alertConfig.error.files, true);
      this.setState({ initializingAppProgress: 100 });
      setTimeout(() => {
        this.setState({ defaultLogo: undefined });
      }, 500);
      return;
    }

    let communityLogos = files.filter((file) => file.fileKey === 'communityLogo');
    const defaultLogo = files.find((file) => file.fileKey === 'defaultLogo');

    const footerImages = {
      footerImageOne: files.filter((file) => file.fileKey === 'footerImageOne'),
      footerImageTwo: files.filter((file) => file.fileKey === 'footerImageTwo'),
    };

    const dictionary = files.find((file) => file.fileKey === 'dictionary');



    if (dictionary) {
      try {
        let response = await fetch(dictionary.s3Path);
        const json = await response.json();
        Dictionary.appendConfig(json);
      } catch (err) {
        console.error('Error - failed to fetch dictionary', err);
      }
    }

    this.setState({ initializingAppProgress: 100 });
    setTimeout(() => {
      this.setState({
        communityLogos,
        defaultLogo,
        footerImages,
      });
    }, 500);
  };

  handleUserLogin = async (email, password) => {
    const { enabledRoutes, windowWidth } = this.state;
    const { history } = this.props;

    this.setLoadingStatus('Authenticating User');
    let kovaApiToken;

    try {
      kovaApiToken = await Api.login(email, password);
    } catch (err) {
      if (err.response?.status === 401) {
        this.openAlert(alertConfig.login);
      } else {
        this.openAlert(alertConfig.error.default);
      }
      this.setLoadingStatus('');
      return;
    }
    return await this.handleLoginResponse(kovaApiToken);

  };
  

  handleLoginResponse = async (kovaApiToken) => {
    const { enabledRoutes, windowWidth } = this.state;
    const { history } = this.props;

    const kovaToken = kovaApiToken.customerPortalToken;
    const customerRIDs = kovaApiToken.customerRIDs;

    const customers = await Api.fetchCustomers(customerRIDs)

    const kovaApiUrl = GlobalConfig.get(GlobalConfig.Key.KOVA_API_URL);
    const csrfResponse = await fetch(`${kovaApiUrl}authenticate/antiforgery`, {
      method: 'GET',
      credentials: 'include'
    });
    const csrfToken =  await csrfResponse.text();
    kovaApiToken.csrfToken = csrfToken;

    identifyHubSpotAnalytics(customers[0]?.EmailHome);

    let user = {};
    user.firstName = '';
    user.lastName = '';
    user.email = '';
    user.phone = '';
    let customerRID = null;
    let isMultipleHomeGate = false;
    if (customers.length === 1) {
      const {
        EmailHome: email,
        NameFirst: firstName,
        NameLast: lastName,
        PhoneHome: phone,
      } = customers[0];
      user = {
        email,
        firstName,
        lastName,
        phone,
      };
      customerRID = customers[0].CustomerRID;
      isMultipleHomeGate = true;
      updateHubSpotAnalytics('/login');
    } else {
      history.push(enabledRoutes[0].path);
      updateHubSpotAnalytics(enabledRoutes[0].path);
    }

    const showPrimaryMenu = windowWidth >= 1025 && customers && customers.length > 0;


    this.setState({
      isAuthenticated: true,
      showPrimaryMenu,
      kovaToken,
      kovaApiToken,
      prelimCustomers: customers,
      isMultipleHomeGate,
      customerRID,
      user,
      newSession: true,
    });
    this.setEnabledRoutes();
    this.setLoadingStatus('');
  };

  setUserProxy = async (customerRID) => {
    const { enabledRoutes, adminApiToken } = this.state;
    const { history } = this.props;

    try {

      const customerData = await Api.fetchCustomers([customerRID])
      const customer = customerData[0];

      const user = {};
      user.lastName = customer.NameLast;
      user.firstName = customer.NameFirst;
      user.email = customer.EmailHome;
      user.phone = customer.PhoneHome;

      this.setState({
        adminApiToken: null,
        originalAdminToken: adminApiToken,
        customerRID: customer.CustomerRID,
        isProxyUser: true,
        user,
        isMultipleHomeGate: true,
      });
      history.push(enabledRoutes[0].path);
    } catch (err) {
      console.error(err);
      this.openAlert(alertConfig.proxyUserLogin);
    }
  };

  openAlert = (alert, async) => {
    if (async) {
      return new Promise((resolve) => {
        const asyncAlert = { ...alert };
        asyncAlert.resolvePromise = resolve;
        this.setState({ alert: asyncAlert });
      });
    }
    this.setState({ alert });
  };

  closeAlert = () => {
    this.setState({ alert: null });
  };

  getHomeData = async (home) => {

    const { customerRID} = this.state;

    let currentCustomerRID = customerRID;
    try {
      let proxy;

      if (!home && currentCustomerRID) {
        proxy = await Api.fetchHomeInfo(currentCustomerRID);
      }

      const homeInfo = home || proxy;
      if (!currentCustomerRID && homeInfo) {
        currentCustomerRID = home ? homeInfo.CustomerRID : proxy.CustomerRID;
      }

      const homeRID = home ? homeInfo.HomeRID : proxy.HomeRID;
      const slsOrdRID = home ? homeInfo.SlsOrdRID : proxy.SlsOrdRID;
      const jobRID = home ? homeInfo.CurrentJobRID : proxy.CurrentJobRID;
      const communityRID = home ? homeInfo.CommunityRID : proxy.CommunityRID;
      const communityName = home ? homeInfo.CName : proxy.CName;
      const communityBUnit = await Api.fetchCommunityBUnit(communityRID);

      const communityBUnitRID = communityBUnit.BUnitRID;

      let communityBUnitVideo = await Api.fetchCommunityVideoLink(communityBUnitRID);

      // if there is nothing in the field or no field, set to null, to turn off the welcome video
      if (!communityBUnitVideo || !communityBUnitVideo[0]?.TextValue) {
        communityBUnitVideo = null;
      } else {
        communityBUnitVideo = communityBUnitVideo[0].TextValue;
      }

      const showPrimaryMenu = window.innerWidth >= 1025;

      this.setState({
        customerRID: currentCustomerRID,
        jobRID,
        homeRID,
        slsOrdRID,
        communityBUnitRID,
        communityName,
        homeInfo,
        communityRID,
        communityBUnitVideo,
        welcomeVideoActive: !!communityBUnitVideo,
      });
      if (!home) {
        this.setState({
          isAuthenticated: true,
          showPrimaryMenu,
        });
      }
    } catch (err) {
      const tokenExpired = err.response?.data === 'jwt expired';
      const isUnauthorized = err.response?.data.includes('401');
      if (tokenExpired) {
        this.handleLogout(true);
      } else if (isUnauthorized) {
        this.openAlert(alertConfig.error.unauthorized);
      } else {
        this.openAlert(alertConfig.error.noHomeFound);
      }
    }
  };

  setUser = (Customer) => {
    const currentUser = {};
    const name = ParseData.parseUserData(Customer.Name);
    let { lastName } = name;
    const { firstName } = name;

    const len = lastName.length - 1;
    lastName = lastName.substring(0, len);

    currentUser.lastName = lastName;
    currentUser.firstName = firstName;
    currentUser.email = Customer.Email;
    currentUser.phone = Customer.Phone;
    return currentUser;
  };

  checkDirectories = async () => {
    const {
      homeRID,
      initializingProfile,
      jobRID,
      kovaApiToken
    } = this.state;


    if(!initializingProfile) {
      this.setState({initializingProfile: true});
    }

    try {
      await Api.createDirectoriesForJobSchActs(kovaApiToken, homeRID, jobRID);
    } catch (err) {
      const tokenExpired = err.response?.data === 'jwt expired';
      if (tokenExpired) {
        this.handleLogout(true);
      } else {
        this.openAlert(alertConfig.error.directories);
      }
    }
    this.setState({initializingProfile: false});
  };

  getJobData = async () => {
    const { jobRID } = this.state;
    try {
      const [activities, customFields] = await Promise.all([
        Api.fetchActivities(jobRID),
        Api.fetchCustomFieldData(),
      ]);

      const filteredActs = ParseData.assignCustomFields(
        activities,
        customFields,
      );
      let finalFilteredActs = filteredActs;

      this.setState({
        activities: finalFilteredActs,
      });
    } catch (err) {
      if (err.response) {
        const tokenExpired = err.response.data === 'jwt expired';
        if (tokenExpired) {
          this.handleLogout(true);
        } else {
          console.error(err);
        }
      } else {
        console.error(err);
      }
    }
  };

  handleWindowBasedState = () => {
    const { isAuthenticated } = this.state;
    this.setState({
      windowWidth: window.innerWidth,
      showPrimaryMenu: window.innerWidth > 1024 && isAuthenticated,
    });
  };

  handleMenuToggle = () => {
    this.setState((prevState) => ({
      showPrimaryMenu: !prevState.showPrimaryMenu,
    }));
  };

  handleSettingsMenuToggleLogo = () => {
    const {
      showSettingsMenu,
    } = this.state;
    if (showSettingsMenu === true) {
      this.setState({
        showPrimaryMenu: true,
        showSettingsMenu: false,
      });
    }
  };

  handleSettingsMenuToggle = () => {
    const { showPrimaryMenu, showSettingsMenu } = this.state;
    if (!showPrimaryMenu && !showSettingsMenu) {
      this.setState({
        showPrimaryMenu: false,
        showSettingsMenu: true,
      });
    } else {
      this.setState((prevState) => ({
        showPrimaryMenu: !prevState.showPrimaryMenu,
        showSettingsMenu: !prevState.showSettingsMenu,
      }));
    }
  };

  hideMenu = () => {
    this.setState({
      showPrimaryMenu: false,
      showSettingsMenu: false,
    });
  };

  renderMenuType = () => {
    const { isAuthenticated, showSettingsMenu } = this.state;
    if (!isAuthenticated) {
      return false;
    } if (showSettingsMenu) {
      return 'settingsMenu';
    }
    return 'primaryMenu';
  };

  setUserAfterUpDate = (userData) => {
    this.setState((prevState) => {
      const user = { ...prevState.user };
      user.firstName = userData.firstName;
      user.lastName = userData.lastName;
      user.phone = userData.phone;
      return { user };
    });
  };

  handleLogout = (sessionExpired, resetAlert) => {
    const { history, auth0 } = this.props;

    updateHubSpotAnalytics('/logout');

    SessionUtils.clearSessionState();
    if (resetAlert) {
      this.setState({ alert: null });
    }
    const useAuth0 = GlobalConfig.get(GlobalConfig.Key.USE_AUTH0) === "true";
    if (useAuth0) {
      const {  logout } = auth0;
      logout({ returnTo: window.location.origin });
    }

    this.setState({
      activities: null,
      communityBUnitRID: null,
      communityName: null,
      homeRID: null,
      homeInfo: null,
      initializingProfile: false,
      isAuthenticated: false,
      isProxyUser: false,
      isMultipleHomeGate: false,
      user: {
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
      },
      jobRID: null,
      kovaToken: null,
      showPrimaryMenu: false,
      showSettingsMenu: false,
      customerRID: null,
      slsOrdRID: null,
      prelimCustomers: null,
    }, () => {
      if (!useAuth0) {
        Api.logout().then(() => {
          history.push('/login');
          this.setEnabledRoutes();
          if (sessionExpired) {
            this.openAlert(alertConfig.sessionExpired);
          }
        });
      };
    });
  };

  setModalVideo = (homeProgressVideoURL) => {
    this.setState({ homeProgressVideoURL });
  };

  render() {
    const {
      activities,
      alert,
      adminApiToken,
      colorSchemes,
      communityBUnitRID,
      communityBUnitVideo,
      communityLogos,
      customerRID,
      defaultLogo,
      enabledRoutes,
      footerImages,
      hideFutureActivities,
      numberOfFutureActivities,
      homeInfo,
      homeProgressVideoURL,
      homeRID,
      initializingAppProgress,
      initializingProfile,
      isAuthenticated,
      isMultipleHomeGate,
      isCompatibleBrowser,
      isProxyUser,
      kovaToken,
      links,
      loadingStatus,
      prelimCustomers,
      pushOut,
      allowCreatingSvcReqsWhenNewAssignedExists,
      showCalendar,
      showMyImages,
      showPrimaryMenu,
      showSettingsMenu,
      slsOrdRID,
      themeContextSet,
      user,
      windowWidth,
      welcomeVideoActive,
      kovaApiToken
    } = this.state;
    const { history } = this.props;

    const menuType = this.renderMenuType();
    const showNavMenu = (showPrimaryMenu || showSettingsMenu);
    const { handleLogout } = this;
    const selectActive = !customerRID && !showSettingsMenu;


    return themeContextSet && enabledRoutes && (
      <Router history={history}>
        <div className={styles.app}>
          {initializingProfile && <LoadingOverlay message="Loading... Please wait while we setup your profile" />}
          {kovaToken && welcomeVideoActive && communityBUnitVideo
            && (
            <ModalVideo
              setModalVideo={this.setModalVideo}
              videoURL={communityBUnitVideo}
              title="Welcome"
            />
            )}
          {homeProgressVideoURL
            && (
            <ModalVideo
              setModalVideo={this.setModalVideo}
              videoURL={homeProgressVideoURL}
              title="Home Progress Tracker Video"
            />
            )}
          <header className={styles.appHeader}>
            <Header
              userName={user.firstName}
              showNavMenu={showNavMenu}
              handleMenuToggle={this.handleMenuToggle}
              handleSettingsMenuToggle={this.handleSettingsMenuToggle}
              isAuthenticated={isAuthenticated}
              handleSettingsMenuToggleLogo={this.handleSettingsMenuToggleLogo}
              showSettingsMenu={showSettingsMenu}
              defaultLogo={defaultLogo}
              communityLogos={communityLogos}
              homeInfo={homeInfo}
              enabledRoutes={enabledRoutes}
              isProxyUser={isProxyUser}
              returnToAdminPanel={this.returnToAdminPanel}
            />
          </header>
          <div className={styles.displayContainer}>
            {
              showNavMenu
              && (
                <div className={styles.sideNavContainer}>
                  <SideNav
                    menuType={menuType}
                    handleLogout={handleLogout}
                    footerImages={footerImages}
                    homeInfo={homeInfo}
                  >
                    <SideNavItems
                      menuType={menuType}
                      handleLogout={handleLogout}
                      hideMenu={this.hideMenu}
                      enabledRoutes={enabledRoutes}
                      slsOrdRID={slsOrdRID}
                      windowWidth={windowWidth}
                      homeInfo={homeInfo}
                    />
                  </SideNav>
                </div>
              )
            }
            {
              windowWidth
              && (
                <main className={selectActive ? styles.selectActive : styles.selectInactive}>
                  <WindowProvider value={windowWidth}>
                    <Switch>
                      <Routes
                        activities={activities}
                        kovaApiToken={kovaApiToken}
                        adminApiToken={adminApiToken}
                        colorSchemes={colorSchemes}
                        communityBUnitRID={communityBUnitRID}
                        communityBUnitVideo={communityBUnitVideo}
                        communityLogos={communityLogos}
                        customerRID={customerRID}
                        defaultLogo={defaultLogo}
                        enabledRoutes={enabledRoutes}
                        footerImages={footerImages}
                        isAuthenticated={isAuthenticated}
                        isMultipleHomeGate={isMultipleHomeGate}
                        isProxyUser={isProxyUser}
                        handleLinks={this.handleLinks}
                        handleUserLogin={this.handleUserLogin}
                        handleLoginResponse={this.handleLoginResponse}
                        handleLogout={this.handleLogout}
                        hideFutureActivities={hideFutureActivities}
                        numberOfFutureActivities={numberOfFutureActivities}
                        windowWidth={windowWidth}
                        history={history}
                        homeInfo={homeInfo}
                        homeRID={homeRID}
                        kovaToken={kovaToken}
                        links={links}
                        loadingStatus={loadingStatus}
                        openAlert={this.openAlert}
                        selectFromMultipleHome={this.selectFromMultipleHome}
                        setColorSchemes={this.setColorSchemes}
                        setLoadingStatus={this.setLoadingStatus}
                        setModalVideo={this.setModalVideo}
                        setUserAfterUpDate={this.setUserAfterUpDate}
                        setUserProxy={this.setUserProxy}
                        allowCreatingSvcReqsWhenNewAssignedExists={allowCreatingSvcReqsWhenNewAssignedExists}
                        showCalendar={showCalendar}
                        showMyImages={showMyImages}
                        slsOrdRID={slsOrdRID}
                        user={user}
                        prelimCustomers={prelimCustomers}
                        pushOut={pushOut}
                      />
                    </Switch>
                  </WindowProvider>
                </main>
              )
            }
          </div>
          {!isCompatibleBrowser && (
            <Alert alert={alert} />
          )}
          {alert && (
            <Alert
              alert={alert}
              closeAlert={this.closeAlert}
              isLoading={alert.loadingIndicator ? !!loadingStatus : false}
            />
          )}
          {(!themeContextSet || defaultLogo === null) && (
            <div className={styles.loadingOverlay}>
              <LoadingIndicator color="#32485d" progress={initializingAppProgress} />
            </div>
          )}
          {/*<ColorSwatches />*/}
        </div>
      </Router>
    );
  }
}

export default withAuth0(App);
