import React from 'react';
import PropTypes from 'prop-types';
import styles from './ContactUs.module.css';
import { LinkObj } from '../../types';

interface ContactUsProps {
  links: LinkObj;
}

function ContactUs({ links }: ContactUsProps) {
  return (
    <div className={styles.contactUs}>
      {links.contact.url ? (
        <iframe src={links.contact.url} title="contact" />
      ) : (
        <div>This page is currently unavailable. Please check back later.</div>
      )}
    </div>
  );
}

ContactUs.propTypes = {
  links: PropTypes.object.isRequired,
};

export default ContactUs;
