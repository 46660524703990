import React from 'react';
import PropTypes from 'prop-types';
import styles from './LoadingOverlay.module.css';

const LoadingOverlay = ({ message }) => (
  <div className={styles.LoadingOverlay}>{message}</div>
);

LoadingOverlay.propTypes = {
  message: PropTypes.string.isRequired
};

export default LoadingOverlay;
