import React from 'react';
import ReactDOM from 'react-dom';
import { createBrowserHistory } from 'history';
import { ThemeContextProvider } from './context/ThemeContext';
import './index.css';
import App from './components/App/App';
import * as serviceWorker from './serviceWorker';
import GlobalConfig from './utils/GlobalConfig';
import Dictionary from "./utils/Dictionary";
import {TokenLoader} from "./components/SkuSelections/SkuSelections";
import {Auth0Provider} from "@auth0/auth0-react";
import SessionUtils from "./utils/Session";


fetch('./envconfig.json')
  .then((response) => response.json())
  .then((data) => {
    const sp = new URLSearchParams(window.location.search);
    if (sp.get("useAuth0")) {
      sessionStorage.setItem("forceUseAuth0", sp.get("useAuth0"));
    }
    if (sessionStorage.getItem("forceUseAuth0")) {
      data[GlobalConfig.Key.USE_AUTH0] = sessionStorage.getItem("forceUseAuth0");
    }

    GlobalConfig.setConfig(data);

    //Get dictionary here
    fetch('./dictionary.json')
      .then((response) => response.json())
      .then((data) => {
        Dictionary.setConfig(data);

        const history = createBrowserHistory({
          basename: GlobalConfig.get(GlobalConfig.Key.BASE_URL),
        });


        let app = <ThemeContextProvider>
                  <App history={history}/>
                </ThemeContextProvider>;

          const auth0Domain = GlobalConfig.get(GlobalConfig.Key.AUTH0_DOMAIN);
          const authClientId = GlobalConfig.get(GlobalConfig.Key.AUTH0_CLIENT_ID);          

          if (GlobalConfig.get(GlobalConfig.Key.USE_AUTH0) === "true") {
              app = <Auth0Provider
                  domain={auth0Domain}
                  clientId={authClientId}
                  redirectUri={window.location.origin}
              >
                  {app}
              </Auth0Provider>;
          }
        ReactDOM.render(app,
          document.getElementById('root'),
        );

        // If you want your app to work offline and load faster, you can change
        // unregister() to register() below. Note this comes with some pitfalls.
        // Learn more about service workers: https://bit.ly/CRA-PWA
        serviceWorker.unregister();
    });
  });
