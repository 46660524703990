import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './PhotoSection.module.css';
import Photo from '../Photo/Photo';
import { ThemeConsumer } from '../../../context/ThemeContext';
import ParseData from '../../../utils/ParseData';

function PhotoSection({
  homeStatus,
  isProxyUser,
  milestone,
  clickHandler,
  setActiveDir,
  setFileSubmitterActive,
}) {
  /**
   * listPhotos renders <Photo /> components for each photo in stage.Photos.
   * @return {Array<Object>} Array of components to be rendered.
   */
  const listPhotos = milestone.Photos.map((photo, i) => {
    const count = `${i + 1}/${milestone.Photos.length}`;
    const date = `${new Date(photo.LastUpdated).toLocaleDateString('en-US')}`;

    return (
      <Photo
        key={count}
        date={date}
        photo={photo}
        clickHandler={clickHandler}
      />
    );
  });

  const activeClass = milestone.Photos.length > 0 ? styles.active : '';
  const uploadEnabled = (!isProxyUser && homeStatus !== 'Closed' && milestone.Description === 'My Images')
    || (isProxyUser && milestone.Description !== 'My Images');

  let description = ParseData.stripWhitespace(milestone.Description);
  if (description.includes('/')) {
    description = ParseData.replaceBackslash(description);
  }

  return (
    <ThemeConsumer>
      {(theme) => (
        <div className={styles.photoSection}>
          <div
            className={styles.titleBar}
            style={{
              backgroundColor: theme.titleBarBgColor,
              color: theme.titleBarFontColor,
              fontSize: theme.titleBarFontSize,
            }}
          >
            {milestone.portalActName || milestone.Description}
            {uploadEnabled && (
              <button
                onClick={() => {
                  setActiveDir(description);
                  setFileSubmitterActive(true);
                }}
                type="button"
              >
                <span>Upload Images</span>
                <FontAwesomeIcon icon={['far', 'file-image']} />
              </button>
            )}
          </div>
          <div className={`${styles.photoContainer} ${activeClass}`}>
            {milestone.Photos.length > 0
              ? listPhotos
              : <span className={styles.noPhotos}>No photos found.</span>}
          </div>
        </div>
      )}
    </ThemeConsumer>
  );
}

PhotoSection.propTypes = {
  clickHandler: PropTypes.func.isRequired,
  homeStatus: PropTypes.string.isRequired,
  isProxyUser: PropTypes.bool.isRequired,
  milestone: PropTypes.object.isRequired,
  setActiveDir: PropTypes.func.isRequired,
  setFileSubmitterActive: PropTypes.func.isRequired,
};

export default PhotoSection;
