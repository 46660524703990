import React from 'react';
import PropTypes from 'prop-types';
import {ThemeConsumer, ThemeContextProvider} from '../../../context/ThemeContext';
import styles from './Messages.module.css';
import { LoginMessage } from '../types';
import {ClientTheme} from "../../../types";

interface MessagesProps {
  closedMessages: number[];
  closeMessage: (id: number) => void;
  messages: LoginMessage[];
}

function Messages({ closedMessages, closeMessage, messages }: MessagesProps) {
  const formatDate = (date: string) => {
    let formatted: string[] | string;
    formatted = date.split('-');
    formatted = `${formatted[1]}/${formatted[2]}/${formatted[0]}`;
    return formatted;
  };

  function listMessages(theme:ClientTheme) {
    return messages.map((message: LoginMessage) => {
      const closedClass = closedMessages.includes(message.id) ? styles.closed : '';

      return (
        <li key={message.id} className={`${styles.message} ${closedClass}`} style={{ backgroundColor: theme.accentColorA }}>
          <div>
            <span>
              {message.header && `${message.header}: `}
              {!message.hideDate && message.date && `${formatDate(message.date)} `}
              {(message.header && !message.hideDate && message.date) && '| '}
            </span>
            {message.text}
          </div>
          <button type="button" onClick={() => closeMessage(message.id)}>x</button>
        </li>
      );
    });
  }

  return (

    <ThemeConsumer>
      {(theme) => (
        <ul className={styles.Messages}>
          {listMessages(theme)}
        </ul>
      )}
    </ThemeConsumer>
  );
}

Messages.propTypes = {
  closedMessages: PropTypes.arrayOf(PropTypes.number).isRequired,
  closeMessage: PropTypes.func.isRequired,
  messages: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Messages;
