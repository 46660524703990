import React, {useEffect, useState} from "react";
import {BimaireAdMultitenantClient, KovaUserClient} from "@bimaire/kova-api-client";
import GlobalConfig from "../../../utils/GlobalConfig";
import {MsalProvider, useMsal} from '@azure/msal-react';
import {Box, Button, Paper} from "@mui/material";
import { useHistory  } from "react-router-dom";
import queryString from "query-string";

function ProxyLogin(props: any) {
    const {adClient} = props;
    const [loading, setLoading] = useState<boolean>();

    let history = useHistory();
    const msal = useMsal();
    const params = new URLSearchParams(location.search);
    const customerRID = params.get("customerrid");

    const getLoginToken = async () => {
        const kovaEnvironmentId = GlobalConfig.get(GlobalConfig.Key.KOVA_API_ENVIRONMENT);
        let kovaAPIUrl = GlobalConfig.get(GlobalConfig.Key.KOVA_API_URL)
          .replace(`/api/${kovaEnvironmentId}/`, "");

        //KOVA_API_URL has been configured to reference magic cloudfront "route" /api/envName, need to provide KovaUserClient current url as KovaUserClient requires an actual URL
        if(kovaAPIUrl == "")
        {
            kovaAPIUrl = window.location.origin;
        }

        const authority = GlobalConfig.get(GlobalConfig.Key.MSAL_AUTHORITY);

        const kovaToken = await adClient.getTokenForKovaAuth(msal, undefined, authority);
        const kovaUserClient = new KovaUserClient({kovaAuthEndpoint: kovaAPIUrl});
        let kovaLoginResponse = await kovaUserClient.loginKovaUser(kovaToken, kovaEnvironmentId, ["AdminCenter-Access", "AppPortal-ProxyLogin"]);
        if (kovaLoginResponse.success) {
            if (!kovaLoginResponse.userInfo || !kovaLoginResponse.userInfo.permissions || kovaLoginResponse.userInfo.permissions.length === 0) {
                throw new Error(`Insufficient Permissions`);
            } else {
                return {token: kovaLoginResponse.userInfo.csrfToken};
            }
        } else {
            throw new Error(`Login failed`);
        }
    }

    const login = () => {
        getLoginToken().then(result => {
            history.push( `/#customerRID=${customerRID}&csrf=${result.token}`);
            window.location.reload();
        }).catch((e: any) => {
            if (e.errorCode == "popup_window_error") {
                alert(`Error opening login popup window. This can happen if you are using IE or if popups are blocked in the browser!`);
            } else {
                alert(`Login Failed: ${e.message}`);
            }
        });
    }


    useEffect(() => {
        if (!customerRID) {
            return;
        }

        login();
    }, []);

    return (<Box p={4}>
          <span>If the login window does not open, press the button below. Check the window popup blocker!</span><br /><br />
        <Button variant={"contained"} onClick={login}>{`Click here to login as a customer`}</Button>
      </Box>
    );
}

export default function ProxyLoginWrapper() {
    const adClient = new BimaireAdMultitenantClient({
        apiScope: GlobalConfig.get(GlobalConfig.Key.API_SCOPE),
        clientId: GlobalConfig.get(GlobalConfig.Key.MSAL_CLIENT_ID)
    });

    return (
        <MsalProvider instance={adClient.getMsalPublicClientApplication()}>
            <ProxyLogin adClient={adClient}/>
        </MsalProvider>
    )
}