import React from 'react';
import PropTypes from 'prop-types';
import { ThemeConsumer } from '../../context/ThemeContext';
import { getLogoUrl } from './utils';
import styles from './SideNav.module.css';

function SideNav({
  menuType,
  children,
  footerImages,
  homeInfo,
}) {
  return (
    <ThemeConsumer>
      {(theme) => (
        <div
          className={styles.sideNavContainer}
          style={{ backgroundColor: theme[`${menuType}Bg`] }}
        >
          <div
            className={`${styles.sideNav} 
          ${styles[menuType] ? styles[menuType] : styles.default}
          `}
            style={{ backgroundColor: theme[`${menuType}Bg`] }}
          >
            {children}

          </div>
          <div className={styles.navMenuImages}>
            <div
              style={{
                backgroundImage: `url('${getLogoUrl(homeInfo, footerImages?.footerImageOne, null)}')`,
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
              }}
            />
            <div
              style={{
                backgroundImage: `url('${getLogoUrl(homeInfo, footerImages?.footerImageTwo, null)}')`,
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
              }}
            />
          </div>
        </div>
      )}
    </ThemeConsumer>
  );
}

SideNav.propTypes = {
  menuType: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
  ]).isRequired,
  children: PropTypes.object.isRequired,
};

export default SideNav;
