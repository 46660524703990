import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { ThemeConsumer } from '../../../context/ThemeContext';
import styles from './ProgressBar.module.css';
import Node from '../Node/Node';
import GlobalConfig from '../../../utils/GlobalConfig';

moment().format();

const ProgressBar = ({
  currentStep,
  firstStep,
  lastStep,
  stepsData,
  totalSteps,
  handleProgressBarScroll,
  milestoneClickHandler,
  selectedMilestone,
  preConSteps,
}) => {
  const getCustomStatus = (prevstep, step) => {
    return (prevstep && prevstep.Status.toUpperCase() === 'APPROVED' && step.Value ? 'completed' : 'notCompleted');
  };

  const getActivityStatus = (prevstep, step) => {
    let status;

    // steps are complete when they reach 'approved' status
    // and are in progress based on their startDateCur
    // else notCompleted
    if (step.Status.toUpperCase() === 'APPROVED') {
      status = 'completed';
    } else if (prevstep && prevstep.Status.toUpperCase() === 'APPROVED'
        && moment(step.StartDateCur).isBefore()
        && step.Status.toUpperCase() !== 'APPROVED') {
      status = 'inProgress';
    } else {
      status = 'notCompleted';
    }

    return status;
  };



  const assignStatus = (prevstep, step) => {
    let status;

    if (preConSteps || GlobalConfig.get(GlobalConfig.Key.TRACKER_DATA) === 'activities') {
      status = step.hasOwnProperty('Value') ? getCustomStatus(prevstep, step) : getActivityStatus(prevstep, step);
    }
    return status;
  };

  const renderNodes = () => {
    const output = [];

    let prevStep = null;
    for (
      let step = firstStep;
      step < lastStep && step <= totalSteps;
      step += 1
    ) {
      let stepData = null;
      if (Object.prototype.hasOwnProperty.call(stepsData[0], 'Activities')) {
        const initStepData = {
          ...stepsData.find(item => item.Number === step)
        };
        initStepData.Activities = initStepData.Activities.filter(act =>
          act.isTracker || !GlobalConfig.get(GlobalConfig.Key.ACTIVITY_MODULE_FILTER));
        stepData = initStepData;
      } else {
        stepData = stepsData.find(item => item.Number === step);
      }
      output.push(
        <Node
          key={stepData.Number}
          status={assignStatus(prevStep, stepData)}
          current={stepData.Number === currentStep}
          step={stepData}
          milestoneClickHandler={milestoneClickHandler}
          selectedMilestone={selectedMilestone}
        />
      );

      prevStep = stepData;
    }
    return output;
  };

  const legendData = [{text: "Completed", cssclass:"completed"},
                      {text: "In Progress", cssclass:"inProgress"},
                      {text: "Not Completed", cssclass:"notCompleted"}];

  return (
    <ThemeConsumer>
      {theme => (
        <>
        <div className={styles.progressBar}>
          <div
            className={styles.barStart}
            onClick={() => handleProgressBarScroll('left')}
            role="button"
            tabIndex="0"
            style={{ backgroundColor: theme.titleBarBgColor }}
          />
          {renderNodes()}
          <div
            className={`${currentStep !== totalSteps ? styles.barEnd : styles.completedBarEnd}`}
            onClick={() => handleProgressBarScroll('right')}
            role="button"
            tabIndex="0"
            style={{ backgroundColor: theme.titleBarBgColor }}
          />
        </div>
        <div className={styles.keyContainer}>
          {legendData.map(status => {
            return <div className={styles.keyItem}>
              <div>{status.text}</div>
              <div
                  className={styles.box}
                  style={{
                    backgroundColor: theme.node[status.cssclass]
                  }}
              />
            </div>
          })}
        </div>
        </>
      )}
    </ThemeConsumer>
  );
};

ProgressBar.defaultProps = {
  preConSteps: null,
  selectedMilestone: null
};

ProgressBar.propTypes = {
  currentStep: PropTypes.number.isRequired,
  firstStep: PropTypes.number.isRequired,
  lastStep: PropTypes.number.isRequired,
  stepsData: PropTypes.arrayOf(PropTypes.object).isRequired,
  totalSteps: PropTypes.number.isRequired,
  handleProgressBarScroll: PropTypes.func.isRequired,
  preConSteps: PropTypes.arrayOf(PropTypes.object),
  milestoneClickHandler: PropTypes.func.isRequired,
  selectedMilestone: PropTypes.object
};

export default ProgressBar;
