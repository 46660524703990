import React from 'react';
import placeholder from '../../../img/selection-default.png';
import styles from './SelectionItem.module.css';
import {ISlsOrdOpt} from "../Selections";

export interface ISelectionItemProps {
  image:string | undefined;
  opt:ISlsOrdOpt;
}

function SelectionItem(props: ISelectionItemProps) {
  const {image, opt} = props;

    let optValDescription = opt.OptValDescr;
    if(optValDescription === "n/a" && opt.CurrentQty > 0) //this is true for quantity options
    {
        optValDescription = "qty " + opt.CurrentQty;
    }

  return (
    <div className={styles.SelectionItem}>
      {image ? (
        <img src={image} alt="selection" />
      ) : (
        <img src={placeholder} alt="selection" />
      )}
      <div>
        <span><b>{`${opt.OptSelDescr}:`}</b></span>
        <span>{optValDescription}</span>
        {opt.Color
        && (
        <span>
          <p className={styles.boldSpan}>Color: </p>
          {`${opt.Color}`}
        </span>
        )}
        {opt.Style
        && (
        <span>
          <p className={styles.boldSpan}>Style: </p>
          {`${opt.Style}`}
        </span>
        )}
      </div>
    </div>
  );
}

export default SelectionItem;
