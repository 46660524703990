import React, {useContext} from 'react';
import { IconButton, ImageListItem } from '@mui/material';
import { Delete as DeleteIcon } from '@mui/icons-material';
import {ThemeContext} from "../../../context/ThemeContext";


export function listFilePreviews(files, handleRemoveImg, svcReqItem, fieldsEditable) {
    const theme = useContext(ThemeContext);

  return files.map((file) => {
    const key = file.name || file.fileURL;
    const src = file.fileURL
      ? file.fileURL
      : URL.createObjectURL(file);
    return (
      <ImageListItem key={key} sx={{ overflow: 'hidden', position: 'relative' }}>
        { fieldsEditable &&
          <IconButton
            onClick={() => handleRemoveImg(file.fileURL ? file.id : file.name)}
            sx={{ position: 'absolute', right: '5px', top: '5px', backgroundColor: theme.accentColorA }}
          >
            <DeleteIcon />
          </IconButton>
        }
        <img
          alt={file.name}
          src={src}
        />
      </ImageListItem>
    );
  });
}

export function allowItemFieldEdit(useFieldpoint, svcReqDisabled, svcReqItem) {
  return !useFieldpoint && (!svcReqItem.svcReqItmRID || (svcReqItem.status === 'New' && !svcReqDisabled)) || (useFieldpoint && !svcReqItem.svcReqItmRID)
}