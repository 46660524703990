import React from 'react';
import PropTypes from 'prop-types';
import { ThemeConsumer } from '../../../context/ThemeContext';
import styles from './NotifyItem.module.css';
import { Link } from 'react-router-dom';

const NotifyItem = (props) => {
  const { notifyArray } = props;
  const listMessages = notif => notif.list.map(message =>
    <div key={message}>{message}</div>);
  const listDocs = notif => notif.docs.map(doc => (
    <a
      key={doc.fileName}
      className={styles.docLink}
      href={doc.link}
      download
    >
      {doc.fileName}
    </a>
  ));
  
  const renderIcon = (icon) => {
    switch (icon) {
      case 'bell':
        return (
          <ThemeConsumer>
            {theme => (
              <svg id="Bell_Ring" xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50">
                <path id="Path_479" data-name="Path 479" d="M28.988,24.757a11.291,11.291,0,0,1-1.909-6.279V12.111a9.774,9.774,0,0,0-8.613-9.7l0-2.408H16.11V2.426A9.81,9.81,0,0,0,7.566,12.2v6.279a11.291,11.291,0,0,1-1.909,6.279L3.41,28.111h10.6a3.567,3.567,0,0,0,6.617,0h10.6Z" transform="translate(2.698)" fill={theme.accentColorA} />
                <path id="Path_480" data-name="Path 480" d="M21.289,4.081,19.63,5.776a8.918,8.918,0,0,1,.089,12.486l1.694,1.623A11.344,11.344,0,0,0,21.289,4.01Z" transform="translate(15.409 3.143)" fill={theme.accentColorA} />
                <path id="Path_481" data-name="Path 481" d="M19.429,5.91,17.77,7.551a4.317,4.317,0,0,1,1.249,3.05,4.263,4.263,0,0,1-1.213,3l1.677,1.641a6.635,6.635,0,0,0,0-9.329Z" transform="translate(13.951 4.632)" fill={theme.accentColorA} />
                <path id="Path_482" data-name="Path 482" d="M4.988,5.729,3.329,4.07A11.309,11.309,0,0,0,3.2,20l1.677-1.784A8.918,8.918,0,0,1,4.988,5.729Z" transform="translate(0.014 3.19)" fill={theme.accentColorA} />
                <path id="Path_483" data-name="Path 483" d="M6.221,7.551,4.562,5.91a6.635,6.635,0,0,0,0,9.329L6.238,13.6a4.209,4.209,0,0,1-1.231-3,4.263,4.263,0,0,1,1.213-3.05Z" transform="translate(2.099 4.632)" fill={theme.accentColorA} />
              </svg>
            )}
          </ThemeConsumer>
        );
      case 'calendar':
        return (
          <ThemeConsumer>
            {theme => (
              <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50">
                <g id="Calendar" transform="translate(2.698)">
                  <rect id="Rectangle_1228" data-name="Rectangle 1228" width="26.454" height="19.4" transform="translate(3.26 9.51)" fill={theme.primaryMenuHighlightBg} stroke={theme.primaryMenuHighlightBg} stroke-linejoin="round" stroke-width="6.52"/>
                  <rect id="Rectangle_1229" data-name="Rectangle 1229" width="26.454" height="15.872" transform="translate(3.26 12.905)" fill="#fff"/>
                  <rect id="Rectangle_1230" data-name="Rectangle 1230" width="4.497" height="4.497" transform="translate(5.941 15.066)" fill={theme.primaryMenuHighlightBg} />
                  <rect id="Rectangle_1231" data-name="Rectangle 1231" width="4.497" height="4.497" transform="translate(5.941 22.12)" fill={theme.primaryMenuHighlightBg} />
                  <rect id="Rectangle_1232" data-name="Rectangle 1232" width="4.497" height="4.497" transform="translate(13.877 15.066)" fill={theme.primaryMenuHighlightBg} />
                  <rect id="Rectangle_1233" data-name="Rectangle 1233" width="4.497" height="4.497" transform="translate(13.877 22.12)" fill={theme.primaryMenuHighlightBg} />
                  <rect id="Rectangle_1234" data-name="Rectangle 1234" width="4.497" height="4.497" transform="translate(21.813 15.066)" fill={theme.primaryMenuHighlightBg} />
                  <rect id="Rectangle_1235" data-name="Rectangle 1235" width="4.497" height="4.497" transform="translate(21.813 22.12)" fill={theme.primaryMenuHighlightBg} />
                  <g id="Rectangle_1236" data-name="Rectangle 1236" transform="translate(6.302 0.622)" fill={theme.primaryMenuHighlightBg} stroke="#fff" stroke-linecap="round" stroke-miterlimit="10" stroke-width="1.5">
                    <rect width="3.774" height="8.747" rx="1.65" stroke="none"/>
                    <rect x="-0.75" y="-0.75" width="5.274" height="10.247" rx="2.4" fill="none"/>
                  </g>
                  <g id="Rectangle_1237" data-name="Rectangle 1237" transform="translate(22.228 0.56)" fill={theme.primaryMenuHighlightBg} stroke="#fff" stroke-linecap="round" stroke-miterlimit="10" stroke-width="1.5">
                    <rect width="3.774" height="8.747" rx="1.65" stroke="none"/>
                    <rect x="-0.75" y="-0.75" width="5.274" height="10.247" rx="2.4" fill="none"/>
                  </g>
                </g>
              </svg>
            )}
          </ThemeConsumer>
        );
      case 'photo':
        return (
          <ThemeConsumer>
            {theme => (
              <svg id="Inbox" xmlns="http://www.w3.org/2000/svg" width="45" height="45" viewBox="0 0 45 45">
                <rect id="rectangle" width="40" height="40" fill="none"/>
                <path id="path" d="M29.839,3H6.338A3.327,3.327,0,0,0,3.017,6.355L3,29.839a3.342,3.342,0,0,0,3.338,3.355h23.5a3.355,3.355,0,0,0,3.355-3.355V6.355A3.354,3.354,0,0,0,29.839,3Zm0,20.129h-6.71a5.032,5.032,0,0,1-10.065,0H6.338V6.355h23.5V23.129Zm-5.032-8.387H21.452V9.71h-6.71v5.032H11.387l6.71,6.71,6.71-6.71Z" transform="translate(-1.903 1.903)" fill={theme.accentColorB} fill-rule="evenodd"/>
              </svg>
            )}
          </ThemeConsumer>
        );
      case 'doc':
        return (
          <ThemeConsumer>
            {theme => (
              <svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" viewBox="0 0 45 45">
                <g id="Summary_Icon_Selected" transform="translate(-6 -6.25)">
                  <rect id="Rectangle_1109" data-name="Rectangle 1109" width="23.542" height="28.25" transform="translate(9.234 7.75)" fill="none" stroke={theme.accentColorC} stroke-linejoin="round" stroke-width="3"/>
                  <rect id="Rectangle_1110" data-name="Rectangle 1110" width="19" height="24" transform="translate(11.5 9.75)" fill="#fff" stroke="#fff" stroke-linejoin="round" stroke-width="3"/>
                  <circle id="Ellipse_84" data-name="Ellipse 84" cx="0.981" cy="0.981" r="0.981" transform="translate(12.797 12.458)" fill={theme.accentColorC}/>
                  <circle id="Ellipse_85" data-name="Ellipse 85" cx="0.981" cy="0.981" r="0.981" transform="translate(12.797 16.382)" fill={theme.accentColorC}/>
                  <circle id="Ellipse_86" data-name="Ellipse 86" cx="0.981" cy="0.981" r="0.981" transform="translate(12.797 20.306)" fill={theme.accentColorC}/>
                  <circle id="Ellipse_87" data-name="Ellipse 87" cx="0.981" cy="0.981" r="0.981" transform="translate(12.797 24.229)" fill={theme.accentColorC}/>
                  <circle id="Ellipse_88" data-name="Ellipse 88" cx="0.981" cy="0.981" r="0.981" transform="translate(12.797 28.153)" fill={theme.accentColorC}/>
                  <line id="Line_216" data-name="Line 216" x2="5.446" transform="translate(17.309 13.439)" fill="none" stroke={theme.accentColorC} stroke-linejoin="round" stroke-width="1"/>
                  <line id="Line_217" data-name="Line 217" x2="5.446" transform="translate(17.309 17.363)" fill="none" stroke={theme.accentColorC} stroke-linejoin="round" stroke-width="1"/>
                  <line id="Line_218" data-name="Line 218" x2="9.37" transform="translate(17.309 21.286)" fill="none" stroke={theme.accentColorC} stroke-linejoin="round" stroke-width="1"/>
                  <line id="Line_219" data-name="Line 219" x2="9.37" transform="translate(17.309 25.21)" fill="none" stroke={theme.accentColorC} stroke-linejoin="round" stroke-width="1"/>
                  <line id="Line_220" data-name="Line 220" x2="9.37" transform="translate(17.309 29.134)" fill="none" stroke={theme.accentColorC} stroke-linejoin="round" stroke-width="1"/>
                  <line id="Line_221" data-name="Line 221" x2="5.124" y2="5.124" transform="translate(25.109 14.813)" fill="none" stroke="#fff" stroke-linejoin="round" stroke-width="7"/>
                  <line id="Line_222" data-name="Line 222" y1="8.993" x2="8.993" transform="translate(28.844 11.211)" fill="none" stroke="#fff" stroke-linejoin="round" stroke-width="7"/>
                  <line id="Line_223" data-name="Line 223" x2="4.996" y2="4.543" transform="translate(26.004 15.707)" fill="none" stroke={theme.accentColorC} stroke-linejoin="round" stroke-width="3"/>
                  <line id="Line_224" data-name="Line 224" y1="8.993" x2="8.993" transform="translate(28.844 11.211)" fill="none" stroke={theme.accentColorC} stroke-linejoin="round" stroke-width="3"/>
                  <line id="Line_225" data-name="Line 225" x2="2.354" transform="translate(7.5 13.439)" fill="none" stroke="#fff" stroke-linecap="round" stroke-miterlimit="10" stroke-width="3"/>
                  <line id="Line_226" data-name="Line 226" x2="2.354" transform="translate(7.5 13.439)" fill="none" stroke={theme.accentColorC} stroke-linecap="round" stroke-miterlimit="10" stroke-width="1"/>
                  <line id="Line_227" data-name="Line 227" x2="2.354" transform="translate(7.5 17.363)" fill="none" stroke="#fff" stroke-linecap="round" stroke-miterlimit="10" stroke-width="3"/>
                  <line id="Line_228" data-name="Line 228" x2="2.354" transform="translate(7.5 17.363)" fill="none" stroke={theme.accentColorC} stroke-linecap="round" stroke-miterlimit="10" stroke-width="1"/>
                  <line id="Line_229" data-name="Line 229" x2="2.354" transform="translate(7.5 21.286)" fill="none" stroke="#fff" stroke-linecap="round" stroke-miterlimit="10" stroke-width="3"/>
                  <line id="Line_230" data-name="Line 230" x2="2.354" transform="translate(7.5 21.286)" fill="none" stroke={theme.accentColorC} stroke-linecap="round" stroke-miterlimit="10" stroke-width="1"/>
                  <line id="Line_231" data-name="Line 231" x2="2.354" transform="translate(7.5 25.21)" fill="none" stroke="#fff" stroke-linecap="round" stroke-miterlimit="10" stroke-width="3"/>
                  <line id="Line_232" data-name="Line 232" x2="2.354" transform="translate(7.5 25.21)" fill="none" stroke={theme.accentColorC} stroke-linecap="round" stroke-miterlimit="10" stroke-width="1"/>
                  <line id="Line_233" data-name="Line 233" x2="2.354" transform="translate(7.5 29.134)" fill="none" stroke="#fff" stroke-linecap="round" stroke-miterlimit="10" stroke-width="3"/>
                  <line id="Line_234" data-name="Line 234" x2="2.354" transform="translate(7.5 29.134)" fill="none" stroke={theme.accentColorC} stroke-linecap="round" stroke-miterlimit="10" stroke-width="1"/>
                </g>
              </svg>
            )}
          </ThemeConsumer>
        );
      default:
        break;
    }
  }

  const notifyMessageLink = (message) => {
    if (message === 'New Document(s) : ') {
      return <Link to="/documents">{message}</Link>
    } 
    return message
  }

  const countLink = (message, count) => {
    if (message === 'New Document(s) : ') {
      return <Link to="/documents">{count}</Link>
    } 
    return count
  }

  return (
    <>
      {notifyArray.map(notif => (
        <li key={`${notif.time}_${notif.date}_${notif.icon}_${notif.stage}_${notif.message}`} className={styles.item}>
          <div className={styles.icon}>
            {renderIcon(notif.icon)}
          </div>
          <div className={styles.details}>
            <span className={styles.date}>{notif.date}</span>
            <div className={styles.message}>
              {notifyMessageLink(notif.message)}
              {notif.list && listMessages(notif)}
              <span>{countLink(notif.message, notif.count)}</span>
            </div>
            <div className={styles.append}>
              <span>{notif.stage}</span>
              <a href={notif.link} download>{notif.fileName}</a>
              {notif.docs && listDocs(notif)}
            </div>
          </div>
        </li>
      ))}
    </>
  );
};

NotifyItem.propTypes = {
  notifyArray: PropTypes.array.isRequired
};

export default NotifyItem;
