import {Dictionary} from "../../utils/Dictionary";
import React from "react";

interface IDangerousTextAsHtmlProps {
  dictionary: Dictionary;
  dictionaryKey:string;

}
export function DictionaryDangerousTextAsHtml(props: IDangerousTextAsHtmlProps) {
  const { dictionary, dictionaryKey } = props;

  //Dictionary can only be edited by admin users
  const text = dictionary.getText(dictionaryKey)
  return (<span x-dict={dictionaryKey} className={'configurableText'}>
      <div dangerouslySetInnerHTML={{__html: text}}>
      </div>
    </span>);
}