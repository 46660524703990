import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Box, Button, CardHeader } from '@mui/material';
import Api from '../../api/Api';
import { ThemeContext } from '../../context/ThemeContext';
import styles from './SelectHome.module.css';
import LoadingIndicator from '../../shared/LoadingIndicator';
import GlobalConfig from '../../utils/GlobalConfig';
import Dictionary from "../../utils/Dictionary";
import {DictionaryText} from "../Dictionary/DictionaryText";

function SelectHome({
  selectFromMultipleHome,
  prelimCustomers,
}) {
  const theme = useContext(ThemeContext);
  const [homes, setHomes] = useState();

  const fetchHomes = async (customerRID) => {
    const home = await Api.fetchHomeInfo(customerRID);
    return home;
  };

  const homePromises = async () => (
    Promise.all(prelimCustomers.map((customer) => (fetchHomes(customer.CustomerRID))))
  );

  const fetchAndSetHomes = async () => {
    const fetchedHomes = await homePromises();
    const homeArray = [];
    const backUpHomes = [];
    fetchedHomes.forEach((home) =>
    {
      if (!home ||
        home.CustomerRID <= 0 ||
        home.SlsOrdRID <= 0
      ) {
        return;
      }

      const homeObj = {};
      const customer = prelimCustomers.find((cust) => (
        cust.CustomerRID === home.CustomerRID
      ));
      homeObj.customer = customer;
      homeObj.home = home;
      // home age in months, defaults to 16 months if not in envs
      const homeAgeCutOff = Number(GlobalConfig.get(GlobalConfig.Key.ACTIVE_TIME)) || 16;

      const cutOffTime = moment().subtract(homeAgeCutOff, 'months');
      // if homes are not populating check the closedDate field in the home obj.
      // it appears like some time zone calculations
      // can mess this up. Discovered when I (William) traveled from MST to EST.
      if (moment(home.ClosedDate)._i < moment('1900-01-02T00:00:00-10:00')._i) {
        homeArray.push(homeObj);
      } else if (cutOffTime.isBefore(home.ClosedDate)) {
        homeArray.push(homeObj);
      }
      backUpHomes.push(homeObj);
    });

    if (homeArray.length === 1) {
      selectFromMultipleHome(homeArray[0]);
    }
    if (homeArray.length === 0) {
      setHomes(backUpHomes);
      return;
    }
    if(homeArray.length > 1) {
      homeArray.sort((a, b) => {
        const nameA = a.home.Name.toUpperCase();
        const nameB = b.home.Name.toUpperCase();

        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        return 0;
      });
    }
    setHomes(homeArray);
  };

  useEffect(() => {
    fetchAndSetHomes();
  }, []);

  const renderHomes = () => homes.map((home) => (
    <Button
      onClick={() => selectFromMultipleHome(home)}
      sx={{
        border: `2px solid ${theme.titleBarBgColor}`,
        color: theme.titleBarBgColor,
        marginBottom: '1.5rem',
        '&:last-child': {
          marginBottom: 0,
        },
        justifyContent: 'flex-start',
      }}
      variant="outlined"
      key={home.home.HomeRID}
    >
      {home.home.Name}
    </Button>
  ));

  const customersFound = prelimCustomers && prelimCustomers.length > 0;
  const homesFound = homes && homes.length > 1;

  return (
    <div className={styles.container}>
      {!customersFound && (
        <div className={styles.preContractSplash}>
          <div><h2><DictionaryText dictionary={Dictionary} dictionaryKey={Dictionary.SelectHome_NoCustomer} /></h2></div>
        </div>
      )}
      {customersFound && !homesFound && (
        <div className={styles.loadingIndicator}>
          <LoadingIndicator color={theme.accentColorA} />
        </div>
      )}
      {homesFound && (
        <div
          className={styles.SelectHome}
        >
          <Box>
            <CardHeader
              sx={{
                backgroundColor: theme.titleBarBgColor,
                color: theme.titleBarFontColor,
                fontSize: theme.titleBarFontSize,
                marginBottom: '1rem',
              }}
              title="Select Home"
              titleTypographyProps={{
                variant: 'h6',
              }}
            />
            <div className={styles.panelContent}>
              <CardHeader
                sx={{
                  paddingBottom: '1.5rem',
                  paddingLeft: 0,
                  paddingTop: 0,
                }}
                title="Multiple homes were found, please select the one that you want to review"
                titleTypographyProps={{
                  variant: 'body1',
                }}
              />
              {homes && (
                renderHomes(theme)
              )}
            </div>
          </Box>
        </div>
      )}
    </div>
  );
}

SelectHome.propTypes = {
  prelimCustomers: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectFromMultipleHome: PropTypes.func.isRequired,
};

export default SelectHome;
