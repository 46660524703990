import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { ThemeConsumer } from '../../context/ThemeContext';
import { getLogoUrl } from './utils';
import MenuButton from './MenuButton/MenuButton';
import styles from './Header.module.css';
import settingsIcon from '../../img/settings-icon.png';
import {HomeInfoObj} from "../../types";

interface IHeaderProps {
  userName:string;
  showNavMenu:boolean;
  handleMenuToggle:(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void,
  handleSettingsMenuToggle:(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void,
  isAuthenticated:boolean,
  handleSettingsMenuToggleLogo:(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void,
  showSettingsMenu:boolean,
  defaultLogo:unknown,
  communityLogos:unknown[],
  homeInfo:HomeInfoObj,
  enabledRoutes:any,
  isProxyUser:boolean,
  returnToAdminPanel:(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void,
}

function Header({
  userName,
  showNavMenu,
  handleMenuToggle,
  handleSettingsMenuToggle,
  isAuthenticated,
  handleSettingsMenuToggleLogo,
  showSettingsMenu,
  defaultLogo,
  communityLogos,
  homeInfo,
  enabledRoutes,
  isProxyUser,
  returnToAdminPanel,
}:IHeaderProps) {
  const findDefaultRoute = () => {
    if (enabledRoutes.find((route:any) => route.isDefaultRoute)) {
      return enabledRoutes.find((route:any) => route.isDefaultRoute).linkText;
    }
  };

  const displaymenu = () => {
    if (false && isProxyUser) {
      return (
        <div
          onClick={returnToAdminPanel}
          className={styles.userSettings}
          role="button"
          tabIndex={0}
        >
          <div className={styles.userName}>
            Admin View
          </div>
          {(userName.length > 1) && (
          <img
            className={styles.settingsIcon}
            src={settingsIcon}
            alt="settings"
          />
          )}
        </div>
      );
    }
    return (
      <Link to={showSettingsMenu ? `/${findDefaultRoute()}` : '/my-profile'}>
        <div
          onClick={handleSettingsMenuToggle}
          className={styles.userSettings}
          role="button"
          tabIndex={0}
        >
          <div className={styles.userName}>
            {`${userName}`}
          </div>
          <img
            className={styles.settingsIcon}
            src={settingsIcon}
            alt="settings"
          />
        </div>
      </Link>
    );
  };

  return (
    <ThemeConsumer>
      {(theme) => (
        <div className={styles.header}>
          <MenuButton
            showNavMenu={showNavMenu}
            handleMenuToggle={handleMenuToggle}
          />
          <div className={styles.logoContainer}>
            {isAuthenticated
              ? (
                <Link to={`/${findDefaultRoute()}`} onClick={handleSettingsMenuToggleLogo}>
                  <img
                    className={styles.logo}
                    src={getLogoUrl(homeInfo, defaultLogo, communityLogos, theme.logo)}
                    alt="logo"
                  />
                </Link>
              ) : (
                <img
                  className={styles.logo}
                  src={getLogoUrl(homeInfo, defaultLogo, communityLogos, theme.logo)}
                  alt="logo"
                />
              )}
          </div>
          {isProxyUser ? (
            <div className={styles.customerInfoContainer}>
              {homeInfo && (
                <>
                  {homeInfo.CustName}
                  <br />
                  {homeInfo.LotStreetAddress}
                </>
              )}
            </div>
          )
            : (
              <div className={styles.customerInfoContainer}>
                {homeInfo ? homeInfo.LotStreetAddress : ''}
              </div>
            )}
          {isAuthenticated && (
            displaymenu()
          )}
        </div>
      )}
    </ThemeConsumer>
  );
}

export default Header;
