import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styles from './Photos.module.css';
import Api from '../../api/Api';
import ParseData from '../../utils/ParseData';
import FileSubmitter from '../FileSubmitter';
import PhotoSection from './PhotoSection/PhotoSection';
import PhotoOverlay from './PhotoOverlay/PhotoOverlay';
import { ThemeConsumer } from '../../context/ThemeContext';
import GlobalConfig from '../../utils/GlobalConfig';
import alertConfig from '../../config/alert';

class Photos extends Component {
  constructor(props) {
    super(props);

    this.state = {
      photoData: null,
      overlay: false,
      currentPhoto: null,
      activeDirName: '',
      fileSubmitterActive: false,
    };
  }

  componentDidMount() {
    this.generatePhotoData();
  }

  /**
   * generatePhotoData creates a new array by assigning a Photos property to
   * the stages data objects and sets to state. This data is used by the
   * listPhotoSections method.
   */
  generatePhotoData = async () => {
    const {
      activities,
      handleLogout,
      openAlert,
      showMyImages,
    } = this.props;

    try {
      const milestones = [...activities].filter((activity) => activity.isTracker).sort(
                    (a, b) => new Date(a.StartDateCur).getTime()
                            - new Date(b.StartDateCur).getTime());

      const photos = await this.getPhotos(milestones);
      const photoData = milestones.map((milestone, i) => {
        const data = { ...milestone };
        data.Photos = photos[i];
        return data;
      });
      if(showMyImages) {
        photoData.push({
          Description: 'My Images',
          Photos: photos[photos.length - 1],
        });
      }

      this.setState({ photoData });
    } catch (err) {
      if (err.response) {
        const tokenExpired = err.response.data === 'jwt expired';
        if (tokenExpired) {
          handleLogout(true);
        } else {
          openAlert(alertConfig.errors.generatePhotoData);
        }
      } else {
        console.error(err);
      }
    }
  };

  /**
   * getPhotos retrieves the photo data for each stage.
   * @return {Array<Array<Object>>} Photo data arrays sorted by stage.
   */
  getPhotos = async (milestones) => {
    const {
      homeRID,
      showMyImages
    } = this.props;
    const stageKeys = milestones.map(milestone => milestone.Description);

    if(showMyImages) {
      stageKeys.push('MyImages');
    }

    const photos = await Api.fetchStagePhotos(homeRID, stageKeys);
    return photos;
  };

  /**
   * listRecentPhotos renders an <img /> element for the most recent 3 photos.
   * @return {Array<Object>} Array of html elements to be rendered.
   */
  listRecentPhotos = () => {
    const { photoData } = this.state;
    // Combine all photo objects into one array
    const merged = photoData.reduce((acc, val) => acc.concat(val.Photos), []);
    // Sort from most to least recent and grab first 3 photos
    const recentPhotos = merged.sort(
      (a, b) => new Date(b.LastUpdated) - new Date(a.LastUpdated),
    ).slice(0, 3);
    const classNames = ['largePhoto', 'smallPhoto', 'pullRight'];

    return recentPhotos.map((photo, i) => {
      const style = {
        background: `url(${photo.RepDocFileHandlerLink ? photo.RepDocFileHandlerLink : photo.MiddlewareDownloadURL})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
      };

      return (
        <div
          key={photo.ObjectRID}
          className={styles[classNames[i]]}
          style={style}
        />
      );
    });
  };

  /**
   * listPhotoSections renders a <PhotoSection /> component for each milestone.
   * @return {Array<Object>} Array of PhotoSection components to be rendered.
   */
  listPhotoSections = () => {
    const { homeInfo, isProxyUser } = this.props;
    const { photoData } = this.state;

    return photoData.map((data) => (
      data.Description !== 'Release To Estimating'
        ? (
          <PhotoSection
            key={data.Description}
            homeStatus={homeInfo.Status}
            isProxyUser={isProxyUser}
            milestone={data}
            clickHandler={this.handleClick}
            setActiveDir={this.setActiveDir}
            setFileSubmitterActive={this.setFileSubmitterActive}
          />
        ) : (
          null
        )
    )).filter(Boolean);
  };

  handleClick = (photo) => {
    const { overlay } = this.state;
    const overlayState = !overlay;

    this.setState({
      overlay: overlayState,
      currentPhoto: photo,
    });
  };

  onClose = () => {
    this.setState({
      overlay: false,
      currentPhoto: null,
    });
  };

  setActiveDir = (activeDirName) => {
    this.setState({ activeDirName });
  };

  setFileSubmitterActive = (fileSubmitterActive) => {
    this.setState({ fileSubmitterActive });
  };

  handleUploadFiles = async (isProxyUser, files) => {
    const { homeRID, kovaApiToken } = this.props;
    const { activeDirName } = this.state;

    await Api.uploadRepDocImgs(
        activeDirName,
        files,
        homeRID,
        kovaApiToken
    );

    await this.generatePhotoData();
  };

  render() {
    const {
      handleLogout,
      homeRID,
      isProxyUser,
      openAlert,
      widget,
      kovaApiToken
    } = this.props;
    const {
      currentPhoto,
      fileSubmitterActive,
      overlay,
      photoData,
    } = this.state;

    return widget ? (
      <ThemeConsumer>
        {(theme) => (
          <div className={styles.recentPhotos}>
            <div
              style={{
                backgroundColor: theme.titleBarBgColor,
                color: theme.titleBarFontColor,
                fontSize: theme.titleBarFontSize,
              }}
              className={styles.titleBar}
            >
              recently uploaded
            </div>
            <div className={styles.panelContent}>
              <div className={styles.photoPanel}>
                {photoData && this.listRecentPhotos()}
              </div>
              <div className={styles.photoLink}>
                <Link to="/photos" style={{ color: theme.accentColorA }}>view scrapbook</Link>
              </div>
            </div>
          </div>
        )}
      </ThemeConsumer>
    ) : (
      <div className={styles.photos}>
        {overlay && photoData && (
          <PhotoOverlay
            currentPhoto={currentPhoto}
            generatePhotoData={this.generatePhotoData}
            handleLogout={handleLogout}
            homeRID={homeRID}
            isProxyUser={isProxyUser}
            photoData={photoData}
            onClose={this.onClose}
            openAlert={openAlert}
            kovaApiToken={kovaApiToken}
          />
        )}
        {fileSubmitterActive && (
          <div className={styles.fileSubmitterOverlay}>
            <FileSubmitter
              handleLogout={handleLogout}
              openAlert={openAlert}
              setFileSubmitterActive={this.setFileSubmitterActive}
              uploadFiles={(files) => this.handleUploadFiles(isProxyUser, files)}
            />
          </div>
        )}
        <div className={styles.photoSectionWrapperScrollHider}>
          <div className={styles.photoSectionWrapper}>
            {photoData && this.listPhotoSections()}
          </div>
        </div>
      </div>
    );
  }
}

Photos.defaultProps = {
  homeRID: null,
  widget: false,
};

Photos.propTypes = {
  activities: PropTypes.arrayOf(PropTypes.object).isRequired,
  handleLogout: PropTypes.func.isRequired,
  homeInfo: PropTypes.object.isRequired,
  homeRID: PropTypes.number,
  isProxyUser: PropTypes.bool.isRequired,
  openAlert: PropTypes.func.isRequired,
  widget: PropTypes.bool,
};

export default Photos;
