import React, { useContext } from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import FormHelperText from "@mui/material/FormHelperText";
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  ImageList,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { Delete, FileUpload } from '@mui/icons-material';
import { ThemeContext } from '../../../context/ThemeContext';
import { listFilePreviews, allowItemFieldEdit } from './utils';
import styles from './ServiceRequestItem.module.css';
import GlobalConfig from "../../../utils/GlobalConfig";

function ServiceRequestItem({
  handleChange,
  handleClose,
  handleRemoveExistingImg,
  handleRemoveFileUpload,
  hideFiles,
  index,
  optLocations,
  svcReqItem,
  svcReqDisabled
}) {
  let useFieldpoint = GlobalConfig.get(GlobalConfig.Key.WARRANTY_PROVIDER) === "Fieldpoint";
  const theme = useContext(ThemeContext);
  const muiTheme = useTheme();
  const largeImgRow = useMediaQuery('(min-width: 550px) and (max-width: 899px), (min-width: 1150px)');
  const locationOpts = optLocations
      .filter(optLoc => !optLoc.Name.startsWith(GlobalConfig.get(GlobalConfig.Key.OPTLOC_FILTER)))
      .map((optLoc) => (
        { text: optLoc.Name, value: optLoc.Name }
      ));
  locationOpts.sort((a,b) => {
    return a.text.toLowerCase().localeCompare(b.text.toLowerCase());
  });
  locationOpts.unshift({ text: 'Location', value: '' });
  const { description, key, location, dirty } = svcReqItem;

  const fieldsEditable = allowItemFieldEdit(useFieldpoint, svcReqDisabled, svcReqItem);

  return (
    <Box className={styles.ServiceRequestItem} sx={dirty ? {backgroundColor: 'lightpink'} : {}}>
      {dirty && <FormHelperText sx={{color: 'red'}}>Problem occurred while saving this item</FormHelperText>}
      <Box
        sx={{
          [muiTheme.breakpoints.up('md')]: {
            columnGap: '2rem',
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
          },
        }}
      >
        <Box sx={{ display: 'flex' }}>
          <div>{`${index + 1}.`}</div>
          <Box sx={{ flexGrow: 1, marginLeft: '1rem' }}>
            { !useFieldpoint &&
            <Select
              disabled={!fieldsEditable}
              displayEmpty
              name="location"
              onChange={handleChange}
              required
              sx={{
                backgroundColor: '#fff',
                display: 'block',
                marginBottom: '1rem',
                textAlign: 'left',
              }}
              value={location}
              variant="outlined"
            >
              {locationOpts.map(({ text, value }) => (
                <MenuItem key={`${text}${value}`} value={value}>{text}</MenuItem>
              ))}
            </Select>}
            <TextField
              disabled={!fieldsEditable}
              minRows={3}
              multiline
              name="description"
              placeholder="Please tell us about your issue. For example, 'The cabinet door to the right of my microwave is loose.'"
              onChange={handleChange}
              required
              sx={{ backgroundColor: '#fff', marginBottom: '1rem', width: '100%' }}
              value={description}
              variant="outlined"
            />
          </Box>
        </Box>
        {!hideFiles && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            { fieldsEditable &&
                <div>
                  <Button
                      component="label"
                      startIcon={<FileUpload/>}
                      sx={{
                        alignSelf: 'flex-start',
                        backgroundColor: theme.accentColorA
                      }}
                      variant="contained"
                  >
                    Upload Images
                    <input
                        accept="image/png, image/jpeg"
                        hidden
                        id={`file${key}`}
                        multiple
                        name="files"
                        onChange={handleChange}
                        type="file"
                    />
                  </Button>
                  <FormHelperText>Max 5 images. Max 5MB each.</FormHelperText>
                </div>
            }
            {
              <ImageList cols={2} gap={16} rowHeight={largeImgRow ? 200 : 125}>
                {svcReqItem.existingImgs && listFilePreviews(
                    [...svcReqItem.existingImgs],
                    handleRemoveExistingImg,
                    svcReqItem,
                    fieldsEditable
                )}
                {svcReqItem.files && listFilePreviews(
                    [...svcReqItem.files],
                    handleRemoveFileUpload,
                    svcReqItem,
                    fieldsEditable
                )}
              </ImageList>
            }
            { !useFieldpoint && fieldsEditable &&
                <Button
                    onClick={handleClose}
                    startIcon={<Delete/>}
                    sx={{
                      alignSelf: 'flex-end',
                      borderColor: theme.accentColorA,
                      color: theme.accentColorA,
                      marginTop: '1rem',
                    }}
                    variant="outlined"
                >
                  Delete Item
                </Button>
            }
          </Box>
        )}
      </Box>
    </Box>
  );
}

ServiceRequestItem.defaultProps = {
  handleClose: undefined,
  hideFiles: false,
};

ServiceRequestItem.propTypes = {
  handleChange: PropTypes.func.isRequired,
  handleClose: PropTypes.func,
  handleRemoveExistingImg: PropTypes.func.isRequired,
  handleRemoveFileUpload: PropTypes.func.isRequired,
  hideFiles: PropTypes.bool,
  index: PropTypes.number.isRequired,
  optLocations: PropTypes.arrayOf(PropTypes.object).isRequired,
  svcReqItem: PropTypes.object.isRequired,
};

export default ServiceRequestItem;
