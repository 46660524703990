import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { ThemeContext } from '../../context/ThemeContext';
import useInput from '../../hooks/input';
import Api from '../../api/Api';
import alertConfig from '../../config/alert';
import styles from './ForgotPassword.module.css';
import LoadingIndicator from '../../shared/LoadingIndicator';
import { AlertConfigCategory } from '../../types';
import GlobalConfig from "../../utils/GlobalConfig";
import Dictionary from "../../utils/Dictionary";
import {DictionaryDangerousTextAsHtml} from "../Dictionary/DictionaryDangerousTextAsHtml";
import {DictionaryText} from "../Dictionary/DictionaryText";

interface ForgotPasswordProps {
  openAlert: (alert:AlertConfigCategory, resetAlert?:boolean) => void;
}

function ForgotPassword({ openAlert }: ForgotPasswordProps) {
  const themeContext = useContext(ThemeContext);
  const [formDisabled, setFormDisabled] = useState<boolean>(false);
  const { changeHandler: handleEmail, reset: resetEmail, value: email } = useInput('');

  async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    setFormDisabled(true);
    try {
      await Api.emailPasswordResetLink(email);
      resetEmail();
      let alertInfo = alertConfig.emailPasswordReset
      var message = Dictionary.getText(Dictionary.PasswordResetNotificationOK);
      if (message) {
        alertInfo.message.body = message;
      }
      openAlert(alertInfo);
      setFormDisabled(false);
    } catch (error) {
      if (error.response) {
        const { status } = error.response;
        const { Message } = error.response.data;
        if (status === 403) {
          openAlert(alertConfig.error.emailPasswordResetForbidden);
        } else if (Message === 'Object reference not set to an instance of an object.') {
          openAlert(alertConfig.error.emailPasswordResetNotFound);
        } else {
          openAlert(alertConfig.error.default);
        }
      } else {
        openAlert(alertConfig.error.default);
      }
      setFormDisabled(false);
    }
  }

  function renderBrandingText() {
    return (
      <i>
        <DictionaryText dictionary={Dictionary} dictionaryKey={Dictionary.ForgotPW_Available} />
      </i>
    );
  }

  return (
    <div className={styles.ForgotPassword}>
      {formDisabled
        && (
        <div className={styles.loadingCover}>
          <LoadingIndicator color={themeContext.accentColorA} />
        </div>
        )}
      <div className={styles.heroImage} />
      <div
        className={styles.formBox}
        style={{ backgroundColor: themeContext.primaryMenuBg }}
      >
        <form onSubmit={handleSubmit}>
          <h1>{`${GlobalConfig.get(GlobalConfig.Key.BRANDING_NAME)} Password Reset`}</h1>
          <p>{renderBrandingText()}</p>
          <label htmlFor="email">
            EMAIL
            <input
              id="email"
              onChange={handleEmail}
              required
              type="email"
              value={email}
            />
          </label>
          <p>
            <DictionaryText dictionary={Dictionary} dictionaryKey={Dictionary.ForgotPW_ConfirmNote} />
          </p>
          <button
            type="submit"
            style={{
              backgroundColor: themeContext.accentColorA,
              opacity: formDisabled ? '0.5' : '1.0',
              pointerEvents: formDisabled ? 'none' : 'auto',
            }}
          >
            Confirm
          </button>
        </form>
      </div>
    </div>
  );
}

ForgotPassword.propTypes = {
  openAlert: PropTypes.func.isRequired,
};

export default ForgotPassword;
