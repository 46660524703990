import customPreConStepsConfig from '../config/customPreConSteps';
import GlobalConfig from './GlobalConfig';

export default class ParseData {
  static replaceBackslash = (string) => {
    const result = string.replace('/', '-');
    return result;
  }

  /**
   * stripWhitespace removes the whitespace from a given string.
   * @param {String} string - the string to be parsed.
   * @return {String} The parsed string.
  */
  static stripWhitespace = (string) => {
    const result = string.replace(/\s+/g, '');
    return result;
  }

  /**
   * filterActivities filters an array of activities based on a selectedActIds
   * configuration array.
   * @param {Array<Object>} activities - array of activity data objects.
   * @return {Array<Object>} The filtered array of activities.
   */
  static filterActivites = activities =>
    activities.reduce((accum, currVal) => {
      if (currVal.isNotification || currVal.isSchedule || currVal.isTracker) {
        accum.push(currVal);
      }
      return accum;
    }, []);

  /**
   * assignCustomFields adds the newFields properties to each activity object
   * set to a default value of false. Then the customFields array is filtered by
   * actRID and the relevant custom fields found are set to true.
   * @param {Array<Object>} initialActList - array of activity objects from API
   * @param {Array<Object>} customFields - array of custom field entries.
   * @return {Array<Object>} array of activities that includes custom fields.
   */
  static assignCustomFields = (initialActList, customFields) => {
    const fieldDict = {
      Bool_PortalNotificationAct: 'isNotification',
      Bool_PortalScheduleAct: 'isSchedule',
      Bool_PortalTrackerAct: 'isTracker',
      Text_PortalActName: 'portalActName',
      NText_PortalActDescr: 'portalActDesc',
      Text_PortalVideoAct: 'portalActVideo'
    };

    const newFields = {
      isNotification: false,
      isSchedule: false,
      isTracker: false,
      portalActName: undefined,
      portalActDesc: undefined,
      portalActVideo: undefined
    };

    return initialActList.map((activity) => {
      const newActivity = Object.assign(activity, newFields);

      const actFields = customFields.filter(field =>
        field.RefObjRID === activity.ActRID);

      actFields.forEach((field) => {
        if (fieldDict[field.FieldID]) {
          newActivity[fieldDict[field.FieldID]] = field[`${field.FieldID.split('_')[0]}Value`];
        }
      });
      return newActivity;
    });
  }



  /**
   * getStageRank takes a stage object and returns a numerical rank based on
   * the stage name.
   * @param {Object} stage - the stage data.
   * @return {Number} The stage rank (i.e. 10, 20, etc).
   */
  static getStageRank = (stage) => {
    let rank = stage.Name.split(' ')[0];
    rank = parseInt(rank, 10);

    return rank;
  }

  static parseUserData = (customerFullName) => {
    const fullName = {};
    const name = customerFullName.split(' ');
    const lastName = name[0];
    const firstName = name[1];
    fullName.firstName = firstName;
    fullName.lastName = lastName;
    return fullName;
  }

  static formatCustomPreConSteps = (customFields) => {
    const result = customPreConStepsConfig[GlobalConfig.get(GlobalConfig.Key.CLIENT_NAME)]
      .map((step) => {
        const formattedStep = { ...step };
        const customField = customFields.find(field =>
          field.FieldID === step.ID);
        if (customField) {
          if (step.ID.split('_')[0] === 'Bool') {
            formattedStep.Value = customField.BoolValue;
          } else if (step.ID.split('_')[0] === 'Text') {
            formattedStep.Value = customField.TextValue
              === step.textValueTarget;
          }
        } else {
          formattedStep.Value = false;
        }

        return formattedStep;
      });

    return result;
  }
}
