import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Api from '../../../api/Api';
import styles from './PhotoOverlay.module.css';
import RightChevron from '../../../img/right-chevron.png';
import LeftChevron from '../../../img/left-chevron.png';
import CloseIcon from '../../../img/close-window.png';
import ParseData from '../../../utils/ParseData';
import { ThemeContext } from '../../../context/ThemeContext';
import alertConfig from '../../../config/alert';
import {assertNotNull} from "../../../assertNotNull";
import {IToken} from "@bimaire/optionslib/build/api/customerportal-client";

interface Photo {
  MiddlewareDownloadURL: string;
  Name: string;
  ObjectRID: number;
  ParentRepDocName: string;
  RepDocFileHandlerLink: string;
}

interface PhotoOverlayProps {
  currentPhoto: any;
  generatePhotoData: () => void;
  handleLogout: (sessionExpired: boolean) => void;
  homeRID: number;
  isProxyUser: boolean;
  photoData: {
    Description: string;
    Photos: Photo[];
  }[];
  onClose: () => void;
  openAlert: (config: any, isAsync?: boolean) => Promise<any>;
  kovaApiToken: IToken;
}

function PhotoOverlay({
  currentPhoto,
  generatePhotoData,
  handleLogout,
  homeRID,
  isProxyUser,
  photoData,
  onClose,
  openAlert,
  kovaApiToken
}: PhotoOverlayProps) {
  const theme = useContext(ThemeContext);
  const [photoIndex, setPhotoIndex] = useState(-1);
  const [stagePhotos, setStagePhotos] = useState<Photo[]|undefined>(undefined);

  const buttonClickLeft = () => {
    if (photoIndex > 0) {
      setPhotoIndex(photoIndex - 1);
    } else {
      if (stagePhotos === undefined) throw 'stagephotos undefined';

      setPhotoIndex(stagePhotos.length - 1);
    }
  };

  const buttonClickRight = () => {
    if (stagePhotos === undefined) throw 'stagephotos undefined';

    if (photoIndex < (stagePhotos.length - 1)) {
      setPhotoIndex(photoIndex + 1);
    } else {
      setPhotoIndex(0);
    }
  };

  const handleDeleteImageClick = async () => {
    const shouldDeleteImage = await openAlert(alertConfig.deleteImage, true);

    if (!shouldDeleteImage) {
      return;
    }

    try {
      if (stagePhotos === undefined) throw 'stagephotos undefined';
      const { Name: repDocName } = stagePhotos[photoIndex];
      let repDocDirName = 'MyImages';

      if (isProxyUser) {
        repDocDirName = currentPhoto.ParentRepDocName;
        repDocDirName = ParseData.stripWhitespace(repDocDirName);
        if (repDocDirName.includes('/')) {
          repDocDirName = ParseData.replaceBackslash(repDocDirName);
        }
      }

      await Api.deleteImage(kovaApiToken, homeRID, repDocDirName, repDocName);
    } catch (err) {
      if (err.response?.data === 'jwt expired') {
        handleLogout(true);
      } else {
        openAlert(alertConfig.error.deleteImage);
      }
      return;
    }

    generatePhotoData();
    onClose();
  };


  const renderImages = () => (
    stagePhotos && <div className={styles.modulePhotoContainer}>
      <img
        src={
          stagePhotos[photoIndex].RepDocFileHandlerLink
            ? stagePhotos[photoIndex].RepDocFileHandlerLink
            : stagePhotos[photoIndex].MiddlewareDownloadURL
        }
        alt={stagePhotos[photoIndex].ParentRepDocName}
        className={styles.modulePhoto}
      />
      <button className={styles.chevronRight} type="button" onClick={buttonClickRight}>
        <img
          src={RightChevron}
          alt="right-arrow"
        />
      </button>
      <button className={styles.chevronLeft} type="button" onClick={buttonClickLeft}>
        <img
          src={LeftChevron}
          alt="right-arrow"
        />
      </button>
    </div>
  );

  const setData = () => {
    const milestone = currentPhoto.ParentRepDocName;
    const photos = assertNotNull(photoData.find((data) => (
        ParseData.replaceBackslash(ParseData.stripWhitespace(data.Description)) === milestone
    ))).Photos;
    const index = photos.findIndex((photo) => photo.ObjectRID === currentPhoto.ObjectRID);

    setPhotoIndex(index);
    setStagePhotos(photos);
  };

  useEffect(() => {
    setData();
  }, []);

  return (
    <div className={styles.photoOverlay}>
      <div
        style={{
          backgroundColor: theme.titleBarBgColor,
          color: theme.titleBarFontColor,
          fontSize: theme.titleBarFontSize,
        }}
        className={styles.titleBar}
      >
        {currentPhoto.ParentRepDocName}
        <div className={styles.closeContainer}>
          <button className={styles.closeIcon} type="button" onClick={onClose}>
            <img
              src={CloseIcon}
              alt="close"
            />
          </button>
        </div>
      </div>
      <div className={styles.moduleContainer}>
        {photoIndex >= 0 && stagePhotos && renderImages()}
      </div>
      {(currentPhoto.ParentRepDocName === 'MyImages' || (isProxyUser && currentPhoto.ParentRepDocName !== 'MyImages')) && (
        <button
          className={styles.deleteButton}
          onClick={handleDeleteImageClick}
          style={{ backgroundColor: theme.titleBarBgColor }}
          type="button"
        >
          <span>Delete Image</span>
          <FontAwesomeIcon icon="trash-alt" />
        </button>
      )}
    </div>
  );
}

PhotoOverlay.propTypes = {
  currentPhoto: PropTypes.object.isRequired,
  generatePhotoData: PropTypes.func.isRequired,
  handleLogout: PropTypes.func.isRequired,
  homeRID: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired,
  openAlert: PropTypes.func.isRequired,
  photoData: PropTypes.array.isRequired,
};

export default PhotoOverlay;
