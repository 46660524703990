import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import Schedule from './shared/Schedule/Schedule';
import {DashboardSelector} from './components/Dashboard/Dashboard.tsx';
import Selections from './components/Selections/Selections';
import Photos from './shared/Photos/Photos';
import Documents from './components/Documents/Documents.tsx';
import Warranty from './components/Warranty/Warranty';
import UsefulLinks from './components/UsefulLinks/UsefulLinks.tsx';
import Faqs from './components/Faqs/Faqs.tsx';
import ContactUs from './components/ContactUs/ContactUs.tsx';
import MyProfile from './components/MyProfile/MyProfile';
import ForgotPassword from './components/ForgotPassword';
import Login from './components/Login/Login';
import ResetPassword from './components/ResetPassword';
import ForgotPasswordReset from './components/ForgotPasswordReset';
import SelectHome from './components/SelectHome/SelectHome';
import { generateEnabledRoutes, capitalize } from './components/App/utils';
import ProxyLogin from './components/Login/ProxyLogin/ProxyLogin.tsx';
import ProxyLoginWrapper from "./components/Login/ProxyLogin/ProxyLogin.tsx";



const allRoutes = [
  {
    path: '/select-home',
    component: SelectHome,
    linkText: 'select home',
    routeType: 'primaryMenu',
  },
  {
    path: '/dashboard',
    component: DashboardSelector,
    linkText: 'dashboard',
    routeType: 'primaryMenu',
    subRoutes: [],
  },
  {
    path: '/dashboard/calendar',
    component: Schedule,
    linkText: 'calendar',
    routeType: 'primaryMenu',
  },
  {
    path: '/selections',
    component: Selections,
    linkText: 'selections',
    routeType: 'primaryMenu',
  },
  {
    path: '/photos',
    component: Photos,
    linkText: 'scrapbook',
    routeType: 'primaryMenu',
  },
  {
    path: '/documents',
    component: Documents,
    linkText: 'documents',
    routeType: 'primaryMenu',
  },
  {
    path: '/warranty',
    component: Warranty,
    linkText: 'warranty',
    routeType: 'primaryMenu',
  },
  {
    path: '/useful-links',
    component: UsefulLinks,
    linkText: 'useful links',
    routeType: 'primaryMenu',
  },
  {
    path: '/faqs',
    component: Faqs,
    linkText: 'faqs',
    routeType: 'primaryMenu',
  },
  {
    path: '/contact-us',
    component: ContactUs,
    linkText: 'contact us',
    routeType: 'primaryMenu',
  },
  {
    path: '/my-profile',
    component: MyProfile,
    linkText: 'my profile',
    routeType: 'settingsMenu',
  },
  {
    path: '/reset-password',
    component: ResetPassword,
    linkText: 'reset password',
    routeType: 'settingsMenu',
  },
  {
    path: '/proxylogin',
    component: ProxyLogin,
    linkText: 'proxylogin',
    routeType: '',
  },
  {
    path: '/',
    component: Login,
    linkText: 'log out',
    routeType: 'settingsMenu',
    redirect: {
      conditional: 'isAuthenticated',
      to: '/dashboard',
      from: '/',
    },
  },
];

function Routes(props) {
  const {
    adminApiToken,
    isProxyUser,
      kovaApiToken,
    isAuthenticated,
    links,
    isMultipleHomeGate,
    prelimCustomers,
    homeInfo
  } = props;

  const enabledRoutes = isMultipleHomeGate || adminApiToken || (!adminApiToken && !isAuthenticated)
    ? generateEnabledRoutes(links, '', prelimCustomers, kovaApiToken, homeInfo)
    : generateEnabledRoutes(links, 'selectOnly', prelimCustomers, kovaApiToken, homeInfo);

  const listSubRoutes = (subRoutes) => subRoutes.map((route) => {
    const Component = route.component;
    return (
      <Route
        exact
        path={route.path}
        key={route.linkText}
        render={() => (
              <Component
                {...props}
              />
        )}
      />
    );
  });

  const renderRoute = (route) => {
    const Component = route.component;
    // these to functions are checking if select-homes is the active route.
    // if it IS active it will become the redirect, otherwise dashboard will be the default route
    let includesSelect = enabledRoutes.filter((checkRoute) => (checkRoute.path === '/select-home'));
    let includesDefault = enabledRoutes.filter((checkRoute) => (checkRoute.isDefaultRoute));
    if (route.redirect) {
      if (includesDefault.length && route.linkText === 'log out' && route.redirect.to === '/dashboard') {
        route.redirect.to = enabledRoutes.find(route => route.isDefaultRoute).path;
        if (includesSelect.length && !includesDefault) {
          route.redirect.to = '/select-home';
        }
      }

      return (
        <Route
          exact
          key={route.linkText}
          path={route.path}
          render={() => (
            <Component
              {...props}
            />
          )}
        >
          {props[route.redirect.conditional]
            ? <Redirect from={route.path} exact to={route.redirect.to} />
              : <Component {...props} />}
        </Route>
      );
    }

    return (
      <Route
        exact
        key={route.linkText}
        path={route.path}
        render={() => {
          if(route?.link?.text){
            document.title = capitalize(route.link.text);
          }
          else if(route && route.linkText){
            document.title = capitalize(route.linkText);
          }
          return <Component
              {...props}
              link={route.link ? route.link : null}
            />
          }
        }
      />
    );
  };

  const listRoutes = enabledRoutes.map((route) => {
    const Component = route.component;
    const hasSubRoutes = route.subRoutes;

    return hasSubRoutes ? (
      <React.Fragment key={route.linkText}>
        <Route
          exact
          path={route.path}
          render={() => {
            if(route?.link?.text){
              document.title = capitalize(route.link.text);
            }
            else if(route && route.linkText){
              document.title = capitalize(route.linkText);
            }
            return <Component
              {...props}
            />
          }}
        />
        {listSubRoutes(route.subRoutes)}
      </React.Fragment>
    ) : (
      renderRoute(route)
    );
  });

  if (isAuthenticated) {
    return listRoutes;
  }
  return (
    <>
      <Route
        exact
        path={['/', '/login']}
        render={() => <Login {...props} />}
      />
      <Route
          exact
          path='/proxylogin'
          render={() => <ProxyLoginWrapper {...props} />}
      />
      <Route
        exact
        path="/forgot-password"
        render={() => <ForgotPassword {...props} />}
      />
      <Route
        exact
        path="/forgot-password-reset"
        render={() => <ForgotPasswordReset {...props} />}
      />
    </>
  );
}

Routes.defaultProps = {
  adminApiToken: null,
};

Routes.propTypes = {
  adminApiToken: PropTypes.string,
  isAuthenticated: PropTypes.bool.isRequired,
  links: PropTypes.object.isRequired,
};

export {
  allRoutes,
  Routes,
};
