import React, { useContext } from 'react';
import Document from './Document/Document';
import { ThemeContext } from '../../context/ThemeContext';
import styles from './DocContainer.module.css';
import LoadingIndicator from '../LoadingIndicator';
import {Grid} from "@mui/material";

interface DocContainerProps {
  docs: any[];
  heading: string;
  lg:number;
}

function DocContainer({ docs, heading, lg }: DocContainerProps) {
  const theme = useContext(ThemeContext);

  return (
    <div className={styles.DocContainer}>
      <div
        style={
          {
            backgroundColor: theme.titleBarBgColor,
            color: theme.titleBarFontColor,
            fontSize: theme.titleBarFontSize,
          }
        }
        className={styles.heading}
      >
        {heading}
      </div>
      {!docs && (
        <div className={styles.statusText}>
          <span className={styles.loadingWrapper}>
            <LoadingIndicator color={theme.accentColorA} />
          </span>
        </div>
      )}
      {docs && docs.length > 0 ? (
        <div style={{'overflow':'auto'}}>
        <Grid container >
          {docs.map((doc) => (
            <Grid item xs={12} md={6} lg={lg} p={1} key={doc.Name}>
              <Document
                link={
                  doc.RepDocFileHandlerLink
                    ? doc.RepDocFileHandlerLink
                    : doc.MiddlewareDownloadURL
                }
                name={doc.Name}
              />
            </Grid>
          ))}
        </Grid>
        </div>
      ) : (docs && (
        <div className={styles.statusText}>
          No Documents Currently Available
        </div>
      ))}
    </div>
  );
}

export default DocContainer;
