import React, { useContext, useEffect, useState } from 'react';
import { ThemeContext } from '../../context/ThemeContext';
import useInput from '../../hooks/input';
import usePrevious from '../../hooks/previous';
import { handleSubmit } from './utils';
import styles from './ResetPassword.module.css';
import GlobalConfig from '../../utils/GlobalConfig';
import Dictionary from "../../utils/Dictionary";

function ResetPassword({ handleLogout, kovaToken, openAlert }) {
  const themeContext = useContext(ThemeContext);
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const previousSubmitDisabled = usePrevious(submitDisabled);
  const [validationErrors, setValidationErrors] = useState({});
  const { changeHandler: handleEmail, reset: resetEmail, value: email } = useInput('');
  const { changeHandler: handlePasswordOld, reset: resetPasswordOld, value: passwordOld } = useInput('');
  const { changeHandler: handlePasswordNew, reset: resetPasswordNew, value: passwordNew } = useInput('');
  const { changeHandler: handlePasswordConfirm, reset: resetPasswordConfirm, value: passwordConfirm } = useInput('');
  const formValues = {
    email,
    passwordOld,
    passwordNew,
    passwordConfirm,
  };

  useEffect(() => {
    if (
      previousSubmitDisabled
      && !submitDisabled
      && Object.keys(validationErrors).length === 0
    ) {
      resetEmail();
      resetPasswordOld();
      resetPasswordNew();
      resetPasswordConfirm();
    }
  }, [submitDisabled]);

  return (
    <div className={styles.ResetPassword}>
      <div className={styles.heroImage} />
      <div
        className={styles.formBox}
        style={{ backgroundColor: themeContext.primaryMenuBg }}
      >
        <form
          className={styles.formWrapper}
          onSubmit={(e) => handleSubmit(
            e,
            formValues,
            handleLogout,
            kovaToken,
            openAlert,
            setValidationErrors,
            setSubmitDisabled,
          )}
        >
          <h1>{`${GlobalConfig.get(GlobalConfig.Key.BRANDING_NAME)} Password Reset`}</h1>
          <label htmlFor="email">
            <div>
              <span>EMAIL</span>
              {validationErrors.LOGINFAILED && (
                <span className={styles.validationError}>
                  {validationErrors.LOGINFAILED}
                </span>
              )}
            </div>
            <input
              id="email"
              onChange={handleEmail}
              required
              style={{ border: validationErrors.LOGINFAILED ? '1px solid red' : 'none' }}
              type="email"
              value={email}
            />
          </label>
          <label htmlFor="passwordOld">
            <div>
              <span>OLD PASSWORD</span>
              {validationErrors.LOGINFAILED && (
                <span className={styles.validationError}>
                  {validationErrors.LOGINFAILED}
                </span>
              )}
            </div>
            <input
              id="passwordOld"
              onChange={handlePasswordOld}
              required
              style={{ border: validationErrors.LOGINFAILED ? '1px solid red' : 'none' }}
              type="password"
              value={passwordOld}
            />
          </label>
          <label htmlFor="passwordNew">
            <div>
              <span>NEW PASSWORD</span>
              {validationErrors.PASSWORDMISMATCH && (
                <span className={styles.validationError}>
                  {validationErrors.PASSWORDMISMATCH}
                </span>
              )}
            </div>
            <input
              id="passwordNew"
              minLength={8}
              pattern={GlobalConfig.get(GlobalConfig.Key.PW_COMPLEXITY)}
              onChange={handlePasswordNew}
              placeholder={Dictionary.getText(Dictionary.PasswordReset)}
              title={Dictionary.getText(Dictionary.PasswordReset)}
              style={{ border: validationErrors.PASSWORDMISMATCH ? '1px solid red' : 'none' }}
              required
              type="password"
              value={passwordNew}
            />
          </label>
          <label htmlFor="passwordConfirm">
            <div>
              <span>CONFIRM NEW PASSWORD</span>
              {validationErrors.PASSWORDMISMATCH && (
                <span className={styles.validationError}>
                  {validationErrors.PASSWORDMISMATCH}
                </span>
              )}
            </div>
            <input
              id="passwordConfirm"
              minLength={8}
              pattern={GlobalConfig.get(GlobalConfig.Key.PW_COMPLEXITY)}
              onChange={handlePasswordConfirm}
              placeholder={Dictionary.getText(Dictionary.PasswordReset)}
              title={Dictionary.getText(Dictionary.PasswordReset)}
              required
              style={{ border: validationErrors.PASSWORDMISMATCH ? '1px solid red' : 'none' }}
              type="password"
              value={passwordConfirm}
            />
          </label>
          <p>
            By clicking the Confirm button below, you confirm that you would
            like to set a new password for this account. The old password will
            immediately stop working and any devices or computers using this
            password will no longer be able to access this account. Upon
            clicking confirm, your account will be logged out from the current
            session.
          </p>
          <button
            type="submit"
            style={{
              backgroundColor: themeContext.accentColorA,
              opacity: submitDisabled ? '0.5' : '1.0',
            }}
          >
            Confirm
          </button>
        </form>
      </div>
    </div>
  );
}

export default ResetPassword;
