import React from 'react';
import YouTube, { Options } from 'react-youtube';
import styles from './TrackerVideo.module.css';
import { ActivityStep, ClientTheme } from '../../types';
import { ThemeConsumer } from '../../context/ThemeContext';

interface TrackerVideoProps {
  selectedMilestone: ActivityStep
  setModalVideo: any
}

function TrackerVideo({ selectedMilestone, setModalVideo }: TrackerVideoProps) {
  const onPlayHandler = (event: any) => {
    event.target.pauseVideo();
    setModalVideo(selectedMilestone.portalActVideo);
  };

  const renderVideo = () => {
    const opts: Options = {
      playerVars: {
        autoplay: 0,
        rel: 0,
        showinfo: 0,
      },
    };

    if (selectedMilestone && selectedMilestone.portalActVideo) {
      const videoURLArray = selectedMilestone.portalActVideo.split('/');
      const videoURL = videoURLArray[videoURLArray.length - 1];
      return (
        <YouTube
          videoId={videoURL}
          opts={opts}
          onPlay={(event: any) => onPlayHandler(event)}
        />
      );
    }
    return <span>No Video Available</span>;
  };

  return (
    <ThemeConsumer>
      {(theme: ClientTheme) => (
        <div className={styles.TrackerVideo}>
          <div
            style={
              {
                backgroundColor: theme.titleBarBgColor,
                color: theme.titleBarFontColor,
                fontSize: theme.titleBarFontSize,
              }
            }
            className={styles.titleBar}
          >
            Home Progress Tracker Video
          </div>
          <div className={styles.panelContent}>
            {renderVideo()}
          </div>
        </div>
      )}
    </ThemeConsumer>
  );
}

export default TrackerVideo;
