import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import styles from './ProgressTracker.module.css';
import ProgressBar from './ProgressBar/ProgressBar';
import Api from '../../api/Api';
import ParseData from '../../utils/ParseData';
import { ThemeConsumer } from '../../context/ThemeContext';
import preconPlaceholder from '../../img/precon-placeholder.jpg';
import GlobalConfig from '../../utils/GlobalConfig';

moment().format();

class ProgressTracker extends Component {
  state = {
    activitySteps: null,
    currentImg: null,
    currentStep: null,
    firstStep: null,
    lastStep: null,
    totalSteps: null,
    preConSteps: null,
    selectedStage: null
  };

  componentDidMount = async () => {
    this.assignCurrentStep();
  }

  componentDidUpdate = (prevProps, prevState) => {
    const {windowWidth} = this.props;
    const {
      currentStep,
    } = this.state;

    const currentStepAssigned = currentStep
        && prevState.currentStep !== currentStep;

    if (currentStep && prevState.currentStep !== currentStep) {
      this.assignTotalSteps();
    }

    if ((prevProps.windowWidth !== windowWidth)
        || currentStepAssigned) {
      this.setNumberOfSteps();
    }
  }

  assignTotalSteps = () => {
    let totalSteps = this.getTotalSteps();

    this.setState({ totalSteps });
  }

  getTotalSteps() {
    const {activitySteps, preConSteps} = this.state;

    // Check if preconstruction stage
    if (preConSteps) {
      return preConSteps.length;
    } else if (activitySteps) {
      return activitySteps.length;
    } else {
      return 0;
    }
  }

  assignCurrentStep = async () => {
    const {activities, setSelectedMilestone} = this.props;

    if (GlobalConfig.get(GlobalConfig.Key.TRACKER_DATA) === 'activities') {
      let activitySteps = [...activities];

      if (GlobalConfig.get(GlobalConfig.Key.ACTIVITY_MODULE_FILTER)) {
        activitySteps = activitySteps.filter(activity => activity.isTracker);
      }

      activitySteps = activitySteps.sort((a, b) =>
          new Date(a.StartDateCur) - new Date(b.StartDateCur));
      activitySteps = activitySteps.map((step, i) => ({...step, Number: i + 1}));
      const currentActivityStep = this.getCurrentActivityStep(activitySteps, 'activities');

      this.setState({
        currentStep: currentActivityStep,
        activitySteps
      });
      setSelectedMilestone(activitySteps[currentActivityStep - 1]);
    }
  }

  getCurrentActivityStep = (activitySteps, env) => {
    let currentlyActive = [];
    if (env === 'activities') {
      activitySteps.forEach((step) => {
        const isNowInProgress = moment(step.StartDateCur).isBefore()
        && step.Status.toUpperCase() !== 'APPROVED';
        if (isNowInProgress) {
          currentlyActive.push(step);
        }
      });

      if (currentlyActive.length === 0) {

        //If none was found, then take the last completed
        let lastCompletedStep = [...activitySteps].reverse().find(step => step.Status.toUpperCase() === "APPROVED");
        if(lastCompletedStep)
        {
            return lastCompletedStep.Number;
        }
        //fallback to last step
        return activitySteps[activitySteps.length - 1].Number;
      }
      return currentlyActive[0].Number;
    }

    let currentStep;
    activitySteps.forEach((step) => {
      const firstStepNotCompleted = !step.Value && !currentStep;
      const isInProgress = moment(step.FinishDateCur).isAfter()
        && moment(step.StartDateCur).isBefore();
      const lastStepCompleted = moment(step.FinishDateCur).isBefore()
        && step.Number === activitySteps.length;

      if (firstStepNotCompleted || isInProgress || lastStepCompleted) {
        currentStep = step.Number;
      }
    });

    return currentStep;
  }


  /**
   * setNumberOfSteps updates the number of steps being displayed in the
   * ProgressBar based on the windowWidth which is especially important at
   * mobile widths.
   *
   * Magic numbers:
   * > The 123 is based on the ideal pixel width of each node including margin.
   */

  setNumberOfSteps = () => {
    const totalSteps = this.getTotalSteps();
    const { windowWidth } = this.props;
    const { currentStep } = this.state;

    const numOfSteps = Math.min(Math.floor(windowWidth / 123), 8); //max eight steps
    const startStep = currentStep - Math.ceil(numOfSteps / 2);
    const endStep = currentStep + Math.floor(numOfSteps / 2);

    let firstStep = startStep;
    let lastStep = endStep;

    if(lastStep > totalSteps){
      firstStep = Math.max(1, firstStep - (lastStep - (totalSteps + 1)));
      lastStep = totalSteps + 1;
    }

    if(firstStep < 1){
      firstStep = 1;
      lastStep = Math.min(numOfSteps + 1, totalSteps + 1);
    }

    this.setState({
      firstStep,
      lastStep
    });
  }

  handleProgressBarScroll = (direction) => {
    const {
      firstStep,
      lastStep,
      totalSteps,
    } = this.state;

    if (direction === 'right' && (lastStep <= totalSteps)) {
      return this.setState(prevState => ({
        firstStep: prevState.firstStep + 1,
        lastStep: prevState.lastStep + 1
      }));
    }

    if (direction === 'left' && (firstStep > 1)) {
      return this.setState(prevState => ({
        firstStep: prevState.firstStep - 1,
        lastStep: prevState.lastStep - 1
      }));
    }

    return false;
  }

  imageCheck = () => {
    const { showImg } = this.props;
    const { currentImg, preConSteps, stageImagesForNode } = this.state;
    // added in check for stageImagesForNode so that 'no Image found'
    // banner did not show on page load
    if (showImg && !currentImg && stageImagesForNode && !preConSteps) {
      return <div className={styles.noImage}> No image found </div>;
    }
    return null;
  }

  setStepsData = () => {
    const { activitySteps, preConSteps } = this.state;
    let stepsData;

    if (GlobalConfig.get(GlobalConfig.Key.TRACKER_DATA) === 'activities') {
      stepsData = activitySteps;
    } else if (preConSteps) {
      stepsData = preConSteps;
    }

    return stepsData;
  }

  render() {
    const {
      selectedMilestone,
      setSelectedMilestone,
      showImg,
    } = this.props;

    const {
      currentImg,
      currentStep,
      firstStep,
      lastStep,
      totalSteps,
      preConSteps
    } = this.state;

    const fullScreenClass = (showImg) ? styles.fullscreen : '';

    return currentStep
      && firstStep
      && lastStep
      && totalSteps
      ? (
        <ThemeConsumer>
          {theme => (
            <div className={`${styles.progressTracker} ${fullScreenClass}`}>
              <div
                style={
                  {
                    backgroundColor: theme.titleBarBgColor,
                    color: theme.titleBarFontColor,
                    fontSize: theme.titleBarFontSize
                  }}
                className={styles.titleBar}
              >
                home progress tracker
              </div>
              {(currentImg || preConSteps) && (
                <div
                  className={styles.image}
                  style={{
                    backgroundImage: `url(${preConSteps ? preconPlaceholder : currentImg})`,
                    backgroundSize: 'cover',
                    backgrounRepeat: 'no-repeat',
                    backgroundPosition: 'center'
                  }}
                />
              )}
              {this.imageCheck()}
              <div className={styles.panelContent}>
                <ProgressBar
                  currentStep={currentStep}
                  firstStep={firstStep}
                  lastStep={lastStep}
                  stepsData={this.setStepsData()}
                  totalSteps={totalSteps}
                  handleProgressBarScroll={this.handleProgressBarScroll}
                  milestoneClickHandler={setSelectedMilestone}
                  selectedMilestone={selectedMilestone}
                  preConSteps={preConSteps}
                />
              </div>
            </div>
          )}
        </ThemeConsumer>
      )
      : (null);
  }
}

ProgressTracker.defaultProps = {
  homeRID: null,
  pushOut: null,
  showImg: false,
  slsOrdRID: null
};

ProgressTracker.propTypes = {
  handleLogout: PropTypes.func.isRequired,
  homeRID: PropTypes.number,
  pushOut: PropTypes.bool,
  showImg: PropTypes.bool,
  slsOrdRID: PropTypes.number,
  windowWidth: PropTypes.number.isRequired
};

export default ProgressTracker;
