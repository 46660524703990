import React from 'react';

const alertConfig = {
  browser: {
    heading: 'Notice',
    message: {
      body: 'Whoops! It looks like you\'re using an unsupported version of IE. This application does not support IE 11 or below. Please use a modern browser to continue.',
    },
    type: 'browser',
  },
  changePassword: {
    button: {
      text: 'OK',
    },
    heading: 'Notice',
    message: {
      body: 'Password successfully changed. You are now logged out.',
    },
    type: 'default',
  },
  deleteImage: {
    heading: 'Notice',
    message: {
      body: 'Are you sure you want to delete this image?',
    },
    type: 'confirm',
  },
  emailPasswordReset: {
    button: {
      text: 'OK',
    },
    heading: 'Notice',
    message: {
      body: 'Email reset password link sent.',
    },
    type: 'default',
  },
  disclaimer: {
    button: {
      text: 'I Understand',
    },
    heading: 'Notice',
    message: {
      body: 'The Construction Calendar and Schedule are tentative dates and can change daily due to weather, vendor availability, workload and material shortages.',
      heading: 'Please Note',
    },
    type: 'default',
  },
  login: {
    button: {
      text: 'OK',
    },
    heading: 'Error',
    message: {
      body: 'Email / Password not recognized.',
    },
    type: 'default',
    customerOverrides: {
      mungo: {
        body: 'Email / Password not recognized. Please use email address on file with Mungo Homes. To verify contact information or for assistance please call (803) 749-5888 Ext. 354',
      },
    },
  },
  error: {
    colorSchemes: {
      button: {
        text: 'OK',
      },
      heading: 'Error',
      message: {
        body: 'Could not fetch color schemes. Using default colors.',
      },
      type: 'default',
    },
    customPages: {
      add: {
        button: {
          text: 'OK',
        },
        heading: 'Error',
        message: {
          body: 'Something went wrong - could not add custom page.',
        },
        type: 'default',
      },
      delete: {
        button: {
          text: 'OK',
        },
        heading: 'Error',
        message: {
          body: 'Something went wrong - could not delete custom page.',
        },
        type: 'default',
      },
      update: {
        button: {
          text: 'OK',
        },
        heading: 'Error',
        message: {
          body: 'Something went wrong - could not update custom page.',
        },
        type: 'default',
      },
    },
    deleteImage: {
      button: {
        text: 'OK',
      },
      heading: 'Error',
      message: {
        body: 'Something went wrong - coud not delete image.',
      },
      type: 'default',
    },
    emailPasswordResetForbidden: {
      button: {
        text: 'OK',
      },
      heading: 'Error',
      message: {
        body: 'Could not email reset password link. Last reset time was less than 1 hour ago.',
      },
      type: 'default',
    },
    emailPasswordResetNotFound: {
      button: {
        text: 'OK',
      },
      heading: 'Error',
      message: {
        body: 'Email not recognized.',
      },
      type: 'default',
      customerOverrides: {
        mungo: {
          body: 'Email not recognized. Please use email address provided to Mungo Homes with contract. To verify contact information or for assistance please call (803) 749-5888 Ext. 354',
        },
      },
    },
    default: {
      button: {
        text: 'OK',
      },
      heading: 'Error',
      message: {
        body: 'Something went wrong.',
      },
      type: 'default',
    },
    deleteSvcReqItm: {
      button: {
        text: 'OK',
      },
      heading: 'Error',
      message: {
        body: 'Something went wrong - could not delete service request item.',
      },
      type: 'default',
    },
    directories: {
      button: {
        text: 'OK',
      },
      heading: 'Error',
      message: {
        body: 'Could not create one or more directories. May not be able to access all documents.',
      },
      type: 'default',
    },
    documentsClosing: {
      button: {
        text: 'OK',
      },
      heading: 'Error',
      message: {
        body: 'Something went wrong - could not get closing documents.',
      },
      type: 'default',
    },
    documentsCommunity: {
      button: {
        text: 'OK',
      },
      heading: 'Error',
      message: {
        body: 'Something went wrong - could not get community documents.',
      },
      type: 'default',
    },
    documentsHelpful: {
      button: {
        text: 'OK',
      },
      heading: 'Error',
      message: {
        body: 'Something went wrong - could not get helpful documents.',
      },
      type: 'default',
    },
    documentsHome: {
      button: {
        text: 'OK',
      },
      heading: 'Error',
      message: {
        body: 'Something went wrong - could not get home documents.',
      },
      type: 'default',
    },
    documentsWarranty: {
      button: {
        text: 'OK',
      },
      heading: 'Error',
      message: {
        body: 'Something went wrong - could not get warranty documents.',
      },
      type: 'default',
    },
    files: {
      button: {
        text: 'OK',
      },
      heading: 'Error',
      message: {
        body: 'Could not fetch image files. Using default logos.',
      },
      type: 'default',
    },
    generatePhotoData: {
      button: {
        text: 'OK',
      },
      heading: 'Error',
      message: {
        body: 'Something went wrong - could not fetch photos.',
      },
      type: 'default',
    },
    getSvcReqs: {
      button: {
        text: 'OK',
      },
      heading: 'Error',
      message: {
        body: 'Something went wrong - could not fetch service requests.',
      },
      type: 'default',
    },
    getFieldpointCompany: {
      button: {
        text: 'OK',
      },
      heading: 'Error',
      message: {
        body: 'Something went wrong - could not fetch Fieldpoint company.',
      },
      type: 'default',
    },
    getSvcReqItms: {
      button: {
        text: 'OK',
      },
      heading: 'Error',
      message: {
        body: 'Something went wrong - could not fetch service request items.',
      },
      type: 'default',
    },
    getSvcReqItmImgs: {
      button: {
        text: 'OK',
      },
      heading: 'Error',
      message: {
        body: 'Something went wrong - could not fetch service request item images.',
      },
      type: 'default',
    },
    links: {
      button: {
        text: 'OK',
      },
      heading: 'Error',
      message: {
        body: 'Unable to fetch links. Some pages may not be available.',
      },
      type: 'default',
    },
    noHomeFound: {
      button: {
        text: 'OK',
      },
      heading: 'Error',
      message: {
        body: 'No home associated with user - user must be assigned a home to use portal.',
      },
      type: 'default',
    },
    settings: {
      button: {
        text: 'OK',
      },
      heading: 'Error',
      message: {
        body: 'Could not fetch settings.',
      },
      type: 'default',
    },
    unauthorized: {
      button: {
        text: 'OK',
      },
      heading: 'Error',
      message: {
        body: 'Something went wrong - unauthorized response detected.',
      },
      type: 'default',
    },
    loginMessages: {
      button: {
        text: 'OK',
      },
      heading: 'Error',
      message: {
        body: 'Something went wrong - could not get messages.',
      },
      type: 'default',
    },
    loginAuth0Timeout: {
      button: {
        text: 'OK',
      },
      heading: 'Error',
      message: {
        body: 'Oops... Login timed out. Please refresh the page and try again!',
      },
      type: 'default'
    },
    loginAuth0: {
      button: {
        text: 'OK',
      },
      heading: 'Error',
      message: {
        body: 'Login Failed. Please try again.',
      },
      type: 'default'
    },
    loginAuth0CustomerNotFound: {
      button: {
        text: 'OK',
      },
      heading: 'Login Successful - Customer not found',
      message: {
        body: 'Could not find a customer for this email. Please contact Customer Care.',
      },
      type: 'default',
      customerOverrides: {
        mungo: {
          body: 'Could not find a customer for this email.\nPlease contact Customer Care (803) 749-5888 Ext. 354 for assistance.',
        },
      },
    },
    saveSvcReq: {
      button: {
        text: 'OK',
      },
      heading: 'Error',
      message: {
        body: 'Something went wrong - could not save service request.',
      },
      type: 'default',
    },
    saveSvcReqItms: {
      button: {
        text: 'OK',
      },
      heading: 'Error',
      message: {
        body: 'Your Service Request has been created, but an error was encountered. Please review what was able to be gathered, and add any missing items.',
      },
      type: 'default',
    },
    updateSvcReq: {
      default: {
        button: {
          text: 'OK',
        },
        heading: 'Error',
        message: {
          body: 'An error occurred while updating Service Request. Please review what was able to be gathered, and add any missing items.',
        },
        type: 'default',
      },
      BeingProcessed: {
        button: {
          text: 'OK',
        },
        heading: 'Error',
        message: {
          body: 'Current service request is already being processed. Please create a new request if needed.',
        },
        type: 'default',
      },
      409: {
        button: {
          text: 'OK',
        },
        heading: 'Error',
        message: {
          body: 'Your changes could not be saved. Please contact customer care.',
        },
        type: 'default',
        customerOverrides: {
          mungo: {
            body: 'Your changes could not be saved. Please contact customer care: 803-832-4933',
          },
        },
      },
    },
    uploadImages: {
      button: {
        text: 'OK',
      },
      heading: 'Error',
      message: {
        body: 'Something went wrong - could not upload one or more images.',
      },
      type: 'default',
    },
    uploadImagesTooManyFiles: {
      button: {
        text: 'OK',
      },
      heading: 'Error',
      message: {
        body: 'Cannot upload more than five images per item',
      },
      type: 'default',
    },
    uploadImagesTooLargeFiles: {
      button: {
        text: 'OK',
      },
      heading: 'Error',
      message: {
        body: 'Maximum image size is 5MB',
      },
      type: 'default',
    },
  },
  profileUpdate: {
    button: {
      text: 'OK',
    },
    heading: 'Notice',
    message: {
      body: 'Information saved.',
    },
    type: 'default',
  },
  proxyUserLogin: {
    button: {
      text: 'OK',
    },
    heading: 'Error',
    message: {
      body: 'Proxy user login failed.',
    },
    type: 'default',
  },
  sessionExpired: {
    button: {
      text: 'OK',
    },
    heading: 'Notice',
    message: {
      body: 'Your session has expired and you have been logged out to protect your privacy. Please log back in to continue.',
    },
    type: 'default',
  },
  submitSvcReqDefault: {
    button: {
      text: 'OK',
    },
    heading: 'Notice',
    message: {
      body: 'Thank you for submitting your request.',
    },
    textAlign: 'left',
    type: 'default',
  },
  submitSvcReqMungo: {
    button: {
      text: 'OK',
    },
    heading: 'Notice',
    message: {
      body: [
        'Thank you for submitting your request with Mungo Homes Customer Care.',
        <br />,
        <br />,
        'If your warrantable concerns pertain to plumbing, electrical, appliances and/or heating and air, you may choose to contact these trades directly using the contact information located on the SOS sticker on your breaker panel.',
        <br />,
        <br />,
        'With Sincere Appreciation,',
        <br />,
        'Your Mungo Homes Customer Care Team',
      ],
    },
    textAlign: 'left',
    type: 'default',
  },
};

export default alertConfig;
