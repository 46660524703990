import React from 'react';
import arborLogo from '../img/homeBuyers_portal_logo.png';
import goodallLogo from '../img/goodall-logo.png';
import mungoLogo from '../img/MungoHomes_BHC_Logo_Colored.svg';
import { ThemeObj, ClientTheme } from '../types';
import GlobalConfig from "../utils/GlobalConfig";

const themes: ThemeObj = {
  arbor: {
    logo: arborLogo,
    primaryMenuBg: '#32485d',
    primaryMenuHighlightBg: '#466d7f',
    primaryMenuFontColor: '#57767F',
    settingsMenuBg: '#757575',
    settingsMenuHighlightBg: '#A5A5A5',
    titleBarBgColor: '#32485d',
    titleBarFontColor: '#fff',
    titleBarFontSize: '1.125rem',
    accentColor: '#599F41',
    node: {
      inProgress: '#f9c486',
      completed: '#32485d',
      notCompleted: '#979797',
      selected: '#0084ff',
    },
    accentColorA: '#4bbc89',
    accentColorB: '#f9f29c',
    accentColorC: '#f9c486',
    calendarLegendText: '#fff',
    calendarDayText: 'black',
    calendarWeekendText: 'red',
    calendarCurrentWeek: '#f9f29c',
    calendarBeforeCurrentWeek: '#32485d',
    calendarNowDate: '#f9c486',
    calendarWeekDay: '#466d7f',
    calendarSelectedDay: '#EFEFEF',
  },
  goodall: {
    logo: goodallLogo,
    primaryMenuBg: '#32485d',
    primaryMenuHighlightBg: '#466d7f',
    primaryMenuFontColor: '#57767F',
    settingsMenuBg: '#757575',
    settingsMenuHighlightBg: '#A5A5A5',
    titleBarBgColor: '#32485d',
    titleBarFontColor: 'green',
    titleBarFontSize: '1.125rem',
    accentColor: '#599F41',
    node: {
      inProgress: '#466d7f',
      completed: '#4bbc89',
      notCompleted: '#616161',
      selected: '#0084ff',
    },
    accentColorA: '#4bbc89',
    accentColorB: '#f9f29c',
    accentColorC: '#f9c486',
    calendarLegendText: '#32485d',
    calendarDayText: 'black',
    calendarWeekendText: 'red',
    calendarCurrentWeek: '#f9f29c',
    calendarBeforeCurrentWeek: '#32485d',
    calendarNowDate: '#f9c486',
    calendarWeekDay: '#466d7f',
    calendarSelectedDay: '#EFEFEF',
  },
  mungo: {
    logo: mungoLogo,
    primaryMenuBg: '#435A70',
    primaryMenuHighlightBg: '#B3CED0',
    primaryMenuFontColor: '#57767F',
    settingsMenuBg: '#757575',
    settingsMenuHighlightBg: '#A5A5A5',
    titleBarBgColor: '#435A70',
    titleBarFontColor: '#fff',
    titleBarFontSize: '1.125rem',
    accentColor: '#599F41',
    node: {
      inProgress: '#1265B4',
      completed: '#599F41',
      notCompleted: '#616161',
      selected: '#0084ff',
    },
    accentColorA: '#ba5549',
    accentColorB: '#f9f29c',
    accentColorC: '#f9c486',
    calendarLegendText: '#fff',
    calendarDayText: 'black',
    calendarWeekendText: 'red',
    calendarCurrentWeek: '#f9f29c',
    calendarBeforeCurrentWeek: '#32485d',
    calendarNowDate: '#f9c486',
    calendarWeekDay: '#466d7f',
    calendarSelectedDay: '#EFEFEF',
  },
};

interface ThemeContextProviderProps {
  children: unknown;
}

interface ThemeContextProviderState {}

export const ThemeContext = React.createContext<ClientTheme | any>({});
export const ThemeConsumer = ThemeContext.Consumer;

export class ThemeContextProvider
  extends React.Component<ThemeContextProviderProps, ThemeContextProviderState> {
  constructor(props: ThemeContextProviderProps) {
    super(props);

    this.state = { ...themes[GlobalConfig.get(GlobalConfig.Key.CLIENT_NAME)] };

    //if theme is not hardcoded for a client, set default colors for styles that are currently not being served by beth
    //and are not configurable in Admin Center
    if(!themes[GlobalConfig.get(GlobalConfig.Key.CLIENT_NAME)]) {
      this.state = {...{
          calendarCurrentWeek: '#f9f29c',
          calendarBeforeCurrentWeek: '#32485d',
          calendarNowDate: '#f9c486',
          calendarWeekDay: '#466d7f',
          calendarSelectedDay: '#EFEFEF',
        }
      };
    }
  }

  updateTheme = (nextState: ThemeContextProviderState) => {
    this.setState(nextState);
  };

  render() {
    const { children } = this.props;
    return (
      <ThemeContext.Provider value={{ ...this.state, updateTheme: this.updateTheme }}>
        {children}
      </ThemeContext.Provider>
    );
  }
}
