import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { ThemeConsumer } from '../../context/ThemeContext';
import styles from './Login.module.css';
import LoginForm from './LoginForm/LoginForm';
import Messages from './Messages/Messages';
import AdminApi from '../../api/AdminApi';
import alertConfig from '../../config/alert';
import ColorSwatches from '../../shared/ColorSwatches/ColorSwatches';
import { LoginMessage } from './types';
import { AlertConfigCategory } from '../../types';
import GlobalConfig from "../../utils/GlobalConfig";
import Auth0Login from "./Auth0Login/Auth0Login";

const { version } = require('../../../package.json');

interface LoginProps {
  openAlert: (alert: AlertConfigCategory, async?: boolean) => void;
  handleUserLogin: (email: string, password: string) => void;
  handleLoginResponse: (tokens: any) => void;
  loadingStatus: string;
}

interface LoginState {
  closedMessages: number[];
  messages: LoginMessage[];
}

export class Login extends React.Component<LoginProps & RouteComponentProps, LoginState> {
  constructor(props: LoginProps & RouteComponentProps) {
    super(props);

    this.state = {
      closedMessages: [],
      messages: [],
    };
  }

  componentDidMount() {
    if (GlobalConfig.get(GlobalConfig.Key.KOVA_API_URL)) {
      this.setMessages();
    }
  }

  setMessages = async () => {
    const { openAlert } = this.props;

    try {
      const messages = await AdminApi.fetchMessages();
      this.setState({ messages });
    } catch (err) {
      openAlert(alertConfig.error.loginMessages);
    }
  };

  closeMessage = (id: number) => {
    const { closedMessages } = this.state;
    const update = [...closedMessages];
    update.push(id);
    this.setState({ closedMessages: update });
  };

  render() {
    const { handleUserLogin, loadingStatus, handleLoginResponse, openAlert } = this.props;
    const { closedMessages, messages } = this.state;
    const useAuth0 = GlobalConfig.get(GlobalConfig.Key.USE_AUTH0) === "true";

    return (
      <ThemeConsumer>
        {(theme) => (
          <div
            className={styles.login}
            style={{ backgroundColor: theme.primaryMenuBg }}
          >
            {messages.length > 0 && (
              <Messages
                closeMessage={this.closeMessage}
                closedMessages={closedMessages}
                messages={messages}
              />
            )}
            {/*<ColorSwatches />*/}
            <div className={styles.loginContainer}>
              <div className={styles.heroImage} />
              <div
                className={styles.formContainer}
                style={{ backgroundColor: theme.primaryMenuBg }}
              >
                { !useAuth0 && (
                    <LoginForm
                      handleUserLogin={handleUserLogin}
                      loadingStatus={loadingStatus}
                    />
                )}
                { useAuth0 && <Auth0Login handleLoginResponse={handleLoginResponse} openAlert={openAlert}></Auth0Login> }

              </div>
            </div>
            <div className={styles.version}>
              {GlobalConfig.get(GlobalConfig.Key.DATA_PRIVACY_URL) && (
                <><a href={GlobalConfig.get(GlobalConfig.Key.DATA_PRIVACY_URL)}>Privacy</a>
                  &nbsp;&nbsp;|&nbsp;&nbsp;
                </>
              )}
              {`V ${version}`}</div>
          </div>
        )}
      </ThemeConsumer>
    );
  }
}

export default withRouter(Login);
