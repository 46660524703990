import React from 'react';
import PropTypes from 'prop-types';
import pdfIcon from '../../../img/pdfIcon.svg';
import styles from './Document.module.css';

interface DocumentProps {
  link: string;
  name: string;
}

function Document ({ link, name }: DocumentProps) { 
  return (
    <a
      className={styles.Document}
      href={link}
      download
    >
      <img className={styles.icon} src={pdfIcon} alt="document" />
      <div>{name.split('.')[0]}</div>
    </a>
  );
}

export default Document;
