import Api from '../../api/Api';
import alertConfig from '../../config/alert';

function checkPasswordMatch(passwordNew, passwordConfirm) {
  return passwordNew === passwordConfirm;
}

function getQueryString() {
  const url = window.location.href;
  let queryParameters = '';
  if (url.lastIndexOf('?') !== -1) {
    [, queryParameters] = url.split('?');
  }
  return queryParameters;
}

export async function handleSubmit(
  e,
  formValues,
  handleLogout,
  openAlert,
  setValidationErrors,
  setSubmitDisabled,
) {
  e.preventDefault();
  setSubmitDisabled(true);
  const { passwordNew, passwordConfirm } = formValues;
  const validationErrors = {};
  const passwordsMatch = checkPasswordMatch(passwordNew, passwordConfirm);

  if (!passwordsMatch) {
    validationErrors.PASSWORDMISMATCH = 'Passwords do not match';
  } else {
    try {
      await Api.changeForgotPassword(formValues, getQueryString());
      openAlert(alertConfig.changePassword);
      handleLogout();
    } catch (error) {
      console.dir(error);
      const { Message } = error.response.data;
      const { ModelState } = error.response.data;
      if (Message === 'Could not change password! The old password is incorrect!') {
        validationErrors.LOGINFAILED = 'Email and/or Password is not recognized';
      } else if (Message === 'The request is invalid.' && ModelState && ModelState['wcResetPassword.NewPassword'] && ModelState['wcResetPassword.NewPassword'][0]) {
        var error = ModelState['wcResetPassword.NewPassword'][0];
        validationErrors.LOGINFAILED = error;
      } else {
        openAlert(alertConfig.error.default);
      }
    }
  }
  setValidationErrors(validationErrors);
  setSubmitDisabled(false);
}
