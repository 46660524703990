class GlobalConfig {
  config : Record<string, string>;

  Key : Record<string, string>;

  constructor() {
    this.config = {};
    this.Key = {
      BASE_URL: 'BASE_URL',
      KOVA_ENV: 'KOVA_ENV',
      CLIENT_NAME: 'CLIENT_NAME',
      SELECTIONS_TYPE: 'SELECTIONS_TYPE',
      MAIL_LINK: 'MAIL_LINK',
      CONTACT_LINK: 'CONTACT_LINK',
      FAQ_LINK: 'FAQ_LINK',
      USEFUL_LINK: 'USEFUL_LINK',
      KOVA_USER_RID: 'KOVA_USER_RID',
      BRANDING_NAME: 'BRANDING_NAME',
      KOVA_URL_PROD: 'KOVA_URL_PROD',
      ACTIVITY_FILTER: 'ACTIVITY_FILTER',
      ACTIVITY_MODULE_FILTER: 'ACTIVITY_MODULE_FILTER',
      GOOGLE_ANALYTICS_ID: 'GOOGLE_ANALYTICS_ID',
      HUBSPOT_ANALYTICS_ID: 'HUBSPOT_ANALYTICS_ID',
      WARRANTY_LINK: 'WARRANTY_LINK',
      SVCREQCATEG: 'SVCREQCATEG',
      DOC_TYPE: 'DOC_TYPE',
      ONLINE_DC_LINK: 'ONLINE_DC_LINK',
      TRACKER_DATA: 'TRACKER_DATA',
      ACTIVE_TIME: 'ACTIVE_TIME',
      PW_COMPLEXITY: 'PW_COMPLEXITY',
      OPTLOC_FILTER: 'OPTLOC_FILTER',
      DATA_PRIVACY_URL: 'DATA_PRIVACY_URL',
      KOVA_API_URL: 'KOVA_API_URL',
      API_SCOPE: 'API_SCOPE',
      MSAL_CLIENT_ID: 'MSAL_CLIENT_ID',
      MSAL_AUTHORITY: 'MSAL_AUTHORITY',
      KOVA_API_ENVIRONMENT: 'KOVA_API_ENVIRONMENT',
      COMMUNITY_SPECIFIC_WARRANTY_DOCS: 'COMMUNITY_SPECIFIC_WARRANTY_DOCS',
      WARRANTY_PROVIDER: 'WARRANTY_PROVIDER',
      USE_AUTH0: 'USE_AUTH0',
      AUTH0_CLIENT_ID: "AUTH0_CLIENT_ID",
      AUTH0_DOMAIN: "AUTH0_DOMAIN",
      AUTH0_AUDIENCE: "AUTH0_AUDIENCE"
    };
  }

  setConfig(config : Record<string, string>) {
    this.config = config;
  }

  get(key : string) {
    return this.config[key];
  }
}

const configInstance = new GlobalConfig();

export default configInstance;
