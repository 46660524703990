import axios from 'axios';
import GlobalConfig from '../utils/GlobalConfig';

export default class AdminApi {
  static kovaApiURL(){
    return `${GlobalConfig.get(GlobalConfig.Key.KOVA_API_URL)}admincenter/`;

  }

  static fetchFiles = async () => {
    const response = await axios.get(`${this.kovaApiURL()}Files`);
    return response.data;
  };

  static fetchLinks = async () => {
    const response = await axios.get(`${this.kovaApiURL()}Links`);
    return response.data;
  };

  static fetchMessages = async () => {

    const response = await axios.get(`${this.kovaApiURL()}Messages`);
    return response.data;
  };

  static fetchColorSchemes = async () => {
    const response = await axios.get(`${this.kovaApiURL()}colorSchemes`);
    return response.data;
  };

  static fetchSettings = async () => {
    const response = await axios.get(`${this.kovaApiURL()}settings`);
    return response.data;
  };
}
