export default class BrowserUtils {
  /**
   * detectIE identifies if a Microsoft browser is present (IE, Edge).
   * @param {Array<String>} browsers - a list of browsers to check for. Options
   * are 'ie10' for IE 10 and below, 'ie11', and 'edge'.
   * @param {Boolean || String} test - browser to test for. If specified, will
   * replace window userAgent value with the userAgent value of the test
   * browser. Options are 'ie10', 'ie11', 'edge12', and 'edge13'. Defaults to
   * false.
   * @return {Boolean || String} The userAgent value of the detected browser, or
   * false if a non-microsoft browser is detected.
   */
  static detectIE = (browsers, test = false) => {
    let ua = window.navigator.userAgent;

    // Testing logic
    // IE 10
    if (test === 'ie10') {
      ua = 'Mozilla/5.0 (compatible; MSIE 10.0; Windows NT 6.2; Trident/6.0)';
    }
    // IE 11
    if (test === 'ie11') {
      ua = 'Mozilla/5.0 (Windows NT 6.3; Trident/7.0; rv:11.0) like Gecko';
    }
    // Edge 12 (Spartan)
    if (test === 'edge12') {
      ua = 'Mozilla/5.0 (Windows NT 10.0; WOW64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/39.0.2171.71 Safari/537.36 Edge/12.0';
    }
    // Edge 13
    if (test === 'edge13') {
      ua = 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/46.0.2486.0 Safari/537.36 Edge/13.10586';
    }

    // Check browser version
    // >= IE 10
    if (browsers.includes('ie10')) {
      const msie = ua.indexOf('MSIE ');

      if (msie > 0) {
        // IE 10 or older => return version number
        return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
      }
    }

    // IE 11
    if (browsers.includes('ie11')) {
      const trident = ua.indexOf('Trident/');

      if (trident > 0) {
        // IE 11 => return version number
        const rv = ua.indexOf('rv:');
        return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
      }
    }

    // Edge
    if (browsers.includes('edge')) {
      const edge = ua.indexOf('Edge/');

      if (edge > 0) {
        // Edge (IE 12+) => return version number
        return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
      }
    }

    // other browser
    return false;
  }
}
