import React from 'react';
import PropTypes from 'prop-types';
import { Box, CircularProgress, Typography } from '@mui/material';
import styles from './LoadingIndicator.module.css';
// See: https://loading.io/css/

interface LoadingIndicatorProps {
  color: string;
  message?: string;
  progress?: number;
}

function LoadingIndicator({ color, message, progress }: LoadingIndicatorProps) {
  return (
    <div className={styles.LoadingIndicator}>
      <Box
        sx={{
          color,
          position: 'relative',
          display: 'inline-flex',
          marginBottom: '1rem',
        }}
      >
        <CircularProgress
          color="inherit"
          size={80}
          value={progress}
          variant={progress ? 'determinate' : 'indeterminate'}
        />
        {progress && (
          <Box
            sx={{
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              position: 'absolute',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Typography variant="caption" component="div" color="text.secondary">
              {`${Math.round(progress)}%`}
            </Typography>
          </Box>
        )}
      </Box>
      <Typography variant="body1">{message || 'Loading...'}</Typography>
    </div>
  );
}

LoadingIndicator.defaultProps = {
  message: undefined,
  progress: undefined,
};

LoadingIndicator.propTypes = {
  color: PropTypes.string.isRequired,
  message: PropTypes.string,
  progress: PropTypes.number,
};

export default LoadingIndicator;
