import React from 'react';
import PropTypes from 'prop-types';
import { ThemeConsumer } from '../../context/ThemeContext';
import LoadingIndicator from '../../shared/LoadingIndicator';
import chromeIcon from '../../img/chrome-icon.png';
import safariIcon from '../../img/safari-icon.png';
import firefoxIcon from '../../img/firefox-icon.png';
import edgeIcon from '../../img/edge-icon.png';
import styles from './Alert.module.css';
import { AlertConfigCategory, ClientName } from '../../types';
import GlobalConfig from '../../utils/GlobalConfig';

interface AlertProps {
  alert: AlertConfigCategory;
  closeAlert: () => void;
  isLoading: boolean | null;
}

function Alert({ alert, closeAlert, isLoading }: AlertProps) {
  const browsers = [
    { icon: chromeIcon, link: 'https://www.google.com/chrome/' },
    { icon: safariIcon, link: 'https://support.apple.com/downloads/safari' },
    { icon: firefoxIcon, link: 'https://www.mozilla.org/en-US/firefox/new/' },
    { icon: edgeIcon, link: 'https://www.microsoft.com/en-us/windows/microsoft-edge' },
  ];
  const listBrowsers = browsers.map((browser) => (
    <a key={browser.link} href={browser.link} target="_blank" rel="noopener noreferrer">
      <img src={browser.icon} alt="browser icon" />
    </a>
  ));

  let { message } = alert;
  const { customerOverrides, heading } = alert;

  const client = GlobalConfig.get(GlobalConfig.Key.CLIENT_NAME) as ClientName;
  if (customerOverrides?.[client]) {
    message = alert.customerOverrides![client]!;
  }

  return (
    <ThemeConsumer>
      {(theme) => (
        <div className={styles.Alert}>
          <div className={styles.modal}>
            <h1 style={
              {
                backgroundColor: theme.titleBarBgColor,
                color: theme.titleBarFontColor,
                fontSize: theme.titleBarFontSize,
                textAlign: alert.textAlign ? alert.textAlign : "inherit"
              }
            }
            >
              {alert.heading}
            </h1>
            <div
              className={styles.message}
              style={alert.textAlign && { textAlign: alert.textAlign }}
            >
              <div>{message.body}</div>
            </div>
            {alert.type === 'browser' && <ul>{listBrowsers}</ul>}
            {alert.button && (
              <>
                {isLoading ? (
                  <LoadingIndicator color={theme.accentColorA} />
                ) : (
                  <button
                    disabled={!!isLoading}
                    type="button"
                    onClick={() => {
                      closeAlert();
                      if (alert.resolvePromise) {
                        alert.resolvePromise();
                      }
                    }}
                    style={{ backgroundColor: theme.accentColorA }}
                  >
                    { alert.button.text }
                  </button>
                )}
              </>
            )}
            {alert.type === 'confirm' && (
              <div className={styles.buttonWrapper}>
                <button
                  type="button"
                  onClick={() => {
                    closeAlert();
                    if (alert.resolvePromise) {
                      alert.resolvePromise(false);
                    }
                  }}
                  style={{ backgroundColor: theme.accentColorA }}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  onClick={() => {
                    closeAlert();
                    if (alert.resolvePromise) {
                      alert.resolvePromise(true);
                    }
                  }}
                  style={{ backgroundColor: theme.accentColorA }}
                >
                  OK
                </button>
              </div>
            )}
          </div>
        </div>
      )}
    </ThemeConsumer>
  );
}

Alert.defaultProps = {
  closeAlert: null,
  isLoading: false,
};

Alert.propTypes = {
  alert: PropTypes.shape({
    button: PropTypes.shape({
      text: PropTypes.string.isRequired,
    }),
    customerOverrides: PropTypes.object,
    heading: PropTypes.string.isRequired,
    message: PropTypes.shape({
      body: PropTypes.string.isRequired,
      heading: PropTypes.string,
    }).isRequired,
    resolvePromise: PropTypes.any,
    textAlign: PropTypes.any,
    type: PropTypes.string.isRequired,
  }).isRequired,
  closeAlert: PropTypes.func,
  isLoading: PropTypes.bool,
};

export default Alert;
