import SelectionItem from "./SelectionItem/SelectionItem";
import React from "react";
import {ISelectionImage, ISlsOrdOpt} from "./Selections";
import styles from "./SelectionsGallery.module.css";
import placeholder from "../../img/selection-default.png";
import SelectionListItem from "./SelectionListItem/SelectionListItem";

interface ISelectionsGalleryProps {
  selectionsType:string;
  slsOrdOpts: ISlsOrdOpt[];
  selectionImages?: ISelectionImage[];
}

interface IOptCateg {
  OptCategRID:number;
  Name:string;
  SortOrder:number;
  Locations:Map<number,IOptLoctn>;
}
interface IOptLoctn {
  OptLoctnRID:number;
  Name:string;
  SortOrder:number;
  OptsWithImages: {opt: ISlsOrdOpt, image: string | undefined}[];
}

function SelectionsGallery(props: ISelectionsGalleryProps) {
  const { selectionsType, slsOrdOpts, selectionImages } = props;

  const opts = slsOrdOpts.filter((opt) => (opt.OptValDescr !== '- Not Selected -'));
  const optsWithImages = opts.map((opt) => {
    const selectionImage = selectionImages?.find((img) => img.slsOrdOptRID === opt.SlsOrdOptRID);
    let image = undefined;
    if (selectionImage) {
      image = selectionImage.img;
    }
    return {opt:opt, image:image}
  });

  return (
    selectionsType === 'default' ?
      (<BoxGallery optsWithImages={optsWithImages} />) :
      (<ListGallery optsWithImages={optsWithImages} />)
  )
}

function BoxGallery(props:{optsWithImages: {opt: ISlsOrdOpt, image: string | undefined}[]}) {
  const optsWithImages = props.optsWithImages;

  return (
    <>
      {
        optsWithImages.map(optWithImage => {
          const opt = optWithImage.opt;
          const image = optWithImage.image;

          return (
            <SelectionItem
              key={opt.ObjectRID}
              image={image}
              opt={opt}
            />
          );
        })
      }
    </>
  )
}

function ListGallery(props:{optsWithImages: {opt: ISlsOrdOpt, image: string | undefined}[]}) {
  const optsWithImages = props.optsWithImages;

  let categories = new Map<number,IOptCateg>();

  optsWithImages.forEach(optWithImages => {
    let category = categories.get(optWithImages.opt.OptCategRID);
    if (!category) {
      category = {
        OptCategRID:optWithImages.opt.OptCategRID,
        Name:optWithImages.opt.OptCategName ?? "Unknown Category",
        SortOrder:optWithImages.opt.OptCategSortOrder ?? 0,
        Locations: new Map<number, IOptLoctn>()
      };
      categories.set(category.OptCategRID, category);
    }

    let location = category.Locations.get(optWithImages.opt.OptLoctnRID);
    if (!location) {
      location = {
        OptLoctnRID:optWithImages.opt.OptLoctnRID,
        Name:optWithImages.opt.OptLoctnName ?? "Unknown Location",
        SortOrder:optWithImages.opt.OptLoctnSortOrder ?? 0,
        OptsWithImages: []
      };
      category.Locations.set(location.OptLoctnRID, location);
    }
    location.OptsWithImages.push(optWithImages);
  });
  const optCategs = Array.from(categories.values()).sort((a,b) => {
    return a.SortOrder - b.SortOrder
  });

  return (
    <>
      {
        optCategs.map(category => {
          return (
            <div className={styles.category}>
              <div className={styles.categoryName}>{category?.Name}</div>
              {
                Array.from(category.Locations.values()).sort((a,b) => {
                  return a.SortOrder - b.SortOrder
                }).map(location => {
                  return (<div className={styles.location}>
                    <div className={styles.locationName}>{location.Name}</div>
                      {
                        location.OptsWithImages
                          .sort((optWithImageA, optWithImageB) => {
                            return optWithImageA.opt.OptSelDescr.localeCompare(optWithImageB.opt.OptSelDescr)
                          })
                          .map(optWithImage => {
                            return (<SelectionListItem
                              key={optWithImage.opt.ObjectRID}
                              image={optWithImage.image}
                              opt={optWithImage.opt}
                            />)
                          })
                      }
                    </div>
                  )
                })
              }
            </div>
          )
        })
      }
    </>
  )
}

export default SelectionsGallery;
