import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Moment from 'moment';
import ProgressTracker from '../../shared/ProgressTracker/ProgressTracker';
import Notifications from '../../shared/Notifications/Notifications';
import Schedule from '../../shared/Schedule/Schedule';
import Photos from '../../shared/Photos/Photos';
import TrackerVideo from '../TrackerVideo';
import styles from './Dashboard.module.css';
import { WindowConsumer } from '../../context/WindowContext';
import { ActivityStep, StepObj } from '../../types';
import { ThemeConsumer } from '../../context/ThemeContext';
import GlobalConfig from "../../utils/GlobalConfig";
import {IToken} from "@bimaire/optionslib/build/api/customerportal-client";
import DesignDashboard from '../DesignDashboard/DesignDashboard';
import {getEnabledRouteNames} from '../App/utils';

interface DashboardProps {
  activities: ActivityStep[];
  kovaApiToken: IToken|null,
  communityBUnitRID: number;
  handleLogout: (sessionExpired:boolean, resetAlert?:boolean) => void;
  homeInfo: { CommunityRID: number; }
  homeRID: number;
  setModalVideo: unknown;
  showCalendar: unknown;
  hideFutureActivities: boolean;
  numberOfFutureActivities: number;
  slsOrdRID: number;
  pushOut: unknown;
}
const listCompletedTasks = (acts: any[]) => {
  if (acts) {
    const filterCompletedActs = acts.filter((act) => act.Status === "Approved");
    const sortByDate = filterCompletedActs.sort(
        (a, b) => new Date(b.FinishDateCur).getTime()
            - new Date(a.FinishDateCur).getTime(),
    );

    return sortByDate.map((activity) => (
        <li className={styles.activityItem}>
          <div className={styles.dateDiv}>{Moment(activity.FinishDateCur).format('MM/DD/YYYY')}</div>
          <div>{activity.portalActName ? activity.portalActName : activity.ActName}</div>
        </li>
    ));
  }
  return null;
};

export const DashboardSelector : React.FC<DashboardProps> = (props) => {
  const { kovaApiToken } = props;
  if (kovaApiToken !== null && kovaApiToken.hasDcAccess) {
    return <DesignDashboard kovaApiToken={kovaApiToken}></DesignDashboard>
  }
  else if (!props.communityBUnitRID) {
    return null;
  } else {
    return <Dashboard {...props} />
  }
}

function Dashboard({
  activities,
  communityBUnitRID,
  handleLogout,
  homeInfo,
  homeRID,
  setModalVideo,
  showCalendar,
  hideFutureActivities,
  numberOfFutureActivities,
  slsOrdRID,
  pushOut,
}: DashboardProps) {
  const [scheduleOverlayActive, setScheduleOverlayActive] = useState<boolean>(false);
  const [selectedMilestone, setSelectedMilestone] = useState<null | ActivityStep>(null);
  const dataLoaded: boolean = activities && homeRID ? true : false;
  const [acts, setActs] = useState<ActivityStep[]>([]);
  const showScrapbook = () => {
    const routes = getEnabledRouteNames() ?? [""];
    return routes.includes("scrapbook");
  }

  const filterScheduleActs = () => {
    const newArray: ActivityStep[] = [];
    activities?.forEach((act: ActivityStep) => {
      if (act.isSchedule || !GlobalConfig.get(GlobalConfig.Key.ACTIVITY_MODULE_FILTER)) {
        newArray.push(act);
      }
    });
    setActs(newArray);
    return newArray;
  };

  useEffect(() => {
    filterScheduleActs();
  }, [activities]);



  return (
    <ThemeConsumer>
      {(theme) => (
        dataLoaded ? (
          <div
            className={styles.dashboard}
            onClick={() => setScheduleOverlayActive(false)}
            onKeyPress={() => setScheduleOverlayActive(false)}
            role="button"
            tabIndex={0}
          >
            <div className={`${styles.progressTracker}`}>
              <WindowConsumer>
                {(context) => (
                  <ProgressTracker
                    activities={activities}
                    handleLogout={handleLogout}
                    homeRID={homeRID}
                    selectedMilestone={selectedMilestone}
                    setSelectedMilestone={setSelectedMilestone}
                    slsOrdRID={slsOrdRID}
                    windowWidth={context}
                    pushOut={pushOut}
                  />
                )}
              </WindowConsumer>
              {selectedMilestone && (
                <div className={styles.trackerDescription}>
                  {selectedMilestone.portalActDesc}
                </div>
              )}
            </div>
            <div className={`${styles.panel} ${styles.notifications}`}>
              <Notifications
                activities={activities}
                communityBUnitRID={communityBUnitRID}
                communityRID={homeInfo.CommunityRID}
                homeRID={homeRID}
                handleLogout={handleLogout}
                pushOut={pushOut}
                slsOrdRID={slsOrdRID}
                hideFutureActivities={hideFutureActivities}
              />
            </div>
            {GlobalConfig.get(GlobalConfig.Key.TRACKER_DATA) === 'activities' ? (
              <TrackerVideo
                selectedMilestone={selectedMilestone!}
                setModalVideo={setModalVideo}
              />
            ) : showScrapbook() && (
              <div className={`${styles.panel} ${styles.recentPhotos}`}>
                <Photos
                  handleLogout={handleLogout}
                  homeRID={homeRID}
                  widget
                />
              </div>
            )}
            {showCalendar ? (
              <div className={`${styles.panel} ${styles.schedule}`}>
                <Schedule
                  activities={activities}
                  scheduleOverlayActive={scheduleOverlayActive}
                  setScheduleOverlayActive={setScheduleOverlayActive}
                  hideFutureActivities={hideFutureActivities}
                  numberOfFutureActivities={numberOfFutureActivities}
                  widget
                />
              </div>
            ) : (
              <div className={styles.colLeft}>
                <div
                  style={
                    {
                      backgroundColor: theme.titleBarBgColor,
                      color: theme.titleBarFontColor,
                      fontSize: theme.titleBarFontSize,
                    }
                  }
                  className={styles.titleBar}
                >
                  {`(${listCompletedTasks(acts)!.length}) Completed Tasks`}
                </div>
                <ul className={styles.completedList}>
                  {listCompletedTasks(acts)}
                </ul>
              </div>
            )}
            {GlobalConfig.get(GlobalConfig.Key.TRACKER_DATA) === 'activities' && showScrapbook() && (
              <div className={`${styles.panel} ${styles.recentPhotosAlt}`}>
                <Photos
                  activities={activities}
                  handleLogout={handleLogout}
                  homeRID={homeRID}
                  widget
                />
              </div>
            )}
          </div>
        ) : (
          null
        )
      )}
    </ThemeConsumer>
  );
}

Dashboard.defaultProps = {
  activities: null,
  pushOut: null,
};

Dashboard.propTypes = {
  activities: PropTypes.arrayOf(PropTypes.object),
  communityBUnitRID: PropTypes.number.isRequired,
  handleLogout: PropTypes.func.isRequired,
  homeRID: PropTypes.number.isRequired,
  pushOut: PropTypes.bool,
  slsOrdRID: PropTypes.number.isRequired,
};

export default Dashboard;
