import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as moment from 'moment';
import { ThemeContext } from '../../../context/ThemeContext';
import styles from './ClaimTicket.module.css';
import GlobalConfig from "../../../utils/GlobalConfig";
import {formatDate} from "../ClaimModal/SvcReqDetails/utils";
import {GetUserFriendlyStatusFromFieldpointStatus, statusMap} from "../ClaimModal/utils";
import {IsCustomerEditable} from "../../../utils/WarrantyUtil";

class ClaimTicket extends Component {
  static contextType = ThemeContext;

  state = {
    width: 0,
  };

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  descriptionCheck = () => {
    const { width } = this.state;
    const { svcReq: { Description } } = this.props;
    const windowWidth = width;
    let stringSize = 0;
    if (windowWidth < 500) {
      stringSize = 20;
    } else if (windowWidth < 730 && windowWidth >= 500) {
      stringSize = 40;
    } else if (windowWidth < 1400 && windowWidth >= 730) {
      stringSize = 60;
    } else if (windowWidth >= 1400) {
      stringSize = 45;
    }

    if (Description.length > stringSize) {
      let newString = Description.substring(0, stringSize);
      const newStringSpace = newString.lastIndexOf(' ');
      newString = newString.substring(0, newStringSpace);
      newString = `${newString}...`;
      return newString;
    }
    return Description;
  };

  updateWindowDimensions = () => {
    this.setState({ width: window.innerWidth });
  };

  setBackgroundColor = (Status, svcReqStatusDescriptions) => {

    let statusDescription = svcReqStatusDescriptions.find((statusDescription) => statusDescription.title === Status);
    if(statusDescription){
      return statusDescription.color;
    }

    return "";
  };

  render() {
    const {
      setActiveSvcReq,
      svcReq,
      toggleClaimModal,
      svcReqStatusDescriptions
  } = this.props;
    let {
      PrefServiceDate,
      DateReceived,
      Name,
      Status,
      SvcReqID,
      SvcReqCategSvcReqCategID,
      ReceiverUserRID
    } = svcReq;

    let useFieldpoint = GlobalConfig.get(GlobalConfig.Key.WARRANTY_PROVIDER) === "Fieldpoint";
    if (useFieldpoint) {
      Status = GetUserFriendlyStatusFromFieldpointStatus(Status);
    }
    else if (Status == "New" && !IsCustomerEditable(Status, SvcReqCategSvcReqCategID, ReceiverUserRID)) {
      Status = "In Review";
    }
    Status = useFieldpoint ? GetUserFriendlyStatusFromFieldpointStatus(Status) : Status;

    return (
      <button
        className={styles.ClaimTicket}
        onClick={() => {
          toggleClaimModal(svcReq.SvcReqRID);
        }}
        style={{ backgroundColor: this.setBackgroundColor(Status, svcReqStatusDescriptions) }}
        type="button"
      >
        <div className={styles.claimNum}>{SvcReqID}</div>
        {!useFieldpoint && <div className={styles.name}>{Name}</div>}
        <div className={styles.descriptionWrapper}>
          {this.descriptionCheck()}
        </div>
        <div className={styles.date}>{useFieldpoint ? formatDate(PrefServiceDate) : moment(DateReceived).format('M-D-YY')}</div>
        <div className={styles.status}>{Status}</div>
      </button>
    );
  }
}

ClaimTicket.propTypes = {
  svcReqRID: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
};

export default ClaimTicket;
