export default class SessionUtils {
  static getSessionState = () => {
    const sessionState = sessionStorage.getItem('ahp-session-state');
    return sessionState;
  };

  static setSessionState = (state) => {
    const value = JSON.stringify(state);
    sessionStorage.setItem('ahp-session-state', value);
  };

  static clearSessionState = () => {
    sessionStorage.clear();
  };
}
