
export function formatDate(dateString: string) {
  let date = new Date(dateString);
  if (dateString.includes('00:00:00')) {
    date = new Date(dateString.replace(/-/g, '/').replace(/T.+/, ''));
  }
  const year = date.getFullYear();
  if (year <= 1901) {
    return '';
  }
  const month = (1 + date.getMonth()).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  const formattedDate = `${month}-${day}-${year}`;

  return formattedDate;
}
