import { v4 as uuidv4 } from 'uuid';

export function addNewSvcReqItem(svcReqItems, setSvcReqItems) {
  const nextSvcReqItems = [...svcReqItems];
  nextSvcReqItems.push({
    description: '',
    files: [],
    location: '',
    key: uuidv4(),
  });
  setSvcReqItems(nextSvcReqItems);
}

export function calcCurrentSeqNumber(svcReqItms) {
  const seqNumbers = svcReqItms.map(({ seqNumber }) => seqNumber || 0);
  const currentSeqNumber = Math.max(...seqNumbers);

  return currentSeqNumber;
}

export function parseRIDFromLocationHeader(locationHeader) {
  /**
   * Assumes location header format matches example below:
   * https://kova1.mungo.com/KovaMungoBetaWebConfigurator/odata/SvcReqs(1489223)
   */
  const match = locationHeader.match(/\(([^)]+)\)/);

  if (match) {
    return Number(match[1]);
  }
  return undefined;
}

export function GetUserFriendlyStatusFromFieldpointStatus(status){
  const statusMap = {
    "New": ["Entered", "Assigned", "Online"],
    "Open": ["Scheduled", "Working On"],
    "Closed": ["Completed", "Closed", "Pending AP", "QA Review", "Not Warrantable", "AwaitingInvoice"],
    "On Hold": ["On Hold", "Weather", "Out of Town", "Materials Hold", "Legal", "LiveGoodsIrriga"]
  };

  for(let statusKey of Object.keys(statusMap)) {
    if (statusMap[statusKey].some(fpStatus => fpStatus === status)) {
      return statusKey;
    }
  };

  return status;
}

