import GlobalConfig from "./GlobalConfig";

export function setGoogleAnalytics() {
  if (GlobalConfig.get(GlobalConfig.Key.GOOGLE_ANALYTICS_ID)) {
    // Google tag manager
    const tagManagerScript = document.createElement('script');
    tagManagerScript.setAttribute('async', 'true');
    tagManagerScript.setAttribute('src', `https://www.googletagmanager.com/gtag/js?id=${GlobalConfig.get(GlobalConfig.Key.GOOGLE_ANALYTICS_ID)}`);
    document.head.appendChild(tagManagerScript);

    // Google Analytics
    let wnd = (window as any);
    wnd.dataLayer = wnd.dataLayer || [];
    wnd.gtag = function () {
      wnd.dataLayer.push(arguments);
    };
    wnd.gtag('js', new Date());

    wnd.gtag('config', GlobalConfig.get(GlobalConfig.Key.GOOGLE_ANALYTICS_ID));
  }
}