


export function assertNotNull<T>(x:T|null|undefined): T {
    if (x === null) console.log("not null assertion failed!");
    if (x === undefined) console.log("not undefined assertion failed!");
    return x!;
}


