import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './ModalVideo.module.css';
import { ThemeConsumer } from '../../context/ThemeContext';

class ModalVideo extends Component {
  render() {
    const {
      setModalVideo,
      videoURL,
      title,
    } = this.props;

    return (
      <ThemeConsumer>
        {(theme) => (
          <div className={styles.ModalVideo}>
            <div className={styles.videoContainer}>
              <div className={styles.video}>
                <div
                  className={styles.titleBar}
                  style={
                    {
                      backgroundColor: theme.titleBarBgColor,
                      color: theme.titleBarFontColor,
                      fontSize: theme.titleBarFontSize,
                    }
                  }
                >
                  {title}
                  <div
                    className={styles.close}
                    onClick={() => setModalVideo('')}
                    onKeyPress={() => setModalVideo('')}
                  >
                    X
                  </div>
                </div>
                <ul className={`${styles.panelContent} ${styles.active}`}>
                  <iframe src={videoURL} title={title} />
                </ul>
              </div>
            </div>
          </div>
        )}
      </ThemeConsumer>
    );
  }
}

ModalVideo.propTypes = {
  setVideoActive: PropTypes.object.isRequired,
  videoURL: PropTypes.string.isRequired,
};

export default ModalVideo;
