import React from "react";

export interface ISelectionsPreview {
  windowWidth:number;
  slsOrdRID:number;
  startSheetUrl:string;
}
function SelectionsPreview(props: ISelectionsPreview) {
  const { windowWidth, slsOrdRID, startSheetUrl } = props;
  return (
    <>
      {(windowWidth < 1025 ?
        <a href={startSheetUrl}>Open PDF</a>
        :
        <iframe key={slsOrdRID} src={startSheetUrl} title="start sheet" />)}
    </>
  )
}

export default SelectionsPreview;
