import {PopupConfigOptions, useAuth0} from "@auth0/auth0-react"
import React, {useEffect, useRef, useState} from "react";
import GlobalConfig from "../../../utils/GlobalConfig";
import styles from './Auth0Login.module.css';
import {ThemeConsumer} from "../../../context/ThemeContext";
import alertConfig from "../../../config/alert";
import {AlertConfigCategory} from "../../../types";
import Dictionary from "../../../utils/Dictionary";
import {DictionaryDangerousTextAsHtml} from "../../Dictionary/DictionaryDangerousTextAsHtml";
import {DictionaryText} from "../../Dictionary/DictionaryText";

const Auth0Login: React.FC<{handleLoginResponse: any, openAlert:(alert: AlertConfigCategory, async?: boolean) => void}> = (props) => {
    const { openAlert } = props;
    const buttonRef = useRef<HTMLButtonElement>(null);
    const logOutButtonRef = useRef<HTMLButtonElement>(null);
    const [authError, setAuthError] = useState<string>("");
    const [gettingTokens, setGettingTokens] = useState(true);

    const auth0Audience = GlobalConfig.get(GlobalConfig.Key.AUTH0_AUDIENCE);
    const auth0Scope = "CPG:Access:CustomerPortal";

    const {  user, error, isLoading, isAuthenticated, getAccessTokenSilently, getAccessTokenWithPopup, logout, loginWithPopup } = useAuth0();

    //Ability to force a log out
    const sp = new URLSearchParams(window.location.search);
    if (sp.get("logout") === "true") {
        logout({ returnTo: window.location.origin });
    }

    useEffect(() =>  {
        getAccessTokenSilently({
            audience: auth0Audience,
            scope: auth0Scope
        }).then(accessToken => {
            if (accessToken) {
                loginWithAccessToken(accessToken, true);
            } else {
                setGettingTokens(false);
            }
        }).catch(e => {
            //console.error("Login failed: " + e);
            setGettingTokens(false);
        });
    }, []);

    async function loginWithAccessToken(accessToken: string, silentMode: boolean) {
        const loginResp = await fetch(`${GlobalConfig.get(GlobalConfig.Key.KOVA_API_URL)}authenticate/LoginWithOAuth`,
          {
              method: 'POST',
              credentials: "include",
              headers: {
                  "Authorization": "Bearer " + accessToken
              }
          });
        if (loginResp.status == 500) {
            if (!silentMode) {
                const error = await loginResp.json()
                if (error.detail === 'Cannot find customer') {
                    await openAlert(alertConfig.error.loginAuth0CustomerNotFound, true);
                } else {
                    await openAlert(alertConfig.error.loginAuth0, true);
                }
                await logout({ returnTo: window.location.origin });
            }
            else {
                console.error("loginWithAccessToken failed with 500");
            }
        } else {
            const loginRespJson = await loginResp.json();
            props.handleLoginResponse(loginRespJson);
        }
    }

    return (
    <ThemeConsumer>
        {(theme) => (
            <div className={styles.wrapper}>
                {gettingTokens && (
                    <div>Loading account info...</div>
                )}
                {!gettingTokens && (
                  <>
                    {authError && (
                      <button
                        className={styles.submitButton}
                        style={{ backgroundColor: theme.accentColorA }}
                        onClick={async () => {
                            window.location.replace(window.location.origin);
                        }}
                      >
                          <div className={styles.authError}
                               style={{ backgroundColor: theme.accentColorA }}
                          >
                              <h2>{authError}</h2>
                          </div>
                      </button>
                    )}
                    {!authError && (
                        <>
                            <div className={styles.textWrapper}>
                                <div className={styles.header}>
                                    <DictionaryText dictionary={Dictionary} dictionaryKey={Dictionary.LoginTitle} />
                                </div>
                            </div>
                            <button
                              ref={buttonRef}
                              className={styles.submitButton}
                              style={{ backgroundColor: theme.accentColorA }}
                              onClick={async () => {
                                  try {
                                      buttonRef!.current!.disabled = true;
                                      buttonRef!.current!.textContent = "LOADING...";

                                      let config : PopupConfigOptions = {};
                                      config.timeoutInSeconds = 3600;
                                      config.popup = window.open('', `auth0:${auth0Scope}`)
                                      let accessToken = await getAccessTokenWithPopup({
                                          audience: auth0Audience,
                                          scope: auth0Scope
                                      }, config);

                                      await loginWithAccessToken(accessToken, false);
                                  } catch (e) {
                                      if (e.error === "cancelled") {
                                          //Don't do anything
                                          if (buttonRef!.current != null) {
                                              buttonRef.current.disabled = false;
                                              const text = Dictionary.getText(Dictionary.LoginButton);
                                              buttonRef.current.textContent = text;
                                          }
                                      }
                                      else if (e.error === "timeout") {
                                          setAuthError("Login timed out... Please refresh the page by clicking here!");
                                          openAlert(alertConfig.error.loginAuth0Timeout);
                                      }
                                      else {
                                          console.error("Login failed: " + e);
                                          await openAlert(alertConfig.error.loginAuth0, true);
                                          await logout({ returnTo: window.location.origin });
                                      }
                                  }
                            }}><DictionaryText dictionary={Dictionary} dictionaryKey={Dictionary.LoginButton} /></button>
                        </>
                      )}
                    </>
                    )}
                <div>
                    <DictionaryDangerousTextAsHtml dictionary={Dictionary} dictionaryKey={Dictionary.LoginInstructions} />
                </div>
            </div>
        )}
    </ThemeConsumer>
    );
}

export default Auth0Login;