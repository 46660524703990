import React, {useState} from 'react';
import styles from './SelectionListItem.module.css';
import {ISlsOrdOpt} from "../Selections";
import placeholder from "../../../img/selection-default.png";

export interface ISelectionListItemProps {
  image:string | undefined;
  opt:ISlsOrdOpt;
}

function SelectionListItem(props: ISelectionListItemProps) {
  const [imageLoaded, setImageLoaded] = useState(false);
  const {image, opt} = props;

  let optValDescription = opt.OptValDescr;
  if(optValDescription === "n/a" && opt.CurrentQty > 0) //this is true for quantity options
  {
      optValDescription = "qty " + opt.CurrentQty;
  }

  const loading = false;//|| image && !imageLoaded;

  return (
    <div className={styles.SelectionListItem}>
      <div>
        <span><b>{`${opt.OptSelDescr}:`}</b></span>
        <span>{optValDescription}</span>
        {opt.Color
          && (
            <><br /><span>&nbsp;&nbsp;<b>Color:&nbsp;</b>{`${opt.Color}`}</span></>
          )}
        {opt.Style
          && (
            <><br /><span>&nbsp;&nbsp;<b>Style:&nbsp;</b>{`${opt.Style}`}</span></>
          )}
      </div>
      {image && (
        <div className={styles.SelectionListItemImage}>
          {
            loading ?
              ("Loading image...") :
              (<img src={image} alt="selection" onLoad={() => setImageLoaded(true)} />)
          }
        </div>
      )}
    </div>
  );
}

export default SelectionListItem;
