import React from 'react';
import { Box, TextField } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { formatDate } from './utils';
import { assertNotNull } from '../../../../assertNotNull';
import GlobalConfig from "../../../../utils/GlobalConfig";
import {GetUserFriendlyStatusFromFieldpointStatus} from "../utils";
import {ISvcReq} from "../../../../api/Api";
import { ISvcReqFieldValues } from "../ClaimModal";

interface SvcReqDetailsProps {
  handleFieldChange: (e:React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  svcReq?: ISvcReq | null,
  svcReqFields: ISvcReqFieldValues,
  svcReqDisabled:boolean,
  dirtyFields: string[]
}

function SvcReqDetails({ handleFieldChange, svcReq, svcReqFields, svcReqDisabled, dirtyFields }: SvcReqDetailsProps) {
  let useFieldpoint = GlobalConfig.get(GlobalConfig.Key.WARRANTY_PROVIDER) === "Fieldpoint";
  const shazamInfoLine = "Please contact Shazam Customer Care with any questions.\n (303) 486-8900 customercare@shazamhomeservices.com";
  const theme = useTheme();
  const {
    ContEmail,
    ContName,
    ContPhone1,
  } = svcReqFields;
  let AssignedUserName;
  let DateClosed;
  let DateReceived;
  let PrefServiceDate;
  let ReceiverName;
  let Status;

  if (svcReq) {
    ({
      AssignedUserName,
      DateClosed,
      DateReceived,
      PrefServiceDate,
      ReceiverName,
      Status,
    } = svcReq);
  }

  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: '1fr',
        marginBottom: '1.5rem',
        padding: '0 1rem',
        rowGap: '1rem',
        [theme.breakpoints.up('md')]: {
          gridGap: '1.5rem',
          gridTemplateColumns: '1fr 1fr 1fr',
        },
      }}
    >
      <TextField
        disabled={svcReq && svcReqDisabled || useFieldpoint}
        label="Full Name:"
        onChange={handleFieldChange}
        name="ContName"
        required
        value={ContName}
        variant="outlined"
        error={dirtyFields.includes("ContName")}
        helperText={dirtyFields.includes("ContName") ? "Error occurred when saving this field" : ""}
      />
      <TextField
        disabled={svcReq && svcReqDisabled || useFieldpoint}
        label="Email:"
        name="ContEmail"
        onChange={handleFieldChange}
        required
        value={ContEmail}
        variant="outlined"
        error={dirtyFields.includes("ContEmail")}
        helperText={dirtyFields.includes("ContEmail") ? "Error occurred when saving this field" : ""}
      />
      <TextField
        disabled={svcReq && svcReqDisabled || useFieldpoint}
        label="Phone:"
        name="ContPhone1"
        onChange={handleFieldChange}
        required
        value={ContPhone1}
        variant="outlined"
        error={dirtyFields.includes("ContPhone1")}
        helperText={dirtyFields.includes("ContPhone1") ? "Error occurred when saving this field" : ""}
      />
      {svcReq && (
        <>
          <TextField
            aria-readonly
            label="Status:"
            value={useFieldpoint ? GetUserFriendlyStatusFromFieldpointStatus(Status) : Status}
            variant="standard"
            inputProps={
              { readOnly: true, }
            }
          />
          <TextField
            aria-readonly
            label="Received:"
            value={formatDate(assertNotNull(DateReceived))}
            variant="standard"
            inputProps={
              { readOnly: true, }
            }
          />
          <TextField
            aria-readonly
            multiline rows={useFieldpoint ? 2 : 1}
            label="Assigned Rep:"
            value={useFieldpoint ? shazamInfoLine : ReceiverName || '-'}
            variant="standard"
            inputProps={
              { readOnly: true, }
            }
          />
          <TextField
            aria-readonly
            label="Scheduled:"
            value={formatDate(assertNotNull(PrefServiceDate)) || '-'}
            variant="standard"
            inputProps={
              { readOnly: true, }
            }
          />
          <TextField
            aria-readonly
            label="Closed:"
            value={formatDate(assertNotNull(DateClosed)) || '-'}
            variant="standard"
            inputProps={
              { readOnly: true, }
            }
          />
          <TextField
            aria-readonly
            label="Assigned Tech:"
            value={AssignedUserName || '-'}
            variant="standard"
            inputProps={
              { readOnly: true, }
            }
          />
        </>
      )}
    </Box>
  );
}

SvcReqDetails.defaultProps = {
  svcReq: undefined,
};

export default SvcReqDetails;
