import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { ThemeContext } from '../../../context/ThemeContext';
import ClaimTicket from '../ClaimTicket/ClaimTicket';
import styles from './ClaimTracker.module.css';
import LoadingIndicator from '../../../shared/LoadingIndicator';
import GlobalConfig from '../../../utils/GlobalConfig';
import Dictionary from "../../../utils/Dictionary";
import {DictionaryDangerousTextAsHtml} from "../../Dictionary/DictionaryDangerousTextAsHtml";
import {DictionaryText} from "../../Dictionary/DictionaryText";
import {IsCustomerEditable} from "../../../utils/WarrantyUtil";


function GetSvcReqStatusDescriptions(useFieldpoint, theme) {

    const clientName = GlobalConfig.get(GlobalConfig.Key.CLIENT_NAME);

    let newDescript = '';
    let openDescript = '';
    let closedDescript = '';

    if (clientName === 'arbor') {
        newDescript = 'Your request has been received and is currently being reviewed by the Customer Care team.';
        openDescript = 'Your request is being reviewed for scheduling.';
        closedDescript = 'Your request was either completed or closed due to warrantability guidelines.';
    } else if (clientName === 'mungo') {
        newDescript = 'Your request has been received and is currently being reviewed by the Customer Care team.';
        openDescript = 'An appointment has been scheduled to review or address items on the request.';
        closedDescript = 'Your request was either completed or closed because it was not a warrantable request.';
    }

    const kovaStatusColors = [
        {color: theme.accentColorA, title: 'New', descript: newDescript},
        {color: theme.primaryMenuHighlightBg, title: 'Open', descript: openDescript},
        {color: '#606060', title: 'Closed', descript: closedDescript}
    ];

    const fieldpointStatusColors = [
        {color: theme.accentColor, title: 'New', descript: 'Your request has been received and is currently being reviewed by your Shazam team'},
        {color: theme.accentColorA, title: 'Open', descript: 'An appointment has been scheduled to review or address items on the request'},
        {color: theme.accentColorB, title: 'Closed', descript: 'Your request was either completed or closed because it was not a warrantable request'},
        {color: theme.accentColorC, title: 'On Hold', descript: 'Your request is on hold. Please contact a Shazam Customer Care coordinator if you have any questions regarding this status'}
    ];


    const statuses = useFieldpoint ? fieldpointStatusColors : kovaStatusColors;
    return statuses;
}

function ClaimTracker({
  homeInfo,
  setActiveSvcReq,
  svcReqs,
  toggleClaimModal,
  fieldpointUserExists,
  allowCreatingSvcReqsWhenNewAssignedExists
}) {
  let useFieldpoint = GlobalConfig.get(GlobalConfig.Key.WARRANTY_PROVIDER) === "Fieldpoint";
  const theme = useContext(ThemeContext);

  const listSvcReqs = (svcReqStatusDescriptions) => (
    svcReqs.map((req) => (
      <ClaimTicket
        key={req.ObjectRID}
        setActiveSvcReq={setActiveSvcReq}
        svcReq={req}
        toggleClaimModal={toggleClaimModal}
        svcReqStatusDescriptions={svcReqStatusDescriptions}
      />
    ))
  );

  const colorKey = () => {
      const statuses = GetSvcReqStatusDescriptions(useFieldpoint, theme);

      return (
      <>
        <div>
          <span className={styles.keyHeader}>Color Key:</span>
          <span>Description:</span>
        </div>
        {statuses.map((item) => (
          <div key={item.title} className={styles.keyItem}>
            <div className={styles.keyLeft}>
              <span
                className={styles.colorBox}
                style={{ backgroundColor: item.color }}
              />
              {item.title}
            </div>
            <span className={styles.keyRight}>
              {item.descript}
            </span>
          </div>
        ))}
      </>
    );
  };

  const renderRequestText = (onlyNewSvcReqIsUnderReview) => {

    let dictionaryKey;

    if(onlyNewSvcReqIsUnderReview){
        dictionaryKey = Dictionary.ClaimTracker_InstructionFooter_UnderReview;
    }
    else if(homeInfo.WtyStatus !== "Released" && homeInfo.WtyStatus !== "Warranty Expired"){
        dictionaryKey = Dictionary.ClaimTracker_InstructionFooter;
    }
    else if(homeInfo.WtyStatus === "Released"){
        dictionaryKey = Dictionary.ClaimTracker_InstructionFooter_Released;
    }

    return (
      <p>
        <span>
            {dictionaryKey &&
                <DictionaryDangerousTextAsHtml dictionary={Dictionary} dictionaryKey={dictionaryKey}/>
            }
        </span>
      </p>
    );
  };


  let submitButtonVisible = homeInfo.WtyStatus !== 'Warranty Expired' && homeInfo.WtyStatus !== 'Not Applicable' && (!useFieldpoint || fieldpointUserExists);
  let onlyNewSvcReqIsUnderReview = false;
  //need to check if there is new request under review and no other is editable
  if(!allowCreatingSvcReqsWhenNewAssignedExists && svcReqs){
      for(let svcReq of svcReqs){
          if(svcReq.Status === "New" && !IsCustomerEditable(svcReq.Status, svcReq.SvcReqCategSvcReqCategID, svcReq.ReceiverUserRID)){
              onlyNewSvcReqIsUnderReview = true;
          }
          else if(IsCustomerEditable(svcReq.Status, svcReq.SvcReqCategSvcReqCategID, svcReq.ReceiverUserRID)){
              onlyNewSvcReqIsUnderReview = false;
              break;
          }
      }
  }

  submitButtonVisible = submitButtonVisible && !onlyNewSvcReqIsUnderReview;


  return (
    <>
        <div className={styles.claimTracker}>
          <div
            className={styles.claimTrackerHeader}
            style={
              {
                backgroundColor: theme.titleBarBgColor,
                color: theme.titleBarFontColor,
                fontSize: theme.titleBarFontSize,
              }
}
          >
            {useFieldpoint ? "Service Tracker" : "Claim Tracker"}
          </div>
          {homeInfo.WtyStatus !== 'Not Applicable' && (!useFieldpoint || fieldpointUserExists) ? (
            (!svcReqs && (
              <div className={styles.claimTracker}>
                <span className={styles.loadingWrapper}>
                  <LoadingIndicator color={theme.accentColorA} />
                </span>
              </div>
            )) || (svcReqs && (
            <>
              <div className={styles.dataLabels}>
                <div className={styles.claimNum}>{useFieldpoint ? "Service Item #" : "Claim #"}</div>
                  {!useFieldpoint && <div className={styles.name}>Name</div>}
                <div className={styles.description}>Description</div>
                <div className={styles.date}>{useFieldpoint ? "Start Date" : "Date"}</div>
                <div className={styles.status}>Status</div>
              </div>
              <div className={styles.claimTicketWrapper}>
                {listSvcReqs(GetSvcReqStatusDescriptions(useFieldpoint,theme))}
              </div>
            </>
            ))
          ) : (
              <>
                  {(useFieldpoint && !fieldpointUserExists) ?
                      <div className={styles.featurePlaceholder}>
                          Once you've closed on your home, this section will become available to submit service requests
                      </div>
                      :
                      <div className={styles.featurePlaceholder}>
                        <DictionaryText dictionary={Dictionary} dictionaryKey={Dictionary.ClaimTracker_WtyNA} />
                      </div>
                  }
              </>
          )}
        </div>
      <div className={styles.legendWrapper}>
        {submitButtonVisible && (
          <button className={styles.submitClaimButton} type="submit" onClick={() => toggleClaimModal()} style={{ backgroundColor: theme.accentColorA }}>
            Request Service
          </button>
        )}
        { homeInfo.WtyStatus !== "Warranty Expired" && (
          renderRequestText(onlyNewSvcReqIsUnderReview)
        )}
        { homeInfo.WtyStatus === "Warranty Expired" && (
          <div style={{
            backgroundColor: theme.titleBarBgColor,
            color: '#fff',
            padding: '0.75rem',
            width:'100%'
            }}
          >
            <DictionaryDangerousTextAsHtml dictionary={Dictionary} dictionaryKey={Dictionary.ClaimTracker_InstructionFooter_Expired} />
          </div>
        )}
      </div>
      <div className={styles.colorKey}>
        {colorKey()}
      </div>
    </>
  );
}

ClaimTracker.propTypes = {
  svcReqs: PropTypes.array,
  toggleClaimModal: PropTypes.func.isRequired,
};

export default ClaimTracker;
