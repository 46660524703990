import {IToken} from "@bimaire/optionslib/build/api/customerportal-client";
import * as React from "react";
import GlobalConfig from "../../utils/GlobalConfig";
import { ThemeConsumer } from '../../context/ThemeContext';


export interface IDesignDashboardProps {
    kovaApiToken: IToken;
}
async function loginToDc(token: IToken, apiEndpoint: string, kovaApiEnv: string) {


    const resp = await fetch(apiEndpoint + `customer/loginToDc`, {
        credentials: "include",
        method: 'POST',
        headers: {
            "X-XSRF": token.csrfToken,
            'Content-Type': 'application/json',
        },
    });

    const respText = await resp.text();
    const url = respText;
    window.open(url, "_blank");


}
const DesignDashboard: React.FC<IDesignDashboardProps> = (props) => {

    const { kovaApiToken } = props;
    const apiEndpoint = GlobalConfig.get(GlobalConfig.Key.KOVA_API_URL);
    const kovaApiEnv = GlobalConfig.get(GlobalConfig.Key.KOVA_API_ENVIRONMENT);


    return <ThemeConsumer>{ (theme) => (<section style={{width: '100%', height: '100%', display: 'flex', flexDirection: 'column'}}>
            <p style={{textAlign: 'center', backgroundColor: theme.backgroundColor, borderBottom: '3px solid black'}}><a
                style={{fontSize: 'xx-large'}} href={'#'} onClick={() => loginToDc(kovaApiToken, apiEndpoint, kovaApiEnv)}>Click
                here to access Online Design Studio</a></p>
            <iframe style={{width: '100%', height: '100%', border: 0}} src={"https://www.bimaire.com/ods-home"}/>

        </section>) }
     </ThemeConsumer>

}

export default DesignDashboard;