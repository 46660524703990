import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import {
  Box,
  Button,
  CardHeader,
  MenuItem,
  Select,
} from '@mui/material';
import { Add as AddIcon } from '@mui/icons-material';
import {
  addNewSvcReqItem,
  calcCurrentSeqNumber,
  parseRIDFromLocationHeader,
} from './utils';
import Api, {
  IKovaApiToken,
  IObjAttImg,
  IOptLocation,
  ISvcReq, ISvcReqAttachment,
  ISvcReqItm,
  ISvcReqType, ISvcReqUpdateData, IPortalUser
} from '../../../api/Api';
import { ThemeContext } from '../../../context/ThemeContext';
import useInput from '../../../hooks/input';
import styles from './ClaimModal.module.css';
import alertConfig from '../../../config/alert';
import GlobalConfig from '../../../utils/GlobalConfig';
import LoadingIndicator from '../../../shared/LoadingIndicator';
import SvcReqDetails from './SvcReqDetails/SvcReqDetails';
import SvcReqItmList from './SvcReqItmList/SvcReqItmList';
import {IsCustomerEditable} from "../../../utils/WarrantyUtil";
import {AlertConfigCategory, HomeInfoObj} from "../../../types";

interface ISvcReqItemFormValue {
  existingImgs?:any;
  files:File[];
  location:string;
  description:string;
  seqNumber?:number;
  status?:string;
  svcReqItmRID?:number;
  key:string;
  dirty?:boolean
}

export interface ISvcReqFieldValues {
  ContEmail:string;
  ContName:string;
  ContPhone1:string;
}

interface IClaimModalProps {
  handleLogout: (sessionExpired:boolean, resetAlert?:boolean) => void;
  openAlert: (alert:AlertConfigCategory | null, resetAlert?:boolean) => void;
  homeInfo: HomeInfoObj;
  homeRID: number;
  loadingStatus:string;
  setLoadingStatus:(status:string) => void;
  user:IPortalUser;
  kovaApiToken:IKovaApiToken;
  activeSvcReq?: ISvcReq | null,
  getSvcReqs: () => void,

  optLocations: IOptLocation[],
  svcReqTypes: ISvcReqType[],
  toggleClaimModal: (svcReqRID?: number) => void,
}

function ClaimModal(props: IClaimModalProps) {
  const {
    activeSvcReq,
    getSvcReqs,
    handleLogout,
    homeInfo,
    homeRID,
    optLocations,
    svcReqTypes,
    toggleClaimModal,
    openAlert,
    setLoadingStatus,
    loadingStatus,
    user,
    kovaApiToken
  } = props;
  let useFieldpoint = GlobalConfig.get(GlobalConfig.Key.WARRANTY_PROVIDER) === "Fieldpoint";
  const theme = useContext(ThemeContext);
  const [svcReqItems, setSvcReqItems] = useState<{status:string,value?:ISvcReqItm[]}>({
    status: 'idle',
    value: undefined,
  });

  const [partiallyUpdateData, setPartiallyUpdatedData] = useState<ISvcReqUpdateData | undefined>(undefined);
  const [svcReqItmImgs, setSvcReqItmImgs] = useState<any[] | undefined>(undefined);
  const [svcReqDirtyFields, setSvcReqDirtyFields] = useState<string[]>([]);
  const [svcReqFields, setSvcReqFields] = useState<ISvcReqFieldValues>({
    ContEmail: activeSvcReq?.ContEmail || user.email,
    ContName: activeSvcReq?.ContName || user.lastName && `${user.firstName} ${user.lastName}` || user.firstName,
    ContPhone1: activeSvcReq?.ContPhone1 || user.phone,
  });
  const [svcReqItemFormValues, setSvcReqItemFormValues] = useState<ISvcReqItemFormValue[]>([]);
  const { value: svcReqCategID, changeHandler: handleSvcReqCategID } = useInput(activeSvcReq?.SvcReqCategSvcReqCategID || GlobalConfig.get(GlobalConfig.Key.SVCREQCATEG) || '');
  const serviceRequestOpts = svcReqTypes.map(({ Name, SvcReqCategID }) => ({
    text: Name,
    value: SvcReqCategID,
  }));
  serviceRequestOpts.unshift({ text: 'Service Request Type', value: '' });

  const getSvcReqItms = async () => {
    const items: ISvcReqItm[] = await Api.fetchSvcReqItms(homeRID, activeSvcReq!.SvcReqRID);

    return items;
  };

  const getSvcReqItmImgs = async (svcReqRID:number) => {
    let itemImgs:ISvcReqAttachment[] = [];

    try {
      itemImgs = await Api.fetchSvcReqItmImgs(svcReqRID);
    } catch (error) {
      if (error.response?.data === 'jwt expired') {
        handleLogout(true);
      } else {
        openAlert(alertConfig.error.getSvcReqItmImgs);
      }
    }

    return itemImgs;
  };

  const getWorkOrderImgs = async (incidentNumber:number) => {
    let itemImgs;

    try {
      itemImgs = await Api.fetchWorkOrderImgs(incidentNumber);
    } catch (error) {
      if (error.response?.data === 'jwt expired') {
        handleLogout(true);
      } else {
        openAlert(alertConfig.error.getSvcReqItmImgs);
      }
    }

    return itemImgs;
  };

  const initializeSvcReqItems = async (partialData: ISvcReqUpdateData | undefined) => {
    try {
      if (activeSvcReq) {

        //when using fieldpoint integration the SvcReqItm info is already populated and doesn't need to be fetched
        if(activeSvcReq.SvcReqItm){

          setSvcReqItems({ status: 'loading', value: undefined });
          const fieldPointImgs = await getWorkOrderImgs(activeSvcReq.SvcReqRID);

          setSvcReqItemFormValues(
          [{
            existingImgs: fieldPointImgs,
            files: [],
            location: "",
            description: activeSvcReq.SvcReqItm.Description,
            seqNumber: activeSvcReq.SvcReqItm.SeqNumber,
            status: activeSvcReq.SvcReqItm.Status,
            svcReqItmRID: activeSvcReq.SvcReqItm.SvcReqItmRID,
            key: uuidv4(),
          } as ISvcReqItemFormValue]);
          let items = [];
          items.push(activeSvcReq.SvcReqItm);
          setSvcReqItems({ status: 'idle', value: items });
          setSvcReqItmImgs([]);
          return;
        }

        setSvcReqItems({ status: 'loading', value: undefined });
        const items = await getSvcReqItms();
        let imgResults = await getSvcReqItmImgs(activeSvcReq.SvcReqRID);

        imgResults = imgResults.map((existingImg) => ({
          id: uuidv4(),
          ...existingImg,
        }));
        const formValueItems = items.map(({
          Description,
          Location,
          SeqNumber,
          Status,
          SvcReqItmRID,
        }) => {
          const existingImgs = imgResults.filter(img => (
            img.svcReqItmRID === SvcReqItmRID
          ));
          return ({
            existingImgs,
            files: [],
            location: Location,
            description: Description,
            seqNumber: SeqNumber,
            status: Status,
            svcReqItmRID: SvcReqItmRID,
            key: uuidv4(),
          } as ISvcReqItemFormValue);
        });

        //merge data so users won't lose input that wasn't saved successfully
        if(partialData){
          let svcReqs = await Api.fetchSvcReqs(homeRID, activeSvcReq!.CustomerRID);
          let svcReqFromServer = svcReqs.find((sv : any) => (sv.SvcReqRID == activeSvcReq!.SvcReqRID));

          let dirtyFields: string[] = [];
          // check SvcReq fields
          Object.entries(svcReqFields).forEach(([key, value]) => {
            if (svcReqFromServer![key as keyof ISvcReq] !== value) {
              dirtyFields.push(key);
            }
          });

          setSvcReqDirtyFields(dirtyFields);

          for(let itemUpdate of partialData.itemUpdates){
            let dataFromServer = formValueItems.find(d => d.svcReqItmRID == itemUpdate.svcReqItmRID);
            if(dataFromServer){
              if(dataFromServer.description !== itemUpdate.description || dataFromServer.location !== itemUpdate.location ){
                dataFromServer.files = itemUpdate.files;
                dataFromServer.dirty = true;
                dataFromServer.description = itemUpdate.description;
                dataFromServer.location = itemUpdate.location;
              }
            }
          }

          for(let itemAddition of partialData.itemAdditions){
            //seqnumber doesn't match any item on the server, assume the save failed and add this item again
            let dataFromServer = formValueItems.find(d => d.seqNumber == itemAddition.seqNumber);
            if(!dataFromServer){
              formValueItems.push({
                description: itemAddition.description,
                files: itemAddition.files,
                location: itemAddition.location,
                key: itemAddition.key,
                dirty:true
              });
            }
            else if(dataFromServer.existingImgs.length !== itemAddition.files.length){
              dataFromServer.files = itemAddition.files;
              dataFromServer.dirty = true;
            }
          }

        }

        setSvcReqItemFormValues(formValueItems);
        setSvcReqItems({ status: 'idle', value: items });
        setSvcReqItmImgs(imgResults);
      } else {
        setSvcReqItemFormValues([{
          files: [],
          location: '',
          description: '',
          key: uuidv4(),
        } as ISvcReqItemFormValue]);
      }
    } catch (error) {
      if (error.response?.data === 'jwt expired') {
        handleLogout(true);
      } else {
        openAlert(alertConfig.error.getSvcReqItms);
      }
      if (activeSvcReq) {
        setSvcReqItems({ status: 'error', value: undefined });
      }
    }
  };

  const removeExistingItemImg = (itemId:string, imgId:string) => {
    const nextSvcReqItems = [...svcReqItemFormValues];
    const index = nextSvcReqItems.findIndex((item) => item.key === itemId);
    nextSvcReqItems[index].existingImgs = nextSvcReqItems[index].existingImgs.filter((img:any) => (
      img.id !== imgId
    ));
    setSvcReqItemFormValues(nextSvcReqItems);
  };

  const removeFileUpload = (itemId:string, fileName:string) => {
    const nextSvcReqItems = [...svcReqItemFormValues];
    const index = nextSvcReqItems.findIndex((item) => item.key === itemId);
    nextSvcReqItems[index].files = nextSvcReqItems[index].files.filter((file) => (
      file.name !== fileName
    ));
    setSvcReqItemFormValues(nextSvcReqItems);
  };

  const removeSvcReqItm = (id:string) => {
    const nextSvcReqItms = svcReqItemFormValues.filter((itm) => itm.key !== id);
    setSvcReqItemFormValues(nextSvcReqItms);
  };

  const updateSvcReqItm = (key:string, event: React.ChangeEvent<HTMLInputElement>) => {
    const { files, name, value } = event.target;
    const nextSvcReqItems = [...svcReqItemFormValues];
    const index = nextSvcReqItems.findIndex((item) => item.key === key);

    const formValueKey = name as keyof ISvcReqItemFormValue;
    if (formValueKey === "files") {
      if (files) {
        const currentFiles = nextSvcReqItems[index].files;
        const savedImageFilesCount = nextSvcReqItems[index].existingImgs?.length || 0;

        if (files.length + currentFiles.length + savedImageFilesCount > 5) {
          openAlert(alertConfig.error.uploadImagesTooManyFiles);
          event.target.value = ""; //set value to "" to trigger the same error message is the same file is uploaded again
          return;
        }

        if (!Array.from(files)
          .every((file) => file.size < 5000000)) {
          openAlert(alertConfig.error.uploadImagesTooLargeFiles);
          event.target.value = ""; //set value to "" to trigger the same error message is the same file is uploaded again
          return;
        }

        nextSvcReqItems[index].files = [...currentFiles, ...Array.from(files)];
      }
    } else {
      nextSvcReqItems[index][formValueKey] = value;
    }
    setSvcReqItemFormValues(nextSvcReqItems);
  };

  const generateUpdateReqData = () => {
    const requestData = {
      itemAdditions: [],
      itemDeletions: [],
      itemUpdates: [],
      imgDeletions: [],
      imgUploadMap: [],
      svcReqFields: [],
    } as ISvcReqUpdateData;
    const imgUploadFiles: File[] = [];
    const currentSeqNumber = calcCurrentSeqNumber(svcReqItemFormValues);

    // check SvcReq fields
    if(activeSvcReq) {
      Object.entries(svcReqFields).forEach(([key, value]) => {
        if (activeSvcReq![key as keyof ISvcReq] !== value) {
          requestData.svcReqFields.push({key, value});
        }
      });
    }

    // find additions
    const newItems = svcReqItemFormValues.filter(({ svcReqItmRID }) => !svcReqItmRID);
    newItems.forEach((item, index) => {
      requestData.itemAdditions.push({
        name: item.description.substring(0, 40),
        seqNumber: currentSeqNumber + index + 1,
        ...item,
      });
    });

    svcReqItemFormValues.forEach((item) => {
      // find updates
      const match = svcReqItems.value?.find(({ SvcReqItmRID }) => (
        SvcReqItmRID === item.svcReqItmRID
      ));
      if (
        (match && match?.Description !== item.description)
        || (match && match?.Location !== item.location)) {
        requestData.itemUpdates.push({
          name: item.description.substring(0, 40),
          ...item,
        });
      }

      // find img additions
      if (item.files) {
        item.files.forEach((file) => {
          requestData.imgUploadMap.push({
            name: file.name,
            size: file.size,
            svcReqItmRID: item.svcReqItmRID,
            key: item.key,
          });
        });
        imgUploadFiles.push(...item.files);
      }

      // find img deletions
      if (item.existingImgs) {
        const prevExistingImgs = svcReqItmImgs?.filter(({ svcReqItmRID }) => (
          svcReqItmRID === item.svcReqItmRID
        ));
        prevExistingImgs?.forEach(({ objAttImgRID, svcReqItmRID }) => {
          const imgMatch = item.existingImgs.find((img:any) => img.objAttImgRID === objAttImgRID);
          if (!imgMatch) {
            requestData.imgDeletions.push({ objAttImgRID, svcReqItmRID });
          }
        });
      }
    });

    // find deletions
    svcReqItems.value?.forEach((item) => {
      const match = svcReqItemFormValues.find(({ svcReqItmRID }) => (
        svcReqItmRID === item.SvcReqItmRID
      ));
      if (!match) {
        const objAttImgRIDs = svcReqItmImgs?.filter(({ svcReqItmRID }) => (
          svcReqItmRID === item.SvcReqItmRID
        ))
          .map(({ objAttImgRID }) => objAttImgRID);
        requestData.itemDeletions.push({
          objAttImgRIDs,
          svcReqItmRID: item.SvcReqItmRID,
        });
      }
    });

    return { imgUploadFiles, requestData };
  };

  async function handleSubmitUpdate(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();

    setLoadingStatus('Saving Service Request');
    // When more clients are added to the alertConfig, a switch statement may be easier/cleaner
    const config = (GlobalConfig.get(GlobalConfig.Key.CLIENT_NAME) === 'mungo'
      ? { ...alertConfig.submitSvcReqMungo }
      : { ...alertConfig.submitSvcReqDefault }) as AlertConfigCategory;
    config.loadingIndicator = true;

    openAlert(config, true);

    const { imgUploadFiles, requestData } = generateUpdateReqData();

    let doToggle = true;
    try {
      await Api.updateSvcReq(
        requestData,
        imgUploadFiles,
        homeRID,
        activeSvcReq!.SvcReqRID,
        kovaApiToken
      );
    } catch (error) {
      setLoadingStatus('');
      if (error.response?.data === 'jwt expired') {
        handleLogout(true);
      } else if (error.response?.status === 409) {
        if(error.response?.data === 'Request processing started'){
          await openAlert(alertConfig.error.updateSvcReq.BeingProcessed, true);
          //some error occurred, don't reload the SvcReq, allow user to copy their comments and try again in a new request
          doToggle = false;
        }
        else {
          await openAlert(alertConfig.error.updateSvcReq[409], true);
        }
      } else {
          await openAlert(alertConfig.error.updateSvcReq.default, true);
          doToggle = false;
          //some error occurred, reload the SvcReq but keep it open
          await initializeSvcReqItems(requestData);
      }
    }

    try {

      if(doToggle) {
        await getSvcReqs();
        toggleClaimModal();
      }
      setLoadingStatus('');
      openAlert(null);
    } catch (error) {
      setLoadingStatus('');
      toggleClaimModal();
      if (error.response?.data === 'jwt expired') {
        handleLogout(true);
      } else {
        openAlert(alertConfig.error.getSvcReqs);
      }
    }
  }

  async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();

    setLoadingStatus('Saving Service Request');
    // When more clients are added to the alertConfig, a switch statement may be easier/cleaner
    const config = (GlobalConfig.get(GlobalConfig.Key.CLIENT_NAME) === 'mungo'
      ? { ...alertConfig.submitSvcReqMungo }
      : { ...alertConfig.submitSvcReqDefault }) as AlertConfigCategory;
    config.loadingIndicator = true;

    openAlert(config as AlertConfigCategory, true);

    let svcReqRID;

    try {

      if (useFieldpoint) {
        const svcReq = {
          CustomerRID: homeInfo.CustomerRID,
          ContEmail: svcReqFields.ContEmail,
          ContName: svcReqFields.ContName,
          ContPhone1: svcReqFields.ContPhone1,
          SvcReqRID: activeSvcReq ? activeSvcReq.SvcReqRID : 0
        } as ISvcReq;

        const svcReqItmValues = svcReqItemFormValues[0];
        let svcReqItm = {
          svcReqRID: svcReq.SvcReqRID,
          svcReqItmRID: svcReqItmValues?.svcReqItmRID || 0,
          description: svcReqItmValues.description,
          location: svcReqItmValues.location,
          seqNumber: svcReqItmValues?.seqNumber || 1,
        } as any;
        const response = await Api.saveWorkorder(svcReq, svcReqItm, kovaApiToken);
        let incidentNumber = response.data.IncidentNumber;

        if (svcReqItemFormValues[0].files && svcReqItemFormValues[0].files.length > 0) {
          await Api.uploadWorkorderAttachments(homeInfo.CustomerRID, svcReqItmValues.files, incidentNumber, kovaApiToken);
        }

        await getSvcReqs();
        toggleClaimModal();
        setLoadingStatus('');
        openAlert(null);
      }
      else {

        const svcReqCateg = svcReqTypes.find((x) => x.SvcReqCategID.toLowerCase() === svcReqCategID.toLowerCase());
        const svcReq = {
          HomeRID:homeRID,
          Name: `${svcReqCateg!.Name}, ${homeInfo.LotStreetAddress}, ${homeInfo.CName}`,
          SvcReqCategRID: svcReqCateg!.SvcReqCategRID,
          CustomerRID: homeInfo.CustomerRID,
          CommunityRID: homeInfo.CommunityRID,
          ContEmail: svcReqFields.ContEmail,
          ContName: svcReqFields.ContName,
          ContPhone1: svcReqFields.ContPhone1,
          LotRID: homeInfo.LotRID,
        } as ISvcReq;

        const response = await Api.saveSvcReq(svcReq, kovaApiToken);
        svcReqRID = response.data.ObjectRID;
        if (!svcReqRID) {
          throw new Error('Unable to get SvcReqRID from response');
        }
      }
    } catch (error) {
      setLoadingStatus('');
      if (error.response?.data === 'jwt expired') {
        handleLogout(true);
      } else {
        openAlert(alertConfig.error.saveSvcReq);
      }
    }

    if(svcReqRID) {
      try {
        const items = svcReqItemFormValues.filter(({description, location}) => (
            description && location
        ));
        if (items.length > 0) {
          for (let i = 0; i < items.length; i++) {
            const item = items[i];
            const {description, location} = item;
            const response = await Api.saveSvcReqItem(
                homeRID,
                {
                  SvcReqRID:svcReqRID,
                  Description:description,
                  Location:location,
                  SeqNumber: i + 1,
                } as ISvcReqItm,
                kovaApiToken,
            );
            const svcReqItmRID = response.data.ObjectRID
            if (!svcReqItmRID) {
              throw new Error('Unable to parse SvcReqItmRID from location header');
            }
            if (item.files && item.files.length > 0) {
              await Api.uploadSvcReqImgs(item.files, svcReqItmRID, kovaApiToken);
            }
          }
        }
        await getSvcReqs();
        toggleClaimModal();
        setLoadingStatus('');
        openAlert(null);
      } catch (error) {
        setLoadingStatus('');
        if (error.response?.data === 'jwt expired') {
          handleLogout(true);
        } else {
          await openAlert(alertConfig.error.saveSvcReqItms, true);
          //saving of the SvcReq probably worked, so we can toggle to it

          let updateData = generateUpdateReqData();
          setPartiallyUpdatedData(updateData.requestData);
          toggleClaimModal(svcReqRID);
        }
      }
    }
  }

  const renderTextA = (clientName:string) => {
    switch (clientName) {
      case 'arbor':
        return 'If your request is an emergency (broken pipe causing flood, gas line leak, etc.), please call 1-800-673-0564.  Otherwise, our team reviews all requests in the order they come in. Our team will review the request and someone will reach out to you in the next 2 business days to discuss and/or schedule an appointment at your home.';
      case 'mungo':
        return (
          <>
            {homeInfo.WtyStatus === 'Warranty Expired' ? (
              <div>
                Your home is outside of the one-year builder’s warranty.  Please
                visit&nbsp;
                <a
                  href="https://www.qbwc.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://www.qbwc.com/
                </a>
                &nbsp;and click on My Coverage to review your remaining coverage
                for years 2 through 10.  For questions or concerns, please
                contact Customer Care 803-749-5888 ext. 354 or&nbsp;
                <a
                  href="mailto: customerservice@mungo.com"
                >
                  customerservice@mungo.com
                </a>
              </div>
            ) : (
              <div>
                If any of your request items have the potential
                to cause property damage, please contact us for immediate
                assistance 803-749-5888 ext. 354 (Monday-Thursday from
                8:00am-5:00pm and Friday from 8:00am-12:00pm).
                If outside of normal business hours, please refer to the SOS
                sticker on your breaker panel for assistance with plumbing,
                electrical, and/or heating and air.
              </div>
            )}
          </>
        );
      default:
        return <></>;
    }
  };

  const renderTextB = (clientName:string) => {
    switch (clientName) {
      case 'arbor':
        return (
          <div>
            Please list warrantable request items requiring service. You can see
            a list of items covered under warranty
            &nbsp;
            <a
              href={GlobalConfig.get(GlobalConfig.Key.WARRANTY_LINK)}
              target="_blank"
              rel="noopener noreferrer"
            >
              here
            </a>
            .
          </div>
        );
      case 'mungo':
        return (
          <div>
            Please list warrantable request items requiring service. Warranty
            request items are located on pages 6-14 of the
            &nbsp;
            <a
              href={GlobalConfig.get(GlobalConfig.Key.WARRANTY_LINK)}
              target="_blank"
              rel="noopener noreferrer"
            >
              QBW Limited Warranty Agreement
            </a>
            .
          </div>
        );
      default:
        return <></>;
    }
  };

  const isSvcReqEditable = () => {
    if (!activeSvcReq) {
      return true;
    }

    return IsCustomerEditable(activeSvcReq.Status, svcReqCategID, activeSvcReq.ReceiverUserRID);
  }

  const isSubmitUpdatesDisabled = (requireValidItemAdditions:boolean) => {
    if (svcReqItems.value === undefined || svcReqItmImgs === undefined && !useFieldpoint) {
      return true;
    }
    if(useFieldpoint && activeSvcReq!.SvcReqRID > 0 ||
      !useFieldpoint && !isSvcReqEditable())
    {
      return true;
    }

    let disabled = true;
    const { imgUploadFiles, requestData } = generateUpdateReqData();

    Object.entries(requestData).forEach(([key, value]) => {
      if (key === 'itemAdditions' && requireValidItemAdditions) {
        disabled = value.every(({ description, location }:any) => !description || !location);
        return;
      }
      if (value.length > 0) {
        disabled = false;
      }
    });

    if (imgUploadFiles.length > 0) {
      disabled = false;
    }

    return disabled;
  };

  const isSubmitDisabled = () => {
    let disabled = false;

    if (loadingStatus === 'Saving Service Request' || svcReqItemFormValues.length === 0) {
      disabled = true;
    }
    if (svcReqItemFormValues.find(({ description, location }) => !description || !location && !useFieldpoint)) {
      disabled = true;
    }

    Object.values(svcReqFields).forEach((value) => {
      if (!value) {
        disabled = true;
      }
    });

    return disabled;
  };

  const handleClose = () => {
    toggleClaimModal();
  };

  const renderTitle = () => {
    if (activeSvcReq) {
      return `Service Request #${activeSvcReq.SvcReqID}`;
    }

    return homeInfo.WtyStatus === 'Warranty Expired'
      ? "Out of Builder's Warranty"
      : 'Submit a Warranty Request';
  };

  const handleSvcReqFieldChange = (e:React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const { name, value } = e.target;
    const newFieldValues = { ...svcReqFields } as ISvcReqFieldValues;
    newFieldValues[name as keyof ISvcReqFieldValues] = value;
    setSvcReqFields(newFieldValues);
  };

  useEffect(() => {
    initializeSvcReqItems(partiallyUpdateData);
  }, [activeSvcReq]);

  const showSubmit = (activeSvcReq && !isSubmitUpdatesDisabled(true) || !activeSvcReq && !isSubmitDisabled());
  //show cancel changes when  any valid change have been made, user's creating new SvcReq or any new items have been added (even when info is still missing)
  const exitText = showSubmit || !activeSvcReq || !isSubmitUpdatesDisabled(false) ? "CANCEL CHANGES" : "EXIT";
  const submitText = activeSvcReq ? "SUBMIT UPDATES" : "SUBMIT REQUEST";

  const svcReqDisabled = !isSvcReqEditable();

  return (
    <div className={styles.claimModal}>
      <CardHeader
        sx={{
          backgroundColor: theme.titleBarBgColor,
          color: '#fff',
          marginBottom: '1.5rem',
        }}
        subheader={renderTextA(GlobalConfig.get(GlobalConfig.Key.CLIENT_NAME))}
        subheaderTypographyProps={{
          sx: { color: '#E0E0E0' },
        }}
        title={renderTitle()}
        titleTypographyProps={{
          variant: 'h6',
        }}
      />
      {(activeSvcReq || homeInfo.WtyStatus !== 'Warranty Expired') && (
          <SvcReqDetails
              handleFieldChange={(e) => handleSvcReqFieldChange(e)}
              svcReq={activeSvcReq}
              svcReqFields={svcReqFields}
              svcReqDisabled={svcReqDisabled}
              dirtyFields={svcReqDirtyFields}
          />
      )
      }
      {(activeSvcReq || homeInfo.WtyStatus !== 'Warranty Expired') &&
          <form onSubmit={activeSvcReq && !useFieldpoint ? handleSubmitUpdate : handleSubmit}>
            <>
              <Box sx={{marginBottom: '1rem'}}>
                {renderTextB(GlobalConfig.get(GlobalConfig.Key.CLIENT_NAME))}
              </Box>
              {!GlobalConfig.get(GlobalConfig.Key.SVCREQCATEG) && (
                  <>
                    <Select
                        disabled={activeSvcReq !== undefined}
                        displayEmpty
                        onChange={handleSvcReqCategID}
                        required
                        sx={{
                          backgroundColor: '#fff',
                          display: 'block',
                          marginBottom: '1rem',
                          textAlign: 'left',
                        }}
                        value={svcReqCategID}
                        variant="outlined"
                    >
                      {serviceRequestOpts.map(({text, value}) => (
                          <MenuItem key={`${text}${value}`} value={value}>{text}</MenuItem>
                      ))}
                    </Select>
                  </>
              )}
              <div className={styles.divider}/>
              {svcReqItems.status === 'loading' ? (
                  <LoadingIndicator color={theme.accentColorA}/>
              ) : (
                  <>
                    {((!activeSvcReq) || (activeSvcReq && svcReqItems.value)) && (
                        <SvcReqItmList
                            optLocations={optLocations}
                            removeExistingItmImg={removeExistingItemImg}
                            removeFileUpload={removeFileUpload}
                            removeSvcReqItm={removeSvcReqItm}
                            svcReqItms={svcReqItemFormValues}
                            updateSvcReqItm={updateSvcReqItm}
                            svcReqDisabled={svcReqDisabled}
                        />
                    )}
                  </>
              )}
              {!(activeSvcReq && svcReqDisabled) && !useFieldpoint &&
                  <>
                    <Button
                        onClick={() => addNewSvcReqItem(
                            svcReqItemFormValues,
                            setSvcReqItemFormValues,
                        )}
                        startIcon={<AddIcon/>}
                        sx={{borderColor: theme.titleBarBgColor, color: theme.titleBarBgColor}}
                        variant="outlined"
                    >
                      Add Another Warranty Request Item
                    </Button>
                    <div className={styles.divider}/>
                  </>
              }
              <Box sx={{display: 'flex'}}>
                {showSubmit &&
                    <Button
                        sx={{
                          backgroundColor: theme.accentColorA,
                          flexGrow: 1,
                          marginRight: '1rem',
                        }}
                        type="submit"
                        variant="contained"
                    >
                      {submitText}
                    </Button>
                }
                <Button
                    onClick={handleClose}
                    sx={{
                      borderColor: theme.accentColorA,
                      color: theme.accentColorA,
                      flexGrow: 1,
                    }}
                    variant="outlined"
                >
                  {exitText}
                </Button>
              </Box>
            </>
          </form>
      }
    </div>
  );
}

export default ClaimModal;
