import React, {useEffect, useState} from 'react';
import Api from '../../api/Api';
import alertConfig from '../../config/alert';
import DocContainer from '../../shared/DocContainer/DocContainer';
import styles from './Documents.module.css';
import { HomeInfoObj, AlertConfigCategory } from '../../types';
import LoadingIndicator from '../../shared/LoadingIndicator';
import { ThemeConsumer } from '../../context/ThemeContext';
import {assertNotNull} from "../../assertNotNull";
import Dictionary from "../../utils/Dictionary";
import {Grid} from "@mui/material";

interface DocumentsProps {
  handleLogout: (sessionExpired:boolean, resetAlert?:boolean) => void;
  homeInfo: HomeInfoObj;
  homeRID: number;
  openAlert: (alert:AlertConfigCategory, resetAlert?:boolean) => void;
  slsOrdRID: number;
}

export default function Documents(props: DocumentsProps) {
  const {slsOrdRID, homeRID, homeInfo, openAlert, handleLogout} = props;
  const [homeDocs, setHomeDocs] = useState<any[] | null>();
  const [communityDocs, setCommunityDocs] = useState<any[] | null>();
  const [closingDocs, setClosingDocs] = useState<any[] | null>();

  useEffect(() => {
    Api.fetchHomeDocuments(homeRID).then(docs => {
      setHomeDocs(docs);
    }).catch(err => {
      setHomeDocs(null);

      if (err.response) {
        const tokenExpired = err.response.data === 'jwt expired';
        if (tokenExpired) {
          handleLogout(true);
        } else {
          console.log(err);
          openAlert(alertConfig.error.documentsHome, true);
        }
      } else {
        console.log(err);
        openAlert(alertConfig.error.documentsHome, true);
      }
    });
  }, [homeRID]);


  useEffect(() => {
    Api.fetchSlsOrdDocs(slsOrdRID, homeInfo.CustomerRID).then(docs => {
      setClosingDocs(docs);
    }).catch(err => {
      setClosingDocs(null);

      if (err.response) {
        const tokenExpired = err.response.data === 'jwt expired';
        if (tokenExpired) {
          handleLogout(true);
        } else {
          console.log(err);
          openAlert(alertConfig.error.documentsClosing, true);
        }
      } else {
        console.log(err);
        openAlert(alertConfig.error.documentsClosing, true);
      }
    });
  }, [slsOrdRID]);

  useEffect(() => {
    Api.fetchCustPortalCommunityDocuments("CustPortal-Community", homeInfo.CommunityRID).then(docs => {
      setCommunityDocs(docs);
    }).catch(err => {
      let tokenExpired;
      let dirNotFound;
      if (err.response) {
        tokenExpired = err.response.data === 'jwt expired';
        dirNotFound = err.response.status === 404;
      }
      if (tokenExpired) {
        handleLogout(true);
      }
      /**
       * Fail silently on fetchCustPortalDocuments 404 errors for
       * Cust-Portal-Community dirTypes only - closed communities cannot
       * have new directories added in Kova.
       */
      if (!dirNotFound) {
        openAlert(alertConfig.error.documentsCommunity, true);
      } else {
        setCommunityDocs([]);
      }
    })
  }, [homeInfo?.CommunityRID]);

  const docsFetched = homeDocs !== undefined && closingDocs !== undefined && communityDocs !== undefined;

  const containerData = [
    { docs: communityDocs, heading: Dictionary.getText(Dictionary.Documents_CommunityDocuments) },
    { docs: homeDocs, heading: Dictionary.getText(Dictionary.Documents_HomeDocuments) },
    { docs: closingDocs, heading: Dictionary.getText(Dictionary.Documents_ClosingDocuments) },
  ];

  const filteredData = containerData
    .filter(container => {
      return container.docs && container.docs.length > 0
    });

  const docLayoutNumber = (12 / Math.max(1, filteredData.length));
  return (
    <ThemeConsumer>
      {(theme) => (
        <div className={styles.Documents}>
          {!docsFetched && (
            <span className={styles.loadingWrapper}>
                <LoadingIndicator color={theme.accentColorA} />
              </span>
          )}
          {docsFetched && (
            <Grid container>
              { filteredData.length == 0 && (
                <Grid item xs={12}>
                  <DocContainer
                    key={"nodocuments"}
                    docs={[]}
                    heading={"Documents"}
                    lg={12}
                  />
                </Grid>
              )}
              { filteredData.length > 0 && filteredData.map((container) => (
                <Grid item xs={12} md={docLayoutNumber} padding={1} key={container.heading}>
                  <DocContainer
                    docs={assertNotNull(container.docs)}
                    heading={container.heading}
                    lg={docLayoutNumber == 12 ? 3 : 4}
                  />
                </Grid>
              ))}
            </Grid>
          )}
        </div>
      )}
    </ThemeConsumer>
  );
}