import React from 'react';
import PropTypes from 'prop-types';
import styles from './CustomPage.module.css';

const CustomPage = ({ link }) => (
  <div className={styles.CustomPage}>
    <iframe src={link.url} title="contact" />
  </div>
);

CustomPage.propTypes = {
  link: PropTypes.object.isRequired
};

export default CustomPage;
