import CustomPage from '../../shared/CustomPage';
import {allRoutes} from '../../routes';
import routeConfig from '../../config/routes.json';
import GlobalConfig from "../../utils/GlobalConfig";

function validatePredicate(predicate, kovaApiToken) {

  if (predicate === "finishes-enabled") {
    if (kovaApiToken && kovaApiToken.hasDcAccess) {
      return true;
    } else {
      return false;
    }
  }

  return true;
}
function validateRoute(clientRoutes, route, kovaApiToken) {

  const configRoute = clientRoutes.find(cr => cr.linkText === route.linkText);
  if (configRoute === undefined) return false;

  if (configRoute.predicate !== undefined) {
    return validatePredicate(configRoute.predicate, kovaApiToken);
  }
  return true;
}
export function generateEnabledRoutes(links, location, prelimCustomers, kovaApiToken, homeInfo) {
  let enabledRoutes = null;
  const clientRoutes = routeConfig[GlobalConfig.get(GlobalConfig.Key.CLIENT_NAME)];
  if (location === 'selectOnly') {
    enabledRoutes = allRoutes.filter((route) =>
      route.linkText === 'log out' || route.linkText === 'select home' || route.linkText == "proxylogin");
  } else {
    enabledRoutes = allRoutes.filter((route) => {
      if(route.linkText == "proxylogin"){
        return true;
      }
      if (prelimCustomers && prelimCustomers.length > 1) {
        return validateRoute(clientRoutes, route, kovaApiToken);
      } else {
        return (validateRoute(clientRoutes, route, kovaApiToken) && route.linkText !== 'select home');
        }
      });
  }
  if (GlobalConfig.get(GlobalConfig.Key.KOVA_API_URL)) {
    if (links) {
      Object.values(links).forEach((link) =>
        link.enabled && (link.communityRID === 0 || !homeInfo || link.communityRID === homeInfo.CommunityRID) && enabledRoutes.push({
          path: `/${link.text.replace(' ', '-')}`,
          component: CustomPage,
          link,
          linkText: link.text,
          routeType: 'primaryMenu'
        }));
    }
  }

  const defaultLinkText = routeConfig[GlobalConfig.get(GlobalConfig.Key.CLIENT_NAME)]
    .find((route) => route.isDefaultRoute).linkText;

  const defaultRoute = enabledRoutes.find((route) => route.linkText === defaultLinkText);

  if (defaultRoute) {
    defaultRoute.isDefaultRoute = true;
  }

  return enabledRoutes;
}

export function getEnabledRouteNames(){
  const clientRoutes = routeConfig[GlobalConfig.get(GlobalConfig.Key.CLIENT_NAME)];
  return clientRoutes.map(route => route.linkText);
}
export function setPageTitle() {
  document.title = GlobalConfig.get(GlobalConfig.Key.BRANDING_NAME);
}

export function capitalize(input) {
  input = input.slice(0,1).toUpperCase() + input.slice(1, input.length)
  return input;
}
