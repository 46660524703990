import React from 'react';
import PropTypes from 'prop-types';
import { ThemeConsumer } from '../../../context/ThemeContext';
import styles from './Node.module.css';

const Node = ({
  status,
  step,
  milestoneClickHandler,
  selectedMilestone,
  current,
}) => {
  const getDescription = () => {
    let newDescription = step.portalActName ? step.portalActName : step.Description;
    newDescription = newDescription.replace(/\^/g, '');
    return newDescription;
  };
  const isMilestoneSelected = selectedMilestone?.ActRID === step.ActRID;

  return (
    <ThemeConsumer>
      {theme => (
        <button
          type="button"
          className={`${styles.node} ${current ? styles.current : ''}`}
          onClick={() => milestoneClickHandler(step)}
        >
          <div className={styles.wrapper}>
            <div
              className={`${styles.nodeBody} ${isMilestoneSelected ? styles.nodeSelected : ''}`}
              style={{ backgroundColor: theme.node[status] }}
            >
              <div>{getDescription()}</div>
            </div>
            <div className={styles.stepNumber}>{`Step ${step.Number}`}</div>
          </div>
        </button>
      )}
    </ThemeConsumer>
  );
};

Node.defaultProps = {
  selectedMilestone: null,
};

Node.propTypes = {
  current: PropTypes.bool.isRequired,
  status: PropTypes.string.isRequired,
  step: PropTypes.object.isRequired,
  milestoneClickHandler: PropTypes.func.isRequired,
  selectedMilestone: PropTypes.object
};

export default Node;
