import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as Moment from 'moment';
import { extendMoment } from 'moment-range';
import styles from './Notifications.module.css';
import NotifyItem from './NotifyItem/NotifyItem';
import Api from '../../api/Api';
import { ThemeConsumer } from '../../context/ThemeContext';
import GlobalConfig from '../../utils/GlobalConfig';

const moment = extendMoment(Moment);

class Notifications extends Component {
  constructor(props) {
    super(props);

    this.state = {
      timelineArr: null,
      startMilestoneArr: null,
      completeMilestoneArr: null,
      photoArr: null,
      documentArr: null,
      notifyArray: null,
    };
  }

  componentDidMount() {
    this.getData();
  }

  getData = async () => {
    const { handleLogout } = this.props;

    try {
      await this.documentFeeder();
      await this.photoFeeder();
      await this.timelineFeeder();
      await this.startedMilestoneFeeder();
      await this.completedMilestoneFeeder();
      await this.mainArray();
    } catch (err) {
      if (err.response) {
        const tokenExpired = err.response.data === 'jwt expired';
        if (tokenExpired) {
          handleLogout(true);
        } else {
          console.error(err);
        }
      } else {
        console.error(err);
      }
    }
  };

  /**
   * Timeline based on each Activities start and finish per day
   */
  timelineFeeder = () => {
    const { hideFutureActivities, activities } = this.props;


    // Filter out non-notification activities and non-approved
    const isNotification = activities.filter(
      (act) => (act.isNotification || !GlobalConfig.get(GlobalConfig.Key.ACTIVITY_MODULE_FILTER)) &&
          (!hideFutureActivities || act.Status == "Approved")
    );

    const currentDate = moment().format('YYYY-MM-DD');
    let finishDates = isNotification.map((activity) => {
      return activity.FinishDateCur;
    })
      // Filter out dates > current date
      .filter((date) => moment(date).diff(currentDate, 'days') <= 0)
      .map((date) => moment(date).format('MM/DD/YYYY'))
      .sort((a, b) => new Date(b) - new Date(a));
    // Convert to Set to remove duplicate values, then convert back to array
    finishDates = [...new Set(finishDates)];

    const timelineNotifs = finishDates.map((date) => {
      const notif = {};
      const activities = isNotification.filter((activity) => {
        const finishDate = moment(activity.FinishDateCur).format('MM/DD/YYYY');
        return finishDate === date;
      });
      const activityNames = activities.map((activity) => activity.portalActName ? activity.portalActName : activity.Description);

      notif.date = date;
      notif.icon = 'bell';
      notif.list = activityNames;

      return notif;
    });

    this.setState({ timelineArr: timelineNotifs });
  };

  /**
   * Milestone based on first Activity per stage per day
   */
  startedMilestoneFeeder = () => {
    const { activities, pushOut, hideFutureActivities } = this.props;

    const currentDate = moment().format('YYYY-MM-DD');

    if (GlobalConfig.get(GlobalConfig.Key.TRACKER_DATA) === 'activities') {
      const startMilestoneArr = [];
      const milestones = GlobalConfig.get(GlobalConfig.Key.ACTIVITY_MODULE_FILTER)
        ? [...activities].filter((activity) => activity.isTracker)
        : [...activities];

      milestones.forEach((milestone) => {
        const date = moment(milestone.StartDateCur).format('MM/DD/YYYY');
        const diff = moment(date).diff(currentDate, 'days');

        if (diff <= 0 && (!hideFutureActivities || milestone.Status == "Approved")) {
          const startedMilestone = {
            date,
            icon: 'calendar',
            message: 'Milestone Started : ',
            stage: milestone.portalActName ? milestone.portalActName : milestone.Description,
            time: milestone.StartDateCur,
          };
          startMilestoneArr.push(startedMilestone);
        }
      });
      this.setState({ startMilestoneArr });
    }
  };

  /**
 * Milestone Completed array done
 */
  completedMilestoneFeeder = () => {
    const { activities } = this.props;


    if (GlobalConfig.get(GlobalConfig.Key.TRACKER_DATA) === 'activities') {
      const completeMilestoneArr = [];
      const milestones = GlobalConfig.get(GlobalConfig.Key.ACTIVITY_MODULE_FILTER)
        ? [...activities].filter((activity) => activity.isTracker)
        : [...activities];

      milestones.forEach((milestone) => {
        const date = moment(milestone.FinishDateCur).format('MM/DD/YYYY');

        if (milestone.Status.toUpperCase() === 'APPROVED') {
          const completedMilestone = {
            date,
            icon: 'calendar',
            message: 'Milestone Completed : ',
            stage: milestone.portalActName ? milestone.portalActName : milestone.Description,
            time: milestone.FinishDateCur,
          };
          completeMilestoneArr.push(completedMilestone);
        }
      });
      this.setState({ completeMilestoneArr });
    }
  };

  /**
   * Photos based off on Creation Date per day
   */
  photoFeeder = async () => {
    const { homeRID, activities } = this.props;
    const currentDate = moment().format('YYYY-MM-DD');

    let milestones = GlobalConfig.get(GlobalConfig.Key.ACTIVITY_MODULE_FILTER)
          ? [...activities].filter((activity) => activity.isTracker)
          : [...activities];

    const stageKeys = milestones.map(milestone => milestone.Description);
    const photos = await Api.fetchStagePhotos(homeRID, stageKeys)

    const flattenPhotos = photos.reduce((a, b) => a.concat(b), []);
    const sortByDate = flattenPhotos.sort(
      (a, b) => new Date(a.CreationDate) - new Date(b.CreationDate),
    );
    const formatDate = sortByDate.map((date) => {
      const newFormat = moment(date.CreationDate).format('MM/DD/YYYY');
      return newFormat;
    });

    const duplicatesByDate = formatDate.reduce((accum, currVal) => {
      const a = accum;
      if (a.find((dates) => dates.date === currVal)) {
        const dateIdx = accum.findIndex((dates) => dates.date === currVal);
        a[dateIdx].count += 1;
      } else {
        accum.push({
          date: currVal,
          count: 1,
        });
      }
      return accum;
    }, []);

    const filterByDate = duplicatesByDate.filter((notif) => moment(notif.date).diff(currentDate, 'days') <= 0);
    const addProperties = filterByDate.map((item) => {
      const obj = { ...item };
      obj.icon = 'photo';
      obj.message = 'new photos uploaded : ';
      return obj;
    });

    this.setState({ photoArr: addProperties });
  };

  /**
   *  Docs based on per day doc uploads and latest fileName
   */
  documentFeeder = async () => {
    const {
      communityRID,
      homeRID,
      slsOrdRID,
    } = this.props;
    const values = await Promise.allSettled([
      Api.fetchHomeDocuments(homeRID),
      Api.fetchSlsOrdDocs(slsOrdRID),
      Api.fetchCustPortalCommunityDocuments("CustPortal-Community", communityRID),
      GlobalConfig.get(GlobalConfig.Key.COMMUNITY_SPECIFIC_WARRANTY_DOCS) ?
          Api.fetchCustPortalCommunityDocuments('CustPortal-Manufacturer', communityRID) :
          Api.fetchCustPortalGeneralDocuments('CustPortal-Manufacturer') ,
      Api.fetchCustPortalGeneralDocuments('CustPortal-Builder'),
    ]);
    const currentDocs = [];
    values.forEach(({ status, value }) => {
      if (status === 'fulfilled') {
        currentDocs.push(...value);
      }
    });

    const sortByDate = currentDocs.sort(
      (a, b) => new Date(a.CreationDate) - new Date(b.CreationDate),
    );
    const formatDate = sortByDate.map((doc) => {
      const newFormat = moment(doc.CreationDate).format('MM/DD/YYYY');
      const obj = {
        date: newFormat,
        fileName: doc.Name,
        link: doc.RepDocFileHandlerLink ? doc.RepDocFileHandlerLink : doc.MiddlewareDownloadURL,
      };
      return obj;
    });

    const duplicatesPerDate = formatDate.reduce((accum, currVal) => {
      const a = accum;
      if (a.find((doc) => doc.date === currVal.date)) {
        const dateIdx = accum.findIndex((doc) => doc.date === currVal.date);
        a[dateIdx].count += 1;
        a[dateIdx].docs.push(currVal);
      } else {
        accum.push({
          date: currVal.date,
          count: 1,
          docs: [currVal],
        });
      }
      return accum;
    }, []);

    const addProperties = duplicatesPerDate.map((el) => {
      const o = { ...el };
      o.message = 'New Document(s) : ';
      o.icon = 'doc';
      return o;
    });

    this.setState({ documentArr: addProperties });
  };

  mainArray = async () => {
    const {
      timelineArr,
      startMilestoneArr,
      completeMilestoneArr,
      photoArr,
      documentArr,
    } = this.state;

    const sortMilestonesByTime = startMilestoneArr.concat(completeMilestoneArr)
      .sort((a, b) => new Date(b.time) - new Date(a.time));

    if (timelineArr !== null) {
      const joinFeeders = await timelineArr.concat(sortMilestonesByTime, photoArr, documentArr);
      const sortedMainArr = await joinFeeders.sort((a, b) => new Date(b.date) - new Date(a.date));

      this.setState({ notifyArray: sortedMainArr });
    }
  };

  render() {
    const { notifyArray } = this.state;

    return (
      <ThemeConsumer>
        {(theme) => (
          <div className={styles.notifications}>
            <div
              style={
                {
                  backgroundColor: theme.titleBarBgColor,
                  color: theme.titleBarFontColor,
                  fontSize: theme.titleBarFontSize,
                }
              }
              className={styles.titleBar}
            >
              notifications
            </div>
            <ul className={styles.panelContent}>
              {notifyArray && <NotifyItem notifyArray={notifyArray} />}
            </ul>
          </div>
        )}
      </ThemeConsumer>
    );
  }
}

Notifications.defaultProps = {
  pushOut: null,
};

Notifications.propTypes = {
  activities: PropTypes.arrayOf(PropTypes.object).isRequired,
  communityRID: PropTypes.number.isRequired,
  slsOrdRID: PropTypes.number.isRequired,
  handleLogout: PropTypes.func.isRequired,
  homeRID: PropTypes.number.isRequired,
  pushOut: PropTypes.bool,
};

export default Notifications;
