import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ThemeContext } from '../../context/ThemeContext';
import alertConfig from '../../config/alert';
import LoadingIndicator from '../LoadingIndicator';
import styles from './FileSubmitter.module.css';

function FileSubmitter({
  handleLogout,
  openAlert,
  setFileSubmitterActive,
  uploadFiles,
}) {
  const theme = useContext(ThemeContext);
  const [isLoading, setIsLoading] = useState(false);
  const [files, setFiles] = useState(undefined);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      setIsLoading(true);
      await uploadFiles(files);
      setFileSubmitterActive(false);
    } catch (error) {
      if (error.response) {
        const tokenExpired = error.response.data === 'jwt expired';
        if (tokenExpired) {
          handleLogout(true);
        } else {
          console.error(error);
          openAlert(alertConfig.error.uploadImages);
        }
      } else {
        console.error(error);
        openAlert(alertConfig.error.uploadImages);
      }
    }
    setIsLoading(false);
  };

  const listFilePreviews = () => ([...files].map((file) => (
    <li className={styles.filePreview}>
      <img alt={file.name} src={URL.createObjectURL(file)} />
      <div>{file.name}</div>
    </li>
  )));

  return (
    <div className={styles.FileSubmitter}>
      <header
        style={{
          background: theme.titleBarBgColor,
          color: theme.titleBarFontColor,
          fontSize: theme.titleBarFontSize,
        }}
      >
        Upload your images
        <button onClick={() => setFileSubmitterActive(undefined)} type="button">
          <FontAwesomeIcon icon="times" />
        </button>
      </header>
      {files?.length > 5 && (
        <div className={styles.fileNumberWarning}>Maximum number of files exceeded</div>
      )}
      <div className={styles.contentWrapper}>
        <form onSubmit={(e) => handleSubmit(e)}>
          <div>
            <label className={styles.fileUpload} htmlFor="files" style={{ backgroundColor: theme.accentColorA }}>
              Upload
              <input
                accept="image/png, image/jpeg"
                id="files"
                multiple
                onChange={(e) => setFiles(e.target.files)}
                type="file"
              />
            </label>
            <button
              disabled={!files || files.length > 5}
              style={{ backgroundColor: theme.accentColorC }}
              type="submit"
            >
              Submit
            </button>
          </div>
          <div>Select up to 5 images to upload.</div>
        </form>
        {files && (
          <div className={styles.filePreviewWrapper}>
            <ul>
              {listFilePreviews()}
            </ul>
          </div>
        )}
        {isLoading && (
          <div className={styles.loadingWrapper}>
            <LoadingIndicator color={theme.accentColorA} />
          </div>
        )}
      </div>
    </div>
  );
}

FileSubmitter.propTypes = {
  handleLogout: PropTypes.func.isRequired,
  openAlert: PropTypes.func.isRequired,
  setFileSubmitterActive: PropTypes.func.isRequired,
  uploadFiles: PropTypes.func.isRequired,
};

export default FileSubmitter;
