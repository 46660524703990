import React from 'react';
import PropTypes from 'prop-types';
import styles from './Photo.module.css';

const Photo = ({
  date,
  photo,
  clickHandler
}) => (
  <div
    className={styles.photo}
    onClick={() => clickHandler(photo)}
    role="button"
    tabIndex={0}
  >
    <img src={photo.RepDocFileHandlerLink ? photo.RepDocFileHandlerLink : photo.MiddlewareDownloadURL} alt={photo.date}  />
    <div className={styles.details}>
      <div>{date}</div>
    </div>
  </div>
);

Photo.propTypes = {
  clickHandler: PropTypes.func.isRequired,
  date: PropTypes.string.isRequired,
  photo: PropTypes.object.isRequired
};

export default Photo;
