import React from 'react';
import PropTypes from 'prop-types';
import { ThemeConsumer } from '../../../context/ThemeContext';
import styles from './CalendarKey.module.css';

const CalendarKey = ({
  widget
}) => {
  const keyDict = [
    {
      text: 'COMPLETED/IN PAST',
      themeKey: 'calendarBeforeCurrentWeek'
    },
    {
      text: 'ACTIVE SELECTION',
      themeKey: 'calendarSelectedDay'
    },
    {
      text: 'THIS WEEK',
      themeKey: 'calendarCurrentWeek'
    }
  ];

  const keys = () => keyDict.map(item => (
    <div className={styles.keyItem} key={item.text}>
      <ThemeConsumer>
        {theme => (
          <>
            <div className={styles.keyItemText}>{item.text}</div>
            <div
              className={styles.box}
              style={{
                backgroundColor: item.themeKey
                  ? theme[item.themeKey]
                  : item.color
              }}
            />
          </>
        )}
      </ThemeConsumer>
    </div>
  ));

  return (
    <div className={widget ? styles.widgetCalendarKey : styles.calendarKey}>
      <div className={styles.keyLabel}>Color Key:</div>
      <div className={styles.keyContainer}>
        {keys()}
      </div>
    </div>
  );
};

CalendarKey.defaultProps = {
  widget: false
};

CalendarKey.propTypes = {
  widget: PropTypes.bool
};

export default CalendarKey;
