import React, { Component, useEffect } from 'react';
import PropTypes from 'prop-types';
import styles from './Selections.module.css';
import { ThemeConsumer } from '../../context/ThemeContext';
import Api from '../../api/Api';
import SelectionItem from './SelectionItem/SelectionItem';
import GlobalConfig from '../../utils/GlobalConfig';
import LoadingIndicator from '../../shared/LoadingIndicator';
import Dictionary from "../../utils/Dictionary";
import SelectionsPreview from "./SelectionsPreview";
import SelectionsGallery from "./SelectionsGallery";
import {DictionaryDangerousTextAsHtml} from "../Dictionary/DictionaryDangerousTextAsHtml";

export interface ISelectionsProps {
  slsOrdRID: number;
  homeRID:number;
  handleLogout: (sessionExpired:boolean, resetAlert?:boolean) => void;
  windowWidth: number;
}


export interface ISlsOrdOpt {
  ObjectRID:number;
  SlsOrdOptRID:number;
  OptSelDescr:string;
  OptValDescr:string;
  Color:string;
  Style:string;
  CurrentQty:number;
  OptSelRID:number;
  OptCategRID:number;
  OptCategName:string;
  OptCategSortOrder:number;
  OptLoctnRID:number;
  OptLoctnName:string;
  OptLoctnSortOrder:number;
}

export interface ISelectionImage {
  slsOrdOptRID:number;
  img:string;
}

async function getConstructionStartSheet(slsOrdRID: number): Promise<string | null> {
  const data = await Api.fetchConstructionStartSheet(slsOrdRID);

  if (data?.ObjAttImgRID && data?.ObjAttImgRID > 0) {
    const base64 = await fetch(`data:application/pdf;base64,${data.fileData}`);
    const blob = await base64.blob();
    const fileURL = URL.createObjectURL(blob);

    return fileURL;
  }
  return null;
}

async function getSelectionImages(slsOrdRID: number, handleLogout: (sessionExpired:boolean, resetAlert?:boolean) => void) {
  const selectionImages = await Api.fetchSelectionImages(slsOrdRID);

  return selectionImages as ISelectionImage[];
}

async function getSlsOrdOpts(slsOrdRID: number, homeRID: number, handleLogout: (sessionExpired:boolean, resetAlert?:boolean) => void): Promise<ISlsOrdOpt[]> {
  const slsOrdOpts = await Api.fetchSlsOrdOpts(slsOrdRID, homeRID);

  return slsOrdOpts as ISlsOrdOpt[];
}

function Selections (props: ISelectionsProps) {
  const [startSheetUrl, setStartSheetUrl] = React.useState<string | null>();
  const [selectionImages, setSelectionImages] = React.useState<ISelectionImage[] | null>([]); //Disabled getting selection images!
  const [slsOrdOpts, setSlsOrdOpts] = React.useState<ISlsOrdOpt[] | null>(null);
  const { slsOrdRID, homeRID, windowWidth, handleLogout} = props;

  const selectionsType = GlobalConfig.get(GlobalConfig.Key.SELECTIONS_TYPE);
  const isLoading = selectionsType === 'startsheet' && startSheetUrl === undefined ||
    selectionsType !== 'startsheet' && (!slsOrdOpts || !selectionImages);

  useEffect(() => {
    if (!slsOrdRID) {
      return;
    }
    try {
      if (selectionsType === 'startsheet') {
        getConstructionStartSheet(slsOrdRID).then( fileURL => {
          setStartSheetUrl(fileURL);
        });
      } else {
        /* Disabled due to performance issues
        getSelectionImages(slsOrdRID, handleLogout).then(selectionImages => {
          if (selectionImages != null) {
            setSelectionImages(selectionImages);
          }
        });*/
        getSlsOrdOpts(slsOrdRID, homeRID, handleLogout).then(slsOrdOpts => {
          if (slsOrdOpts != null) {
            setSlsOrdOpts(slsOrdOpts);
          }
        });
      }
    } catch (err) {
      const tokenExpired = err.response?.data === 'jwt expired';
      if (tokenExpired) {
        handleLogout(true);
      } else {
        console.error(err);
      }
    }
  }, [slsOrdRID]);

  return (
    <ThemeConsumer>
      {(theme) => (
        <div className={styles.selections}>
          <section className={styles.col}>
            <div
              style={
                {
                  backgroundColor: theme.titleBarBgColor,
                  color: theme.titleBarFontColor,
                  fontSize: theme.titleBarFontSize,
                }
}
              className={styles.titleBar}
            >
              selections
            </div>
            {isLoading && (
              <div className={styles.selectionItems}>
                <span className={styles.loadingWrapper}>
                  <LoadingIndicator color={theme.accentColorA} />
                </span>
              </div>
            )}
            {!isLoading && (
              <div className={selectionsType !== 'list' ? styles.selectionItems : styles.selectionListItems}>
                {!slsOrdOpts && !startSheetUrl && (
                  <DictionaryDangerousTextAsHtml dictionary={Dictionary} dictionaryKey={Dictionary.Selections_Head} />
                )}
                {slsOrdOpts && (
                  <SelectionsGallery slsOrdOpts={slsOrdOpts} selectionImages={selectionImages!} selectionsType={selectionsType}/>
                )}
                {startSheetUrl && (
                  <SelectionsPreview windowWidth={windowWidth} slsOrdRID={slsOrdRID} startSheetUrl={startSheetUrl} />
                )}
              </div>
            )}
          </section>
        </div>
      )}
    </ThemeConsumer>
  );
}

export default Selections;
