import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as Moment from 'moment';
import { extendMoment } from 'moment-range';
import { Link, withRouter } from 'react-router-dom';
import Calendar from 'react-calendar';
import styles from './Schedule.module.css';
import ActivityItem from './ActivityItem/ActivityItem';
import CalendarKey from './CalendarKey/CalendarKey';
import { ThemeContext, ThemeConsumer } from '../../context/ThemeContext';
import './reactCalendar.css';
import Alert from '../../config/alert';
import GlobalConfig from '../../utils/GlobalConfig';
import Dictionary from "../../utils/Dictionary";
import {DictionaryText} from "../../components/Dictionary/DictionaryText";

const moment = extendMoment(Moment);

class Schedule extends Component {
  static contextType = ThemeContext;

  constructor(props) {
    super(props);
    this.state = {
      date: new Date(),
      selectedDayActivities: [],
      selectedDay: null,
      weekRange: null,
      scheduleActs: null,
    };
  }

  componentDidMount() {
    const { date } = this.state;
    const { location } = this.props;

    const weekRange = moment().range(
      moment().startOf('week').toDate(),
      moment().endOf('week').toDate(),
    );

    const scheduleActs = this.filterScheduleActs();
    const filteredWeeks = this.filterDates(scheduleActs, weekRange);

    /**
    * On component reload, setState to React-Router History
    * instead of initial component state.
    */
    if (location.state) {
      this.setState({
        selectedDay: location.state.selectedDay,
        scheduleActs,
        weekRange,
        filteredWeeks,
      });
    } else {
      this.setState({
        selectedDay: date,
        scheduleActs,
        weekRange,
        filteredWeeks,
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { selectedDay, scheduleActs } = this.state;
    if (prevState.selectedDay !== selectedDay && scheduleActs) {
      this.determineDayActivities();
    }

    if (!prevState.scheduleActs && scheduleActs) {
      this.handleCalendarFills(true);
    }
  }

  handleCalendarFills = (initialRender) => {
    const tileText = document.getElementsByClassName('react-calendar__tile');
    const weekendText = document.getElementsByClassName('react-calendar__month-view__days__day--weekend');
    const currentWeek = document.getElementsByClassName(styles.currentWeek);
    const beforeCurrentWeek = document.getElementsByClassName(styles.beforeCurrentWeek);
    const nowDate = document.getElementsByClassName('react-calendar__tile--now');
    const weekDays = document.getElementsByClassName('react-calendar__month-view__weekdays');
    const selectedDay = document.getElementsByClassName('react-calendar__tile--active');
    for (const element of tileText) {
      element.style.color = this.context.calendarDayText;
    }
    for (const element of weekendText) {
      element.style.color = this.context.calendarWeekendText;
    }
    for (const element of currentWeek) {
      element.style.backgroundColor = this.context.calendarCurrentWeek;
    }
    for (const element of beforeCurrentWeek) {
      element.style.backgroundColor = this.context.calendarBeforeCurrentWeek;
    }
    for (const element of nowDate) {
      element.style.backgroundColor = this.context.calendarNowDate;
    }
    for (const element of weekDays) {
      element.style.backgroundColor = this.context.calendarWeekDay;
    }
    if (!initialRender) {
      for (const element of selectedDay) {
        element.style.backgroundColor = this.context.calendarSelectedDay;
      }
    }
  };

  filterScheduleActs = () => {
    const { activities, hideFutureActivities } = this.props;
    const newArray = [];
    if (activities) {
      activities.forEach((act) => {
        if (act.isSchedule || !GlobalConfig.get(GlobalConfig.Key.ACTIVITY_MODULE_FILTER)) {
          // if no future activities clause active.
          if (!hideFutureActivities || act.Status === "Approved") {
            newArray.push(act);
          }
          // end changes for future activities.
        }
      });
    }
    return newArray;
  };

  filterDates = (scheduleActs, weekRange) => {
    const currentWeek = [];
    const beforeCurrentWeek = [];
    const afterCurrentWeek = [];

    let newDate = null;

    const datesToHighlight = scheduleActs.map((activity) => (
      new Date(activity.FinishDateCur).toDateString()
    ));

    datesToHighlight.forEach((singleDay) => {
      newDate = new Date(singleDay);
      if (moment(singleDay).isBefore(weekRange.start._d)) {
        beforeCurrentWeek.push(newDate.getTime());
      } else if (moment(singleDay).isAfter(weekRange.end._d)) {
        afterCurrentWeek.push(newDate.getTime());
      } else {
        currentWeek.push(newDate.getTime());
      }
    });

    return {
      currentWeek,
      beforeCurrentWeek,
      afterCurrentWeek,
    };
  };

  onChange = (date) => {
    this.handleCalendarFills();
    this.setState({ date });
  };

  /**
   * Test function to see if applying TileClass works
   */
  setTileClass = ({ date, view }) => {
    const {
      scheduleActs,
      weekRange,
      filteredWeeks,
    } = this.state;

    if (scheduleActs) {
      let currentDate = new Date(date);
      currentDate = currentDate.getTime();

      if (weekRange.contains(currentDate)) {
        return view === 'month' && styles.currentWeek;
      }
      if (filteredWeeks.beforeCurrentWeek.includes(currentDate)) {
        return view === 'month' && styles.beforeCurrentWeek;
      }
      if (filteredWeeks.afterCurrentWeek.includes(currentDate)) {
        return view === 'month' && styles.afterCurrentWeek;
      }
      return null;
    }
    return null;
  };

  listWeekActivities = (expanded) => {
    const {
      scheduleActs,
      weekRange,
    } = this.state;

    if (scheduleActs) {
      const weekActivities = scheduleActs.filter((activity) => (
        weekRange.contains(new Date(activity.FinishDateCur))
      ));

      return weekActivities.map((activity) => (
        expanded ? (
          <ActivityItem
            key={activity.ActActID}
            activity={activity}
            expanded
          />
        ) : (
          <ActivityItem
            key={activity.ActActID}
            activity={activity}
          />
        )));
    }
    return null;
  };

  handleDayClickDash = (date) => {
    const { scheduleOverlayActive, setScheduleOverlayActive } = this.props;
    const { selectedDay } = this.state;

    if (selectedDay.getTime() !== date.getTime() && scheduleOverlayActive) {
      this.setState({ selectedDay: date });
    } else if (selectedDay.getTime() !== date.getTime()
    && !scheduleOverlayActive) {
      setScheduleOverlayActive(!scheduleOverlayActive);
      this.setState({
        selectedDay: date,
      });
    } else {
      setScheduleOverlayActive(!scheduleOverlayActive);
    }
  };

  handleDayClickCal = (selectedDay) => {
    this.setState({ selectedDay });
  };

  determineDayActivities = () => {
    const { scheduleActs } = this.state;
    const { selectedDay } = this.state;

    const selectedDayActivities = scheduleActs.filter((activity) => (
      new Date(activity.FinishDateCur).toDateString() === new Date(selectedDay).toDateString()
    ));

    this.setState({ selectedDayActivities });
  };

  listSelectedDayActivities = (expanded) => {
    const { selectedDayActivities } = this.state;
    return selectedDayActivities.map((activity) => (
      expanded ? (
        <ActivityItem
          key={activity.ActActID}
          activity={activity}
          expanded
        />
      ) : (
        <ActivityItem
          key={activity.ActActID}
          activity={activity}
        />
      )));
  };

  listCompletedTasks = (expanded) => {
    const { scheduleActs } = this.state;
    if (scheduleActs) {
      const filterCompletedActs = scheduleActs.filter((act) => (
        act.Status === "Approved"
      ));
      const sortByDate = filterCompletedActs.sort((a, b) => (
        new Date(b.FinishDateCur).getTime() - new Date(a.FinishDateCur).getTime()
      ));

      return sortByDate.map((activity) => (
        expanded && (
          <ActivityItem
            key={activity.ActActID}
            activity={activity}
            expanded
          />
        )));
    }
    return null;
  };

  onTileClick = (calendarDate, view) => {
    const { scheduleOverlayActive } = this.props;
    const { selectedDay, filteredWeeks } = this.state;
    const monthNames = ['January', 'February', 'March', 'April', 'May', 'June',
      'July', 'August', 'September', 'October', 'November', 'December'];
    let selectedDayString = null;
    let calendarDayString = null;
    let currentDate = null;
    let selectedDayDigits = null;
    if (selectedDay) {
      selectedDayString = JSON.stringify(selectedDay).substring(0, 11);
      selectedDayDigits = JSON.stringify(selectedDay).substring(9, 11);
      calendarDayString = JSON.stringify(calendarDate).substring(0, 11);
      currentDate = (`${monthNames[calendarDate.getMonth()]} ${JSON.stringify(calendarDate).substring(9, 11)}`);
    }

    let newDate = new Date(calendarDate);
    newDate = newDate.getTime();
    const isAfterCurrentWeek = filteredWeeks.afterCurrentWeek.includes(newDate);

    if (view === 'month' && selectedDayString === calendarDayString && scheduleOverlayActive) {
      return (
        <div>
          <div className={styles.clickedActive}>
            <div className={styles.centeredText}>
              {parseInt(selectedDayDigits, 10)}
            </div>
          </div>
          <div className={styles.clickedElementStyle}>
            <div className={styles.secondaryWrapper}>
              <div className={styles.chatbubbleInner}>
                <div className={styles.popupDate}>
                  {currentDate}
                </div>
                {isAfterCurrentWeek ? (
                  <div className={styles.tentativeNote}>Tentative</div>
                ) : null}
                {this.listSelectedDayActivities(false)}
              </div>
            </div>
            <div className={styles.triangleOutter}>
              <div className={styles.triangleInner} />
            </div>
          </div>
        </div>
      );
    }
    return null;
  };

  render() {
    const { widget, location, hideFutureActivities } = this.props;
    const { date, selectedDayActivities, scheduleActs } = this.state;
    const defaultDate = location.state ? location.state.selectedDay : date;

    if (scheduleActs) {
      return (
        <ThemeConsumer>
          {(theme) => (
            widget ? (
              <div className={styles.scheduleWidget}>
                <div
                  style={{
                    backgroundColor: theme.titleBarBgColor,
                    color: theme.titleBarFontColor,
                    fontSize: theme.titleBarFontSize,
                  }}
                  className={styles.titleBar}
                >
                  schedule
                </div>
                <div className={styles.calendarPad}>
                  <div onClick={(e) => e.stopPropagation()} role="presentation">
                    <div className={styles.disclaimer}>
                      <span>Please note: </span>
                      {Alert.disclaimer.message.body}
                    </div>
                    <Calendar
                      locale="en-US"
                      onActiveDateChange={this.handleCalendarFills}
                      onChange={this.onChange}
                      onClickDay={(e) => this.handleDayClickDash(e)}
                      value={date}
                      tileClassName={this.setTileClass}
                      // calendar component requires 'date' to be used as parameter
                      tileContent={({ date, view }) => this.onTileClick(date, view)}
                    />
                  </div>
                  <div
                    className={styles.calendarFooter}
                    style={{
                      backgroundColor: theme.primaryMenuHighlightBg,
                      color: theme.calendarLegendText,
                    }}
                  >
                    <CalendarKey
                      widget
                    />
                  </div>
                  <Link className={styles.calendarLink} to="/dashboard/calendar" style={{ backgroundColor: theme.accentColorA}}>
                    View Calendar
                  </Link>
                  <div className={styles.shortList}>
                    {!hideFutureActivities && (
                      <h2>
                        <DictionaryText dictionary={Dictionary} dictionaryKey={Dictionary.Schedule_FutureActivities} />
                      </h2>
                    )}
                    <ul className={styles.taskList}>
                      {this.listWeekActivities()}
                    </ul>
                  </div>
                </div>
              </div>
            ) : (
              <div className={styles.schedule}>
                <div className={styles.colLeft}>
                  <div
                    style={{
                      backgroundColor: theme.titleBarBgColor,
                      color: theme.titleBarFontColor,
                      fontSize: theme.titleBarFontSize,
                    }}
                    className={styles.titleBar}
                  >
                    {`(${this.listCompletedTasks().length}) Completed Tasks`}
                  </div>
                  <ul className={styles.completedList}>
                    {this.listCompletedTasks(true)}
                  </ul>
                </div>
                <div className={styles.colRight}>
                  <div
                    style={{
                      backgroundColor: theme.titleBarBgColor,
                      color: theme.titleBarFontColor,
                      fontSize: theme.titleBarFontSize,
                    }}
                    className={styles.titleBar}
                  >
                    calendar
                  </div>
                  <Calendar
                    className="full"
                    locale="en-US"
                    onActiveDateChange={this.handleCalendarFills}
                    onChange={this.onChange}
                    onClickDay={(value) => this.handleDayClickCal(value)}
                    value={defaultDate}
                    tileClassName={this.setTileClass}
                  />
                  <div
                    className={styles.calendarFooter}
                    style={{ backgroundColor: theme.primaryMenuHighlightBg }}
                  >
                    <CalendarKey
                      widget={false}
                    />
                  </div>
                  <ul className={styles.activityList}>
                    {selectedDayActivities.length > 0 && this.listSelectedDayActivities(true)}
                  </ul>
                </div>
              </div>
            )
          )}
        </ThemeConsumer>
      );
    }
    return null;
  }
}

Schedule.defaultProps = {
  activities: null,
  widget: false,
  scheduleOverlayActive: false,
  setScheduleOverlayActive: () => {},
};

Schedule.propTypes = {
  activities: PropTypes.arrayOf(PropTypes.object),
  widget: PropTypes.bool,
  location: PropTypes.object.isRequired,
  scheduleOverlayActive: PropTypes.bool,
  setScheduleOverlayActive: PropTypes.func,
  hideFutureActivities: PropTypes.bool.isRequired,
  numberOfFutureActivities: PropTypes.number.isRequired,
};

export default withRouter(Schedule);
