import React from 'react';
import PropTypes from 'prop-types';
import { ThemeConsumer } from '../../../context/ThemeContext'; 
import styles from './ActivityItem.module.css';
import calendarIcon from '../../../img/calendar-icon.png';

const ActivityItem = ({ activity, expanded }) => (expanded ? (
  <ThemeConsumer>
    {theme => (
      <li className={styles.activityItemExpanded}>
        <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50">
          <g id="Calendar" transform="translate(2.698)">
            <rect id="Rectangle_1228" data-name="Rectangle 1228" width="26.454" height="19.4" transform="translate(3.26 9.51)" fill={theme.primaryMenuHighlightBg} stroke={theme.primaryMenuHighlightBg} stroke-linejoin="round" stroke-width="6.52"/>
            <rect id="Rectangle_1229" data-name="Rectangle 1229" width="26.454" height="15.872" transform="translate(3.26 12.905)" fill="#fff"/>
            <rect id="Rectangle_1230" data-name="Rectangle 1230" width="4.497" height="4.497" transform="translate(5.941 15.066)" fill={theme.primaryMenuHighlightBg} />
            <rect id="Rectangle_1231" data-name="Rectangle 1231" width="4.497" height="4.497" transform="translate(5.941 22.12)" fill={theme.primaryMenuHighlightBg} />
            <rect id="Rectangle_1232" data-name="Rectangle 1232" width="4.497" height="4.497" transform="translate(13.877 15.066)" fill={theme.primaryMenuHighlightBg} />
            <rect id="Rectangle_1233" data-name="Rectangle 1233" width="4.497" height="4.497" transform="translate(13.877 22.12)" fill={theme.primaryMenuHighlightBg} />
            <rect id="Rectangle_1234" data-name="Rectangle 1234" width="4.497" height="4.497" transform="translate(21.813 15.066)" fill={theme.primaryMenuHighlightBg} />
            <rect id="Rectangle_1235" data-name="Rectangle 1235" width="4.497" height="4.497" transform="translate(21.813 22.12)" fill={theme.primaryMenuHighlightBg} />
            <g id="Rectangle_1236" data-name="Rectangle 1236" transform="translate(6.302 0.622)" fill={theme.primaryMenuHighlightBg} stroke="#fff" stroke-linecap="round" stroke-miterlimit="10" stroke-width="1.5">
              <rect width="3.774" height="8.747" rx="1.65" stroke="none"/>
              <rect x="-0.75" y="-0.75" width="5.274" height="10.247" rx="2.4" fill="none"/>
            </g>
            <g id="Rectangle_1237" data-name="Rectangle 1237" transform="translate(22.228 0.56)" fill={theme.primaryMenuHighlightBg} stroke="#fff" stroke-linecap="round" stroke-miterlimit="10" stroke-width="1.5">
              <rect width="3.774" height="8.747" rx="1.65" stroke="none"/>
              <rect x="-0.75" y="-0.75" width="5.274" height="10.247" rx="2.4" fill="none"/>
            </g>
          </g>
        </svg>
        <div>
          <div>{new Date(activity.FinishDateCur).toDateString()}</div>
          <div>{activity.portalActName ? activity.portalActName : activity.ActName}</div>
        </div>
      </li>
    )}
  </ThemeConsumer>
) : (
  <li className={styles.activityItem}>
    {activity.portalActName ? activity.portalActName : activity.ActName}
  </li>
));

ActivityItem.defaultProps = {
  expanded: false
};

ActivityItem.propTypes = {
  activity: PropTypes.object.isRequired,
  expanded: PropTypes.bool
};

export default ActivityItem;
