import React from "react";

export class Dictionary {
  config : Record<string, string>;

  LoginInstructions: string = 'LoginInstructions';
  LoginTitle: string = 'LoginTitle';
  LoginButton: string = 'LoginButton';
  PasswordReset: string = 'PasswordReset';
  PasswordResetNotificationOK: string = 'PasswordResetNotificationOK';
  ForgotPW_Available: string = 'ForgotPW_Available';
  ForgotPW_ConfirmNote: string = 'ForgotPW_ConfirmNote';
  ClaimTracker_WtyNA: string = 'ClaimTracker_WtyNA';
  ClaimTracker_InstructionFooter: string = 'ClaimTracker_InstructionFooter';
  ClaimTracker_InstructionFooter_Released: string = 'ClaimTracker_InstructionFooter_Released';
  ClaimTracker_InstructionFooter_Expired: string = 'ClaimTracker_InstructionFooter_Expired';
  ClaimTracker_InstructionFooter_UnderReview: string = 'ClaimTracker_InstructionFooter_UnderReview';
  Schedule_FutureActivities: string = 'Schedule_FutureActivities';
  MyProfile_InfoText: string = 'MyProfile_InfoText';
  Documents_CommunityDocuments: string = 'Documents_CommunityDocuments';
  Documents_HomeDocuments: string = 'Documents_HomeDocuments';
  Documents_ClosingDocuments: string = 'Documents_ClosingDocuments';
  Selections_Head: string = 'Selections_Head';
  SelectHome_NoCustomer: string = 'SelectHome_NoCustomer';

  constructor() {
    this.config = {};
  }

  setConfig(config : Record<string, string>) {
    this.config = config;
  }

  appendConfig(config : Record<string, string>) {
    Object.keys(config).forEach((value:string, index: number) => {
      this.config[value] = config[value];
    })
  }

  getText(key : string) {
    const value = this.config[key];
    return value !== undefined && value !== null ? value : '**Localization Missing**';
  }
}

const dictInstance = new Dictionary();

export default dictInstance;
