import React, {Component, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import styles from './Warranty.module.css';
import ClaimTracker from './ClaimTracker/ClaimTracker';
import DocContainer from '../../shared/DocContainer/DocContainer';
import ClaimModal from './ClaimModal/ClaimModal';
import Api, {
  IFieldPointUser,
  IKovaApiToken,
  IOptLocation,
  ISvcReq,
  ISvcReqType, IPortalUser
} from '../../api/Api';
import alertConfig from '../../config/alert';
import GlobalConfig from "../../utils/GlobalConfig";
import {IsCustomerEditable} from "../../utils/WarrantyUtil";
import {AlertConfigCategory, HomeInfoObj} from "../../types";
import LoadingIndicator from "../../shared/LoadingIndicator";
import { ThemeConsumer } from '../../context/ThemeContext';

interface IWarrantyLoaderProps {
  handleLogout: (sessionExpired:boolean, resetAlert?:boolean) => void;
  openAlert: (alert:AlertConfigCategory | null, resetAlert?:boolean) => void;
  homeInfo: HomeInfoObj;
  homeRID: number;
  customerRID: number;
  loadingStatus:string;
  setLoadingStatus:(status:string) => void;
  user:any;
  kovaApiToken:IKovaApiToken;
  allowCreatingSvcReqsWhenNewAssignedExists:boolean;
}

export default function WarrantyLoader(props: IWarrantyLoaderProps) {
  const [manufacturerWarrantyDocs, setManufacturerWarrantyDocs] = useState<unknown[] | null>();
  const [builderWarrantyDocs, setBuilderWarrantyDocs] = useState<unknown[] | null>();
  const [svcReqs, setSvcReqs] = useState<ISvcReq[] | null>();
  const [svcReqTypes, setSvcReqTypes] = useState<ISvcReqType[] | null>();
  const [optLocations, setOptLocations] = useState<IOptLocation[] | null>();
  const [fieldPointUser, setFieldPointUser] = useState<IPortalUser | null>();

  const {homeRID, customerRID, homeInfo, handleLogout, openAlert, loadingStatus, setLoadingStatus, user, kovaApiToken, allowCreatingSvcReqsWhenNewAssignedExists} = props;

  function handleError(err:any, alertConfig: any) {
    let tokenExpired;
    if (err.response) {
      tokenExpired = err.response.data === 'jwt expired';
    }
    if (tokenExpired) {
      console.log(err);

      handleLogout(true);
    } else {
      console.log(err);

      if (alertConfig) {
        openAlert(alertConfig);
      }
    }
  }

  useEffect(() => {
    const promise = homeInfo && GlobalConfig.get(GlobalConfig.Key.COMMUNITY_SPECIFIC_WARRANTY_DOCS) && homeInfo.CommunityRID > 0 ?
      Api.fetchCustPortalCommunityDocuments('CustPortal-Manufacturer', homeInfo.CommunityRID) :
      Api.fetchCustPortalGeneralDocuments('CustPortal-Manufacturer');
    promise.then(docs => {
      setManufacturerWarrantyDocs(docs);
    }).catch(err => {
      setManufacturerWarrantyDocs(null);

      handleError(err, alertConfig.error.documentsWarranty);
    });
  }, [homeInfo]);

  useEffect(() => {
    Api.fetchCustPortalGeneralDocuments('CustPortal-Builder').then(docs => {
      setBuilderWarrantyDocs(docs);
    }).catch(err => {
      setBuilderWarrantyDocs(null);

      handleError(err, alertConfig.error.documentsHelpful);
    });
  }, []);

  useEffect(() => {
    if(homeRID > 0) {
      reloadSvcReqs();
    }
  }, [homeRID]);

  useEffect(() => {
    if(GlobalConfig.get(GlobalConfig.Key.WARRANTY_PROVIDER) === "Fieldpoint"){
      setOptLocations([]);
      return;
    }

    Api.fetchOptLocations().then(optLocations => {
      if(optLocations.length > 1){
        optLocations.sort((a:any, b:any) => {return a.SortOrder - b.SortOrder});
      }
      setOptLocations(optLocations);
    }).catch(err => {
      setOptLocations(null);

      handleError(err, null);
    });
  }, []);

  useEffect(() => {
    if(GlobalConfig.get(GlobalConfig.Key.WARRANTY_PROVIDER) === "Fieldpoint"){
      setSvcReqTypes([]);
      return;
    }

    Api.fetchSvcReqTypes().then(svcReqTypes => {
      if(svcReqTypes.length > 1){
        svcReqTypes.sort((a:any, b:any) => {return a.SortOrder - b.SortOrder});
      }
      setSvcReqTypes(svcReqTypes);
    }).catch(err => {
      setSvcReqTypes(null);

      handleError(err, null);
    });
  }, []);


  useEffect(() => {
    if(GlobalConfig.get(GlobalConfig.Key.WARRANTY_PROVIDER) === "Fieldpoint") {
      try {
        Api.fetchFieldpointCompany(customerRID).then(fieldpointUser => {
          if(fieldpointUser) {
            //assign fieldpointuser fields to fields that claimmodal uses
            const userInfo = {
              firstName: fieldpointUser.Name,
              email: fieldpointUser.EmailHome,
              phone: fieldpointUser.PhoneHome
            } as IPortalUser;

            setFieldPointUser(userInfo);
          }
          else {
            setFieldPointUser(null);
          }
        })
      } catch (err) {
        if (err.response?.data === 'jwt expired') {
          handleLogout(true);
        } else {
          openAlert(alertConfig.error.getFieldpointCompany);
        }
      }
    }
  }, []);

  async function reloadSvcReqs() : Promise<ISvcReq[]> {
    try {
      let svcReqs = await Api.fetchSvcReqs(homeRID, customerRID);
      setSvcReqs(svcReqs);
      return svcReqs;
    } catch (err) {
      setSvcReqs(null);

      handleError(err, alertConfig.error.getSvcReqs);
    }
    return [];
  }

  if(!homeInfo || homeRID === 0){
    return  <ThemeConsumer>
      {(theme) => (
        <LoadingIndicator color={theme.accentColorA}></LoadingIndicator>
      )}
    </ThemeConsumer>
  }

  return <Warranty homeInfo={homeInfo} homeRID={homeRID} customerRID={customerRID}
                   builderWarrantyDocs={builderWarrantyDocs}
                   manufacturerWarrantyDocs={manufacturerWarrantyDocs}
                   fieldPointUser={fieldPointUser}
                   reloadSvcReqs={reloadSvcReqs}
                   handleLogout={handleLogout}
                   kovaApiToken={kovaApiToken}
                   loadingStatus={loadingStatus}
                   setLoadingStatus={setLoadingStatus}
                   svcReqTypes={svcReqTypes}
                   svcReqs={svcReqs}
                   user={user}
                   optLocations={optLocations}
                   openAlert={openAlert}
                   allowCreatingSvcReqsWhenNewAssignedExists={allowCreatingSvcReqsWhenNewAssignedExists}
  />
}


interface IWarrantyProps {
  handleLogout: (sessionExpired:boolean, resetAlert?:boolean) => void;
  openAlert: (alert:AlertConfigCategory | null, resetAlert?:boolean) => void;
  homeInfo: HomeInfoObj;
  homeRID: number;
  customerRID: number;
  loadingStatus:string;
  setLoadingStatus:(status:string) => void;
  user:any;
  kovaApiToken:any;

  builderWarrantyDocs?:unknown[] | null;
  manufacturerWarrantyDocs?:unknown[] | null;
  svcReqs?:ISvcReq[] | null;
  svcReqTypes?:ISvcReqType[] | null;
  optLocations?:IOptLocation[] | null;
  fieldPointUser?:IPortalUser | null;
  reloadSvcReqs:() => Promise<ISvcReq[]>;
  allowCreatingSvcReqsWhenNewAssignedExists: boolean;
}

function Warranty(props: IWarrantyProps) {
  const [activeSvcReq, setActiveSvcReq] = useState<ISvcReq | null>();

  const {homeInfo, fieldPointUser, user, svcReqs, svcReqTypes, builderWarrantyDocs, manufacturerWarrantyDocs, optLocations, homeRID,
    customerRID, loadingStatus, setLoadingStatus, kovaApiToken, openAlert, handleLogout, reloadSvcReqs,allowCreatingSvcReqsWhenNewAssignedExists} = props;

  async function toggleClaimModal(svcReqRID?:number) {
    if (!svcReqs) {
      return;
    }

    let existingSvcReq = null;

    //Not selecting a specific service request
    if (!svcReqRID) {
      if (activeSvcReq !== undefined) {
        //Remove active
        setActiveSvcReq(undefined);
        return;
      }
      else {
        //Find the first customer editable service request to open
        existingSvcReq = svcReqs
          .filter(svcReq => IsCustomerEditable(svcReq.Status, svcReq.SvcReqCategSvcReqCategID, svcReq.ReceiverUserRID))
          .pop();
      }
    }
    else {
      existingSvcReq = svcReqs.find(svcReq => svcReq.SvcReqRID === svcReqRID);
      if(existingSvcReq == null){
        let reloadedSvcReqs = await reloadSvcReqs();
        existingSvcReq = reloadedSvcReqs.find(svcReq => svcReq.SvcReqRID === svcReqRID);
      }
    }

    if (existingSvcReq) {
      setActiveSvcReq(existingSvcReq);
    }
    else {
      setActiveSvcReq(null);
    }
  }

  const showClaimModal = activeSvcReq !== undefined;

  return (
    <div
      className={styles.warranty}
      style={showClaimModal
        ? {
          overflow: 'hidden',
        } : {}}
    >
      <div className={styles.claimTrackerContainer}>
        {
          svcReqs && (
            <ClaimTracker
              homeInfo={homeInfo}
              setActiveSvcReq={setActiveSvcReq}
              allowCreatingSvcReqsWhenNewAssignedExists={allowCreatingSvcReqsWhenNewAssignedExists}
              svcReqs={svcReqs}
              toggleClaimModal={toggleClaimModal}
              fieldpointUserExists = {!!fieldPointUser}
            />
          )
        }
      </div>
      {
        builderWarrantyDocs && manufacturerWarrantyDocs !== undefined && (
          <div className={styles.builderDocContainer}>
            <DocContainer
              docs={builderWarrantyDocs}
              heading="Helpful Documents"
              lg={3}
            />
          </div>
        )
      }
      {
        manufacturerWarrantyDocs && builderWarrantyDocs !== undefined && (
          <div className={styles.mfgDocContainer}>
            <DocContainer
              docs={manufacturerWarrantyDocs}
              heading="Warranty Documents"
              lg={3}
            />
          </div>
        )
      }
      {showClaimModal && optLocations != undefined && svcReqTypes  != undefined && (fieldPointUser || user) && (
        <ClaimModal
          activeSvcReq={activeSvcReq}
          getSvcReqs={reloadSvcReqs}
          handleLogout={handleLogout}
          homeInfo={homeInfo}
          homeRID={homeRID}
          openAlert={openAlert}
          optLocations={optLocations}
          loadingStatus={loadingStatus}
          setLoadingStatus={setLoadingStatus}
          svcReqTypes={svcReqTypes}
          toggleClaimModal={toggleClaimModal}
          user={fieldPointUser || user}
          kovaApiToken={kovaApiToken}
        />
      )}
    </div>
  );
}
