import React from 'react';
import PropTypes from 'prop-types';
import styles from './MenuButton.module.css';

interface IMenuButtonProps {
  showNavMenu: boolean,
  handleMenuToggle: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void,
}

function MenuButton({
  showNavMenu,
  handleMenuToggle,
}:IMenuButtonProps) {
  return (
    <div
      className={styles.container}
      onClick={handleMenuToggle}
      role="button"
      tabIndex={0}
    >
      <div
        className={`${styles.line} 
      ${showNavMenu ? styles.lineTopOpen : styles.lineTop}
      `}
      />
      <div
        className={`${styles.line} 
      ${showNavMenu ? styles.lineMiddleOpen : styles.lineMiddle}
      `}
      />
      <div
        className={`${styles.line} 
      ${showNavMenu ? styles.lineBottomOpen : styles.lineBottom}
    `}
      />
    </div>
  );
}

export default MenuButton;
